const buttonVariants = {
  primary:
    "bg-primary-blue text-white disabled:bg-gray-300 disabled:text-gray-600",
  secondary: "bg-white text-primary-blue border border-primary-blue",
  "secondary-light": "bg-white text-primary-blue border border-gray-foreground",
  cancel: "bg-white text-primary-blue border border-gray-300",
  "blue-light":
    "bg-primary-blue-light text-primary-blue disabled:bg-gray-300 disabled:text-gray-600",
  red: "bg-white text-red-500 border border-red-500",
  "red-filled": "bg-red-500 text-white",
  "red-filled-light": "bg-pink-150 text-rose-550",
  gray: "bg-white text-primary border border-gray-foreground",
  "gray-light": "bg-white text-tertiary border border-gray-foreground",
  "gray-filled": "bg-gray-foreground text-primary disabled:bg-gray-400/60",
  "gray-blue": "bg-gray-foreground text-primary-blue disabled:bg-gray-400/60",
  "gray-filled-dark": "bg-gray-200 text-primary",
  "text-primary": "text-primary-blue hover:text-primary-blue-hover",
};

const buttonSizes = {
  unset: "",
  small: "h-7 px-3",
  small2: "h-7 px-4 py-4.5 sm:py-5.5",
  medium: "h-10 px-3.5",
};

const Button = ({
  onClick = (_event: React.MouseEvent<HTMLButtonElement>) => {},
  variant = "primary",
  size = "medium",
  rounded = "lg",
  className = "",
  children,
  disabled = false,
  ...props
}: {
  onClick?: (_event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: string;
  size?: string;
  rounded?: string;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  [x: string]: any;
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center justify-center rounded-${rounded}
        ${disabled ? "opacity-70" : "opacity-100"}
        ${buttonVariants[variant]} ${buttonSizes[size]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
