import { CallStatus } from "../../../store/call/interfaces";
import type { StatusUpdateData } from "../../../store/conversation/interfaces";
import { useGetUsersQuery } from "../../../store/user/api";
import type { User } from "../../../store/user/userReducer";

interface StatusUpdateMessageProps {
  statusUpdateData: StatusUpdateData;
  className?: string;
}

const StatusUpdateMessage = ({
  statusUpdateData,
  className = "",
}: StatusUpdateMessageProps) => {
  const getStatusLabel = (status: CallStatus) => {
    return status === CallStatus.Pending ? "Moved to Review" : "Completed";
  };

  const showDoctorName = (doctor: User) => {
    return doctor
      ? doctor.display_name || `${doctor.first_name} ${doctor.last_name}`
      : "";
  };

  return (
    <div className={`w-full flex items-center space-x-4 ${className}`}>
      <hr className="w-full border-gray-foreground" />
      <div className="text-sm font-medium flex flex-col items-center">
        <p className="text-primary-blue whitespace-nowrap">
          {getStatusLabel(statusUpdateData.status_to)}
          {statusUpdateData.updated_by &&
            ` by ${showDoctorName(statusUpdateData.updated_by)}`}
        </p>
        {statusUpdateData.updated_at && (
          <p className="text-tertiary whitespace-nowrap">
            {new Date(
              `${statusUpdateData.updated_at.replace(/-/g, "/")} GMT+0`,
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>
        )}
      </div>
      <hr className="w-full border-gray-foreground" />
    </div>
  );
};

export default StatusUpdateMessage;
