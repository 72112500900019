import CallsTableData from "./CallsTableData";
import CallsTableHeader from "./CallsTableHeader";

interface CallsTableProps {
  className?: string;
}

const CallsTable = ({ className = "" }: CallsTableProps) => {
  return (
    <div className={`${className} grid grid-rows-tab-layout md:gap-6 relative`}>
      <CallsTableHeader />
      <CallsTableData />
    </div>
  );
};

export default CallsTable;
