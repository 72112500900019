class LocalStorageService {
  static VERSION = "1.2";

  private _generateStorageKey(key: string): string {
    return `${key}_v${LocalStorageService.VERSION}`;
  }

  setItem<T>(key: string, value: T): void {
    const storageKey = this._generateStorageKey(key);

    if (value === undefined) {
      console.warn(
        `Attempted to store undefined value under key "${storageKey}". Operation aborted.`,
      );
      return;
    }

    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(storageKey, serializedValue);
    } catch (error) {
      console.error(
        `Error setting item in localStorage with key "${storageKey}":`,
        error,
      );
    }
  }

  getItem<T>(key: string): T | null {
    const storageKey = this._generateStorageKey(key);

    try {
      const serializedValue = localStorage.getItem(storageKey);

      if (serializedValue === null || serializedValue === undefined) {
        return null;
      }

      return JSON.parse(serializedValue) as T;
    } catch (error) {
      console.error(
        `Error getting item from localStorage with key "${storageKey}":`,
        error,
      );
      return null;
    }
  }

  removeItem(key: string): void {
    const storageKey = this._generateStorageKey(key);

    try {
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error(
        `Error removing item from localStorage with key "${storageKey}":`,
        error,
      );
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error("Error clearing localStorage:", error);
    }
  }

  getAllKeys(): string[] {
    try {
      return Object.keys(localStorage);
    } catch (error) {
      console.error("Error getting keys from localStorage:", error);
      return [];
    }
  }
}

export default LocalStorageService;
