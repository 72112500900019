import { useMediaQuery } from "react-responsive";
import CallsTable from "./InboxTable/CallsTable";
import { InboxLayout } from "./Layout/InboxLayout";
import CallDetails from "./RightPanel/CallDetails";
import { useCallManagement } from "./hooks/useCallManagement";

const InboxPage = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const { selectedConversationId, nextConversation, handleCloseCallDetails } =
    useCallManagement();

  return (
    <InboxLayout isDesktop={isDesktop} isHeightSm={isHeightSm}>
      <CallsTable className="w-full h-full" />
      <CallDetails
        className={`absolute top-0 right-0 z-[60] md:static transition-all duration-300 h-full
          overflow-hidden min-w-0 ${selectedConversationId ? "w-full md:ml-8" : "w-0"}`}
        onClose={handleCloseCallDetails}
        nextConversation={nextConversation}
      />
    </InboxLayout>
  );
};

export default InboxPage;
