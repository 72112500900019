import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as TimeIcon } from "../../../assets/icons/billing-new.svg";
import { ReactComponent as CarePlanIcon } from "../../../assets/icons/clipboard-heart.svg";
import { ReactComponent as GenerateIcon } from "../../../assets/icons/file-check.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/pause-circle.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-add.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash-redesign.svg";
import { frequency, patientProfileTabs } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  adjustBillingPatient,
  generateNote,
  getBillingPatient,
  removeAdjustedTime,
  updateBillingNote,
} from "../../../store/billingMetrics/thunks";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientProfileTabAction,
} from "../../../store/patient/actions";
import ActionsButton from "../../Basic/ActionsButton";
import Button from "../../Basic/Button";
import CircleDivider from "../../Basic/CircleDivider";
import LoaderDots from "../../Basic/LoaderDots";
import Modal from "../../Basic/Modal";
import ModalMobile from "../../Basic/ModalMobile";
import YearMonthPicker from "../../Basic/YearMonthPicker";
import NoteContent from "../../BillingMetrics/NoteContent";
import TimeEditing from "../../BillingMetrics/TimeEditing";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import EnrollmentEditFields from "./EnrollmentEditFields";

const cardButtonClasses =
  "text-sm font-semibold rounded-full flex items-center space-x-2 py-1 px-3 whitespace-nowrap truncate";
const cardButtonActiveClasses = "text-primary-blue bg-primary-blue-light";
const cardButtonInactiveClasses = "text-tertiary bg-gray-foreground";

type Enrollment = FixLater;

interface EnrollmentCard {
  enrollment: Enrollment;
  onDelete: () => void;
  setSelectedEnrollment: (enrollment: Enrollment) => void;
  withoutNumber: boolean;
  openByDefault: boolean;
  noConsent: boolean;
}

const EnrollmentCard = ({
  enrollment,
  onDelete,
  setSelectedEnrollment,
  withoutNumber,
  openByDefault,
  noConsent,
}) => {
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const { currentBillingPatient, timeAdjustLoading } = useAppSelector(
    (state) => state.billingMetrics,
  );
  const [activeButton, setActiveButton] = useState(
    openByDefault ? "Care Plan" : "",
  );
  const [combinedStaffData, setCombinedStaffData] = useState(null);
  const [breakdownData, setBreakdownData] = useState(null);
  const [additionalTime, setAdditionalTime] = useState(0);
  const [staffAdjusted, setStaffAdjusted] = useState([]);
  const [adjustmentsToRemove, setAdjustmentsToRemove] = useState([]);
  const [editTime, setEditTime] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month"));
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  const billingType =
    enrollment.campaign.campaign_type === "Outreach"
      ? "CCM"
      : enrollment.campaign.campaign_type === "Outreach_PCM"
        ? "PCM"
        : enrollment.campaign.campaign_type === "Outreach_BHI"
          ? "BHI"
          : enrollment.campaign.campaign_type;

  useEffect(() => {
    if (activeButton !== "Time") {
      return;
    }
    const dateStamp = selectedDate.format("YYYY-MM-DD");
    const interval = setInterval(() => {
      dispatch(
        getBillingPatient(
          enrollment.patient_id,
          dateStamp,
          enrollment.campaign.campaign_type,
        ),
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [selectedDate, activeButton, dispatch, enrollment]);

  useEffect(() => {
    if (currentBillingPatient?.staff) {
      const combinedData = {};

      currentBillingPatient.staff.forEach((item) => {
        const { doctor_id, first_name, last_name, seconds } = item;

        if (!item.reason) {
          if (combinedData[doctor_id]) {
            combinedData[doctor_id].seconds += seconds;
          } else {
            combinedData[doctor_id] = {
              doctor_id,
              first_name,
              last_name,
              seconds,
            };
          }
        }
      });

      const result = Object.values(combinedData);
      setCombinedStaffData(result);
      setBreakdownData(result);
    }
  }, [currentBillingPatient]);

  const onCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  const onSaveNote = (updatedNote) => {
    const dateStamp = selectedDate.format("YYYY-MM-DD");

    dispatch(
      updateBillingNote(
        currentBillingPatient.monthlyBillId,
        updatedNote,
        () => {
          setOpenNoteModal(false);
          dispatch(
            getBillingPatient(
              selectedPatientInfo.patient_id,
              dateStamp,
              enrollment.campaign.campaign_type,
            ),
          );
        },
      ),
    );
  };

  const onSelectDate = (date) => {
    setSelectedDate(date);
    const dateStamp = date.format("YYYY-MM-DD");

    dispatch(
      getBillingPatient(
        selectedPatientInfo.patient_id,
        dateStamp,
        enrollment.campaign.campaign_type,
      ),
    );
  };

  const getDoctorSecondsDiff = (doctorId) => {
    const initial = combinedStaffData.find((doc) => doc.doctor_id === doctorId);
    const adjusted = breakdownData.find((doc) => doc.doctor_id === doctorId);

    if (adjusted !== undefined && adjusted.seconds !== undefined) {
      return adjusted.seconds - initial.seconds;
    }

    return 0;
  };

  const onConfirm = () => {
    const totalSecondsDiff =
      getTotalWithAdditionalSeconds() - currentBillingPatient.totalSeconds;

    const staff = combinedStaffData.map((doctor) => {
      return {
        doctorId: doctor.doctor_id,
        secondsAdjusted: getDoctorSecondsDiff(doctor.doctor_id),
      };
    });

    staffAdjusted.forEach((doctor) => {
      staff.push(doctor);
    });

    const currentDateStamp = dayjs().startOf("month").format("YYYY-MM-DD");

    if (adjustmentsToRemove.length > 0) {
      dispatch(
        removeAdjustedTime(
          currentBillingPatient.monthlyBillId,
          adjustmentsToRemove,
          () => {
            dispatch(
              getBillingPatient(
                enrollment.patient_id,
                currentDateStamp,
                enrollment.campaign.campaign_type,
              ),
            );
          },
        ),
      );
    }

    if (adjustmentsToRemove.length > 0 || totalSecondsDiff !== 0) {
      dispatch(
        adjustBillingPatient(
          currentBillingPatient.monthlyBillId,
          totalSecondsDiff,
          staff,
          () => {
            dispatch(
              getBillingPatient(
                enrollment.patient_id,
                currentDateStamp,
                enrollment.campaign.campaign_type,
                onCancel,
              ),
            );
          },
        ),
      );
    }

    onCancel();
  };

  const onCancel = () => {
    setAdditionalTime(0);
    setStaffAdjusted([]);
    setBreakdownData(combinedStaffData);
    setEditTime(false);
    setAdjustmentsToRemove([]);
  };

  const getTotalWithAdditionalSeconds = () => {
    if (
      !currentBillingPatient ||
      !breakdownData ||
      !combinedStaffData ||
      !staffAdjusted
    ) {
      return 0;
    }

    return (
      currentBillingPatient.totalSeconds +
      (breakdownData.reduce((acc, curr) => acc + curr.seconds, 0) -
        combinedStaffData.reduce((acc, curr) => acc + curr.seconds, 0)) +
      staffAdjusted.reduce((acc, curr) => acc + curr.secondsAdjusted, 0)
    );
  };

  const getFrequencyLabel = () => {
    const frequencyItem = frequency.find(
      (fr) => fr.value === enrollment.frequency,
    );
    return frequencyItem ? frequencyItem.text : enrollment.frequency;
  };

  return (
    <div
      className={`rounded-2.5 p-4 relative group hover:bg-white border hover:border-gray-foreground
        ${activeButton ? "bg-white border-gray-foreground" : "border-transparent"}`}
    >
      <div className="flex items-center justify-between mb-1 space-x-2">
        <p
          className={`text-base font-semibold group-hover:text-primary-blue truncate
            ${activeButton ? "text-primary-blue" : ""}`}
        >
          {enrollment.campaign.campaign}
        </p>

        <ActionsButton
          onClick={() => setSelectedEnrollment(enrollment)}
          actions={[
            withoutNumber && {
              icon: (
                <PhoneIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: "Phone Number",
              onClick: () => {
                dispatch(
                  setSelectedPatientProfileTabAction(
                    patientProfileTabs.INFORMATION,
                  ),
                );
                dispatch(setOpenEditInfoModalAction(true));
              },
            },
            !noConsent && {
              icon: (
                <GenerateIcon
                  width="21"
                  height="20"
                  className="flex-none"
                  fill="#667085"
                  stroke="#667085"
                />
              ),
              label: "Generate Note",
              onClick: () => {
                const dateStamp = selectedDate.format("YYYY-MM-DD");
                dispatch(
                  generateNote(
                    currentBillingPatient.monthlyBillId,
                    dateStamp,
                    () =>
                      dispatch(
                        getBillingPatient(
                          enrollment.patient_id,
                          dateStamp,
                          enrollment.campaign.campaign_type,
                        ),
                      ),
                  ),
                );
              },
            },
            {
              icon: <DeleteIcon width="20" height="20" className="flex-none" />,
              label: "Delete",
              onClick: onDelete,
            },
          ]}
        />
      </div>

      <div
        className="w-full flex flex-col sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-stretch
          lg:items-center justify-between"
      >
        {noConsent ? (
          <div
            className={`${cardButtonClasses} ${cardButtonInactiveClasses} w-fit`}
          >
            No Consent
          </div>
        ) : (
          <div className="flex items-center space-x-2 mb-2 sm:mr-2 sm:mb-0 md:mb-2 md:mr-0 lg:mb-0 lg:mr-2">
            <button
              type="button"
              className={`${cardButtonClasses}
                  ${activeButton === "Care Plan" ? cardButtonActiveClasses : cardButtonInactiveClasses}`}
              onClick={() => {
                if (activeButton === "Care Plan") {
                  setActiveButton("");
                } else {
                  setActiveButton("Care Plan");
                }
              }}
            >
              <CarePlanIcon
                width="16"
                height="16"
                className="flex-none"
                fill={activeButton === "Care Plan" ? "#2970FF" : "#667085"}
              />
              <p className="truncate">Care Plan</p>
            </button>
            {openByDefault && (
              <button
                type="button"
                className={`${cardButtonClasses}
                    ${activeButton === "Time" ? cardButtonActiveClasses : cardButtonInactiveClasses}`}
                onClick={() => {
                  if (activeButton === "Time") {
                    setActiveButton("");
                  } else {
                    setActiveButton("Time");
                  }
                }}
              >
                <TimeIcon
                  width="16"
                  height="16"
                  className="flex-none"
                  stroke={activeButton === "Time" ? "#2970FF" : "#667085"}
                />
                <p className="truncate">Time</p>
              </button>
            )}
          </div>
        )}

        {withoutNumber ? (
          <div className="flex items-center justify-end gap-2 text-sm text-tertiary font-medium">
            <PauseIcon width="20" height="20" stroke="#667085" />
            <p>Paused</p>
          </div>
        ) : activeButton === "Time" ? (
          <YearMonthPicker
            startDate={selectedDate}
            onSelectDate={onSelectDate}
            className="ml-auto text-sm font-semibold bg-gray-foreground rounded-full px-2 py-1 h-7"
            iconSize={16}
          />
        ) : (
          !noConsent &&
          enrollment.next_checkin && (
            <div className="flex items-center justify-end gap-1.5 text-sm text-tertiary font-medium">
              <p>
                {new Date(
                  `${enrollment.next_checkin.replace(/-/g, "/")} GMT+0`,
                ).toLocaleDateString("en-us", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </p>
              <CircleDivider />
              <p className="capitalize">{getFrequencyLabel()}</p>
            </div>
          )
        )}
      </div>

      {activeButton === "Care Plan" && (
        <div className="mt-4 border-t border-gray-foreground pt-4">
          <EnrollmentEditFields
            enrollment={enrollment}
            patient={selectedPatientInfo}
          />
        </div>
      )}

      {activeButton === "Time" &&
        (currentBillingPatient?.staff ? (
          <div className="mt-4 border-t border-gray-foreground pt-4">
            <TimeEditing
              billingType={billingType}
              billingPatient={currentBillingPatient}
              combinedStaffData={combinedStaffData}
              additionalTime={additionalTime}
              setAdditionalTime={setAdditionalTime}
              breakdownData={breakdownData}
              setBreakdownData={setBreakdownData}
              staffAdjusted={staffAdjusted}
              setStaffAdjusted={setStaffAdjusted}
              adjustmentsToRemove={adjustmentsToRemove}
              setAdjustmentsToRemove={setAdjustmentsToRemove}
              editableAdjustments
              editMode={editTime}
              onEditTime={() => setEditTime(true)}
              onClickNote={() => setOpenNoteModal(true)}
            />
            {editTime && (
              <div className="w-full flex justify-end space-x-4 text-sm font-semibold mt-5">
                <Button variant="gray" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  className="w-[124px]"
                  onClick={onConfirm}
                  disabled={!currentBillingPatient || timeAdjustLoading}
                >
                  {timeAdjustLoading ? (
                    <LoaderDots color="disabled" />
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-4 border-t border-gray-foreground pt-4">
            <div className="rounded-lg bg-gray-110 p-4 border border-gray-foreground">
              No time tracked for the selected month
            </div>
          </div>
        ))}

      {openNoteModal && isDesktop && (
        <Modal className="pt-5 rounded-2xl">
          <NoteContent
            onClose={onCloseNoteModal}
            onSave={onSaveNote}
            patientName={
              selectedPatientInfo.preferred_name
                ? selectedPatientInfo.preferred_name
                : `${selectedPatientInfo.first_name} ${selectedPatientInfo.last_name}`
            }
            name={currentBillingPatient.noteTitle}
            note={currentBillingPatient.note}
            className="w-144 grid grid-rows-chat-layout overflow-hidden h-fit max-h-full"
          />
        </Modal>
      )}

      {!isDesktop && (
        <ModalMobile
          open={!!openNoteModal}
          onClickAway={onCloseNoteModal}
          className=""
        >
          {currentBillingPatient && (
            <NoteContent
              onClose={onCloseNoteModal}
              onSave={onSaveNote}
              patientName={
                selectedPatientInfo.preferred_name
                  ? selectedPatientInfo.preferred_name
                  : `${selectedPatientInfo.first_name} ${selectedPatientInfo.last_name}`
              }
              name={currentBillingPatient.noteTitle}
              note={currentBillingPatient.note}
              className="w-full flex flex-col h-full"
              editorClassName="h-fit overflow-hidden"
            />
          )}
        </ModalMobile>
      )}
    </div>
  );
};

export default EnrollmentCard;
