import { debounce } from "@mui/material/utils";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import emptyTeamsIllustrationSrc from "../../assets/empty-teams.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as PatientHeartIcon } from "../../assets/icons/user-heart-rounded.svg";
import AvatarStack from "../../components/Basic/AvatarStack";
import UserTable from "../../components/Basic/UserTable";
import SettingsContainer from "../../components/Settings/SettingsContainer";
import SiteListItem from "../../components/Settings/SiteListItem";
import SitesTableSkeleton from "../../components/Skeletons/SitesTableSkeleton";
import { selectInputText } from "../../helpers/helpers";

const SitesSettings = () => {
  const { sites, sitesLoading } = useSelector((state) => state.site);
  const [searchValue, setSearchValue] = useState("");
  const [filteredSites, setFilteredSites] = useState(sites);

  const columns = [
    { label: "Clinic Name", accessor: "name" },
    { label: "Number of providers", accessor: "providers" },
    { label: "Number of patients", accessor: "total_patients" },
    { label: "Phone Number", accessor: "phone_number" },
  ];

  const getTableData = () => {
    const tableData = [];

    if (filteredSites?.length > 0) {
      filteredSites.forEach((site) =>
        tableData.push({
          name: <p className="font-bold">{site.display_name || site.name}</p>,
          providers: (
            <AvatarStack
              users={site.providers}
              length={4}
              totalNumber={site.providers.length}
            />
          ),
          total_patients: (
            <div
              className="text-xs font-bold text-tertiary px-2 py-1 bg-slate-100 rounded-full
                w-20 flex items-center justify-center space-x-1 border border-gray-foreground"
            >
              <PatientHeartIcon width="16" height="16" stroke="#667085" />
              <p>{site.total_patients}</p>
            </div>
          ),
          phone_number: <p>{site.phone_number || "-"}</p>,
        }),
      );
    }
    return tableData;
  };

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        setFilteredSites(
          sites.filter((site) =>
            site.display_name.toLowerCase().includes(inputValue.toLowerCase()),
          ),
        );
      }, 300),
    [sites],
  );

  return (
    <SettingsContainer>
      <div className="space-y-4 h-full md:flex md:flex-col md:overflow-hidden w-full">
        <div className="flex items-center justify-between gap-4">
          <p className="text-xl font-semibold hidden md:flex">Sites</p>

          <div className="relative w-full md:max-w-md">
            <input
              value={searchValue}
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder="Search sites..."
            />
            <SearchIcon
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>
        </div>

        {sitesLoading || filteredSites === null ? (
          <SitesTableSkeleton />
        ) : filteredSites.length > 0 ? (
          <>
            <UserTable
              columns={columns}
              data={getTableData()}
              className="hidden md:block"
            />
            <div className="space-y-3 md:hidden">
              {sites.map((site) => (
                <SiteListItem key={site.customer_id} site={site} />
              ))}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col items-center justify-center py-10 md:py-16 space-y-7">
            <img
              src={emptyTeamsIllustrationSrc}
              alt="Empty Sites"
              width="459"
              height="165"
            />
            <p className="text-xl font-bold">No Sites Found</p>
          </div>
        )}
      </div>
    </SettingsContainer>
  );
};

export default SitesSettings;
