import type { FixLater } from "../IndependentScribe/store/scribeSlice";

const Select = ({
  label = null,
  options,
  className = "",
  selectClassName = "",
  optionClass = "",
  borderColor = "gray-300",
  placeholder,
  required,
  withoutAsterisk = false,
  name = "",
  register = (_key, _options) => {},
  disabled = false,
  error = null,
  onChange = null,
  ...props
}: {
  label?: string;
  options: { value: string; text: string }[];
  className?: string;
  selectClassName?: string;
  optionClass?: string;
  borderColor?: string;
  placeholder?: string;
  required?: boolean | string;
  withoutAsterisk?: boolean;
  name?: string;
  register?: FixLater;
  disabled?: boolean;
  error?: any;
  onChange?: any;
  [key: string]: any;
}) => {
  return (
    <div className={`flex flex-col text-sm max-w-full ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <select
        {...register(name, { required })}
        disabled={disabled}
        onChange={(e) => {
          register(name)?.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        {...props}
        className={`p-2 pl-3 pr-6 rounded-lg border outline-none h-10 relative truncate ${selectClassName}
          ${error ? "border-red-400" : `border-${borderColor}`}
          ${disabled ? "bg-gray-150 opacity-100" : "bg-white"}`}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {options.length > 0 ? (
          options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              className={`truncate ${optionClass}`}
            >
              {option.text}
            </option>
          ))
        ) : (
          <option disabled value="">
            No options
          </option>
        )}
      </select>
      {error?.message && (
        <p className="text-red-400 font-normal mt-1.5">{error.message}</p>
      )}
    </div>
  );
};

export default Select;
