import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch } from "../../../../store";
import { updateTeam } from "../../../../store/user/thunks";
import type { Team } from "../../../../store/user/userReducer";
import Modal from "../../../Basic/Modal";
import ModalMobile from "../../../Basic/ModalMobile";
import IconSelection, { getTeamIcon } from "./IconSelection";

interface TeamIconButtonProps {
  team: Team;
}

const TeamIconButton = ({ team }: TeamIconButtonProps) => {
  const [openModal, setOpenModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  const handleClickIconButton = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSelectIcon = (icon: string) => {
    dispatch(
      updateTeam(
        team.doctor_team_id,
        team.name,
        team.show_inbox,
        icon,
        true,
        () => handleCloseModal(),
      ),
    );
  };

  return (
    <div
      className="w-6 h-6"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <button type="button" onClick={handleClickIconButton}>
        {getTeamIcon(team.team_icon, "24", "#667085")}
      </button>

      {isDesktop ? (
        openModal && (
          <Modal className="rounded-2xl">
            <div className="grid grid-rows-tab-layout overflow-hidden">
              <p className="p-5 text-xl font-semibold">Choose mention icon</p>
              <IconSelection
                currentIcon={team.team_icon}
                onCancel={handleCloseModal}
                onConfirm={handleSelectIcon}
              />
            </div>
          </Modal>
        )
      ) : (
        <ModalMobile
          header="Choose mention icon"
          open={openModal}
          onClickAway={handleCloseModal}
          childHeight={540}
          className=""
        >
          <IconSelection
            currentIcon={team.team_icon}
            onCancel={handleCloseModal}
            onConfirm={handleSelectIcon}
            className="pt-2"
          />
        </ModalMobile>
      )}
    </div>
  );
};

export default TeamIconButton;
