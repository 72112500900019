import type React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { VariableSizeList } from "react-window";
import emptyNotesIllustration from "../../../assets/no-encounters.svg";
import { patientPanelTabs } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setSelectedPatientPanelTabAction } from "../../../store/patient/actions";
import { getPatientScribes } from "../../../store/patient/thunks";
import { setOpenCallOptionsModalAction } from "../../../store/voiceRecorder/actions";
import { deleteScribe } from "../../../store/voiceRecorder/thunks";
import DeleteModal from "../../Basic/DeleteModal";
import { selectSelectedNoteTemplate } from "../../IndependentScribe/store/noteTemplateSlice";
import {
  setInterruptedRecordingAction,
  setScribeDeleted,
  setScribeResumeGenerated,
  setScribeSelectedAudioId,
} from "../../IndependentScribe/store/scribeSlice";
import {
  type ScribeGroupItem,
  getGroupedScribesByPatientId,
  getRecordingScribe,
  getSelectedScribe,
} from "../../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../../IndependentScribe/store/types";
import { isGeneratedNote } from "../../IndependentScribe/utils";
import NotesSkeleton from "../../Skeletons/NotesSkeleton";
import NoteCard from "./NoteCard";
import NoteHeader from "./NoteHeader";
import NoteInfo from "./NoteInfo";
import NotePreview from "./NotePreview";

const ITEM_HEIGHT = 86;
const HEADER_HEIGHT = 20;

const NotesTab = ({ startRecording }) => {
  const { id } = useParams();
  const selectedNoteTemplate = useAppSelector(selectSelectedNoteTemplate);
  const { profileLoading } = useAppSelector((state) => state.patient);
  const { isLoading } = useAppSelector((state) => state.scribe);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<VariableSizeList>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const openDeleteModalRef = useRef(false);
  const deleteNoteRef = useRef<ScribeGroupItem | null>(null);

  const recordingScribe = useAppSelector(getRecordingScribe);
  const selectedScribe = useAppSelector(getSelectedScribe);
  const groupedScribes = useAppSelector((state) =>
    getGroupedScribesByPatientId(state, Number(id)),
  );

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedParamAudioId = searchParams.get("id");

  useEffect(() => {
    if (selectedParamAudioId === selectedScribe?.audioId) {
      return;
    }

    if (!selectedScribe?.audioId) {
      setSearchParams({}, { replace: true });
      return;
    }

    setSearchParams({ id: selectedScribe?.audioId }, { replace: true });
  }, [selectedParamAudioId, selectedScribe?.audioId, setSearchParams]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          setContainerHeight(entry.contentRect.height);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getPatientScribes(id));

    const interval = setInterval(() => {
      dispatch(getPatientScribes(id));
    }, 3000);

    return () => clearInterval(interval);
  }, [id, dispatch]);

  const memoizedItems: { type: string; content: string | ScribeGroupItem[] }[] =
    useMemo(() => {
      const items = [];
      groupedScribes.forEach((group) => {
        items.push({ type: "header", content: group.date });
        items.push({
          type: "group",
          content: group.entries,
        });
      });
      return items;
    }, [groupedScribes]);

  useEffect(() => {
    if (listRef.current && memoizedItems.length > 0) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [memoizedItems]);

  const getItemSize = useCallback(
    (index: number) => {
      return memoizedItems[index].type === "header"
        ? HEADER_HEIGHT
        : ITEM_HEIGHT * memoizedItems[index].content.length + 16 + 24;
    },
    [memoizedItems],
  );

  const renderItem = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = memoizedItems[index];

      // console.log(item);

      if (item.type === "header") {
        return (
          <div style={style}>
            <p className="text-sm font-semibold uppercase">
              {item.content as string}
            </p>
          </div>
        );
      }

      if (item.type === "group") {
        return (
          <div style={style} className="pl-2 lg:pl-3 py-2">
            <div className="h-full border-l pl-2 lg:pl-4 py-3">
              {(item.content as ScribeGroupItem[]).map(
                (scribe: ScribeGroupItem) => (
                  <NoteCard
                    key={`${scribe.id}_${scribe.audioId}_${index}`}
                    note={scribe}
                    onClick={() => handleNoteTabClick(scribe)}
                    onDelete={() => {
                      deleteNoteRef.current = scribe;
                      openDeleteModalRef.current = true;
                    }}
                  />
                ),
              )}
            </div>
          </div>
        );
      }

      console.error("Invalid item type:", item.type);

      return null;
    },
    [memoizedItems],
  );

  const handleNoteTabClick = (note: ScribeGroupItem) => {
    if (
      selectedScribe?.isRecording &&
      selectedScribe?.audioId !== note.audioId &&
      !isGeneratedNote(note)
    ) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING,
          value: note.audioId,
        }),
      );

      return;
    }

    if (window.document.body.clientWidth < 768 && !isGeneratedNote(note)) {
      dispatch(setOpenCallOptionsModalAction(true));
    }

    dispatch(setScribeSelectedAudioId({ audioId: note.audioId }));
    dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
  };

  const handleDelete = () => {
    dispatch(
      deleteScribe(deleteNoteRef.current.audioId, () => {
        dispatch(getPatientScribes(id));
        dispatch(setScribeDeleted({ audioId: deleteNoteRef.current.audioId }));
        openDeleteModalRef.current = false;
      }),
    );
  };

  const onCloseNote = () => {
    dispatch(setScribeSelectedAudioId({ audioId: null }));
  };

  const onSaveNote = () => {
    dispatch(getPatientScribes(id));
  };

  const onResumeRecording = () => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.RESUME_GENERATED_ENCOUNTER,
        }),
      );
      return;
    }

    dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
    dispatch(setScribeSelectedAudioId({ audioId: selectedScribe.audioId }));
    dispatch(setScribeResumeGenerated());
    startRecording();
  };

  // console.log(
  //   selectedScribe,
  //   groupedScribes,
  //   selectedNoteTemplate,
  //   isLoading,
  //   profileLoading,
  // );

  return (
    <div ref={containerRef} className="h-full w-full">
      {selectedScribe ? (
        <>
          {selectedScribe?.audioId && (
            <NoteHeader note={selectedScribe} onClose={onCloseNote} />
          )}
          {selectedScribe?.audioId ? (
            selectedScribe?.isRecording ||
            selectedScribe?.isGenerating ||
            selectedScribe?.isPaused ? (
              <NotePreview sections={selectedNoteTemplate?.sections || []} />
            ) : (
              <NoteInfo
                scribe={selectedScribe}
                onSaveNote={onSaveNote}
                onResumeRecording={onResumeRecording}
              />
            )
          ) : (
            <NotesSkeleton />
          )}
        </>
      ) : groupedScribes.length > 0 && selectedNoteTemplate ? (
        <VariableSizeList
          ref={listRef}
          className="scrollbar"
          height={containerHeight}
          itemCount={memoizedItems.length}
          itemSize={getItemSize}
          width="100%"
          overscanCount={10}
        >
          {renderItem}
        </VariableSizeList>
      ) : isLoading || profileLoading || !selectedNoteTemplate ? (
        <NotesSkeleton />
      ) : (
        <div className="mx-auto w-full h-35vh-dynamic flex flex-col items-center justify-center">
          <img
            src={emptyNotesIllustration}
            width="578"
            height="200"
            className="mb-8"
            alt="No Notes"
          />
          <p className="text-xl lg:text-2xl font-semibold mb-1">
            No Previous Notes
          </p>
          <p className="text-base font-medium text-tertiary">
            We're ready whenever you are...
          </p>
        </div>
      )}
      {openDeleteModalRef.current && (
        <DeleteModal
          title="Delete Encounter"
          description="Are you sure you want to delete the note?"
          onDelete={handleDelete}
          onClose={() => {
            openDeleteModalRef.current = false;
          }}
        />
      )}
    </div>
  );
};

export default NotesTab;
