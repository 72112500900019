import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import LocalStorageService from "../../services/LocalStorageService";
import { setNotificationAction } from "../user/actions";
import {
  setAllNotesGeneratedAction,
  setBillingLoadingAction,
  setBillingNumbersAction,
  setBillingPatientsAction,
  setCurrentBillingPatientAction,
  setExportLoadingAction,
  setNumBillingPatientsAction,
  setTimeAdjustLoadingAction,
} from "./actions";
import {
  billingNumbersSchema,
  billingPatientSchema,
  billingPatientsSchema,
  exportBillingDataSchema,
} from "./validationSchemas";

const storageService = new LocalStorageService();

export const openConversationEvent = async (patientId, campaignType) => {
  axios
    .post(`${BASE_URL}/track/event`, {
      tracking_event_type_id: 1,
      patientId: patientId,
      campaignType: campaignType === "CCM" ? "Outreach" : campaignType,
    })
    .catch((e: Error | AxiosError) => {
      handleRequestError(e);
    });
};

export const trackTime = async (seconds) => {
  axios
    .post(`${BASE_URL}/track/time-log`, {
      seconds: seconds,
    })
    .catch((e: Error | AxiosError) => {
      handleRequestError(e);
    });
};

export const getBillingNumbers = (campaignType, dateStamp) => (dispatch) => {
  axios
    .post(`${BASE_URL}/billing/patients`, {
      "month-stamp": dateStamp, // "yyyy-mm-01"
      offset: 0,
      limit: 50,
      searchQuery: "",
      campaignType:
        campaignType === "all"
          ? null
          : campaignType === "CCM"
            ? "Outreach"
            : campaignType,
      cptCode: null,
      sortColumn: null,
    })
    .then((response) => {
      const enrolledNumber = response.data.enrolled;
      const readyToBillNumber = response.data.ready_to_bill;

      handleValidation(
        billingNumbersSchema,
        { enrolled: enrolledNumber, ready_to_bill: readyToBillNumber },
        "getBillingNumbers",
      );

      dispatch(
        setBillingNumbersAction({
          enrolled: enrolledNumber,
          readyToBill: readyToBillNumber,
        }),
      );
    })
    .catch((e: Error | AxiosError) => {
      handleRequestError(e);
    });
};

export const generateAllNotes =
  (dateStamp, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/billing/generate-notes`, {
        "month-stamp": dateStamp, // "yyyy-mm-01"
        offset: 0,
        limit: null,
        searchQuery: "",
        campaignType: null,
        cptCode: null,
        sortColumn: null,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to generate notes",
          );
        } else {
          onSuccess();
        }
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to generate notes");
      });
  };

export const generateNote =
  (monthlyBillId, dateStamp, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/billing/generate-note/${monthlyBillId}`, {
        "month-stamp": dateStamp, // "yyyy-mm-01"
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to generate note",
          );
        } else {
          onSuccess();
        }
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to generate note");
      });
  };

export const updateBillingNote =
  (monthlyBillId, newNote, onSuccess = () => {}, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/billing/note/${monthlyBillId}`, {
        note: newNote,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update note",
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated note",
              desc: "Note has been successfully updated",
            }),
          );
          onSuccess();
        }
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to update note");
      })
      .finally(() => {
        onEnd();
      });
  };

export const getBillingPatients =
  (
    dateStamp,
    rows,
    search = "",
    campaignType = "all",
    cptCode = "all",
    sortColumn = null,
    sortDirection = "asc",
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    const sorting = sortColumn === "null" ? null : sortColumn;

    axios
      .post(`${BASE_URL}/billing/patients`, {
        "month-stamp": dateStamp, // "yyyy-mm-01"
        offset: 0,
        limit: rows,
        searchQuery: search,
        campaignType:
          campaignType === "all"
            ? null
            : campaignType === "CCM"
              ? "Outreach"
              : campaignType,
        cptCode: cptCode === "all" ? null : cptCode,
        sortColumn: sorting
          ? { type: sorting, direction: sortDirection }
          : null,
      })
      .then((response) => {
        const results = response.data.results.map((item) => ({
          ...item,
          billingType:
            item.billingType === "Outreach"
              ? "CCM"
              : item.billingType === "Outreach_PCM"
                ? "PCM"
                : item.billingType === "Outreach_BHI"
                  ? "BHI"
                  : item.billingType,
        }));

        const totalCount = response.data.totalCount;
        const generatedAll = response.data.note_generated_all;

        handleValidation(
          billingPatientsSchema,
          {
            results: results,
            totalCount: totalCount,
            note_generated_all: generatedAll,
          },
          "getBillingPatients",
        );

        const filteredResults = results.filter(
          (patient) =>
            patient.billingType === "CCM" ||
            patient.billingType === "BHI" ||
            patient.billingType === "PCM",
        );
        const hiddenCount = results.length - filteredResults.length;

        dispatch(setAllNotesGeneratedAction(generatedAll));
        dispatch(setNumBillingPatientsAction(totalCount - hiddenCount));
        dispatch(setBillingPatientsAction(filteredResults));
        onSuccess();
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to get billing patients");
      })
      .finally(() => {
        dispatch(setBillingLoadingAction(false));
      });
  };

export const getBillingPatient =
  (patientId, dateStamp, campaignType, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/billing/patient`, {
        patientId: patientId,
        "month-stamp": dateStamp, // "yyyy-mm-01"
        campaignType: campaignType === "CCM" ? "Outreach" : campaignType,
      })
      .then((response) => {
        const billingPatient = response.data;

        handleValidation(
          billingPatientSchema,
          billingPatient,
          "getBillingPatient",
        );

        dispatch(setCurrentBillingPatientAction(billingPatient));
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to get billing patient");
      })
      .finally(() => {
        onEnd();
      });
  };

export const adjustBillingPatient =
  (monthlyBillId, totalSecondsDiff, staff, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setTimeAdjustLoadingAction(true));
    axios
      .post(`${BASE_URL}/billing/patient-adjust`, {
        monthlyBillId: monthlyBillId,
        totalSecondsAdjusted: totalSecondsDiff,
        staff: staff,
      })
      .then(() => {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully adjusted time",
            desc: "Time has been successfully adjusted",
          }),
        );
        onSuccess();
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to adjust time");
      })
      .finally(() => {
        dispatch(setTimeAdjustLoadingAction(false));
      });
  };

export const exportBillingData =
  (dateStamp, rows = 1000, search = "") =>
  (dispatch) => {
    dispatch(setExportLoadingAction(true));
    const token = storageService.getItem("token");
    axios
      .get(
        `${BASE_URL}/billing/export?token=${token}&month-stamp=${dateStamp}&searchQuery=${search}&limit=${rows}&offset=0`,
      )
      .then((response) => {
        const billingData = response.data;

        handleValidation(
          exportBillingDataSchema,
          billingData,
          "exportBillingData",
        );

        const csvContent = `data:text/csv;charset=windows-1252,${billingData}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `billing-${dateStamp}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e);
      })
      .finally(() => {
        dispatch(setExportLoadingAction(false));
      });
  };

export const removeAdjustedTime =
  (monthlyBillId, adjustments, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setTimeAdjustLoadingAction(true));
    axios
      .post(`${BASE_URL}/billing/remove-patient-adjust`, {
        monthlyBillId: monthlyBillId,
        adjustments: adjustments,
      })
      .then(() => {
        onSuccess();
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e);
      })
      .finally(() => {
        dispatch(setTimeAdjustLoadingAction(false));
      });
  };
