import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as NoteIcon } from "../../assets/icons/billing-note.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/exclamation-circle.svg";
import { ReactComponent as GenerateIcon } from "../../assets/icons/file-check.svg";
import { rowsPerPage } from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setSortedByAction,
  setSortingDirectionAction,
} from "../../store/billingMetrics/actions";
import {
  generateNote,
  getBillingPatients,
  updateBillingNote,
} from "../../store/billingMetrics/thunks";
import ActionsButton from "../Basic/ActionsButton";
import Loader from "../Basic/Loader";
import Modal from "../Basic/Modal";
import ModalMobile from "../Basic/ModalMobile";
import TrackingTableSkeleton from "../Skeletons/TrackingTableSkeleton";
import MetricsTableData from "./MetricsTableData";
import MetricsTableHeader from "./MetricsTableHeader";
import NoteContent from "./NoteContent";
import TimeButton from "./TimeButton";

const MetricsTable = () => {
  const {
    loading,
    billingPatients,
    currentDateStamp,
    currPage,
    selectedBillingType,
    selectedCptCode,
    sortedBy,
    sortingDirection,
    billingSearchValue,
  } = useAppSelector((state) => state.billingMetrics);
  const [selectedNote, setSelectedNote] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        getBillingPatients(
          currentDateStamp,
          rowsPerPage * currPage,
          billingSearchValue,
          selectedBillingType,
          selectedCptCode,
          sortedBy,
          sortingDirection,
        ),
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [
    dispatch,
    currentDateStamp,
    currPage,
    billingSearchValue,
    selectedBillingType,
    selectedCptCode,
    sortedBy,
    sortingDirection,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        id: "patientName",
        Cell: ({ row }) => (
          <p className="w-full text-left">
            {row.original.preferred_name
              ? row.original.preferred_name
              : row.original.first_name}{" "}
            {row.original.last_name}
          </p>
        ),
      },

      {
        Header: "Conditions",
        accessor: "conditions",
        Cell: ({ row }) => {
          return (
            <div className="truncate max-w-[230px] xl:max-w-[350px] 2xl:max-w-[600px]">
              {row.original.conditions
                .replace(/\d+\.\s*/g, "")
                .replace(/\n/g, ", ")}
            </div>
          );
        },
      },
      {
        Header: "Streaks",
        accessor: "streaks",
      },
      {
        Header: "CPT Codes",
        accessor: "cptCode",
        Cell: ({ row }) => {
          if (row.original.cptCode.length === 0) {
            return "N/A";
          }

          const chunkSize = 3;
          const chunkedData = [];
          for (let i = 0; i < row.original.cptCode.length; i += chunkSize) {
            chunkedData.push(row.original.cptCode.slice(i, i + chunkSize));
          }

          return (
            <div>
              {chunkedData.map((chunk, index) => (
                <p key={index}>
                  {chunk.join(", ")}
                  {/* If this isn't the last chunk, add a trailing comma */}
                  {index !== chunkedData.length - 1 && ","}
                </p>
              ))}
            </div>
          );
        },
        id: "code",
        isSortable: true,
      },
      {
        Header: "Billing",
        accessor: "billingType",
        id: "billing",
        isSortable: true,
        Cell: ({ row }) => {
          return (
            <button
              type="button"
              className="rounded-full border border-grey-foreground bg-slate-100 w-[58px] h-7
                flex items-center justify-center text-tertiary text-sm font-semibold relative"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (row.original.note) {
                  setSelectedNote({
                    monthlyBillId: row.original.monthlyBillId,
                    note: row.original.note,
                    noteTitle: row.original.note_title,
                    name: row.original.preferred_name
                      ? row.original.preferred_name
                      : `${row.original.first_name} ${row.original.last_name}`,
                  });
                }
              }}
              disabled={!row.original.note}
            >
              {row.original.billingType}

              <div className="w-5 h-5 absolute -top-1.5 -right-1.5 flex items-center justify-center flex-none">
                {row.original.note_status === "generating" ? (
                  <Loader size={4} borderWidth={2} />
                ) : row.original.note &&
                  row.original.note_status === "not_enough" ? (
                  <ExclamationIcon
                    width="20"
                    height="20"
                    className="flex-none"
                  />
                ) : (
                  row.original.note && (
                    <NoteIcon width="20" height="20" className="flex-none" />
                  )
                )}
              </div>
            </button>
          );
        },
      },
      {
        Header: "Total Time",
        accessor: "totalTime",
        Cell: ({ row }) => <TimeButton patient={row.original} />,
        id: "time",
        isSortable: true,
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
          return (
            <ActionsButton
              actions={[
                {
                  icon: (
                    <GenerateIcon
                      width="21"
                      height="20"
                      className="flex-none"
                      fill="#667085"
                      stroke="#667085"
                    />
                  ),
                  label: "Generate Note",
                  onClick: () => {
                    dispatch(
                      generateNote(
                        row.original.monthlyBillId,
                        currentDateStamp,
                        () =>
                          dispatch(
                            getBillingPatients(
                              currentDateStamp,
                              rowsPerPage * currPage,
                              billingSearchValue,
                              selectedBillingType,
                              selectedCptCode,
                              sortedBy,
                              sortingDirection,
                            ),
                          ),
                      ),
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [
      dispatch,
      billingSearchValue,
      selectedCptCode,
      selectedBillingType,
      currentDateStamp,
      currPage,
      sortedBy,
      sortingDirection,
    ],
  );

  const onSort = (columnId, direction) => {
    dispatch(setSortedByAction(columnId));
    dispatch(setSortingDirectionAction(direction));
    dispatch(
      getBillingPatients(
        currentDateStamp,
        rowsPerPage * currPage,
        billingSearchValue,
        selectedBillingType,
        "all",
        columnId,
        direction,
      ),
    );
  };

  const onSaveNote = (updatedNote) => {
    dispatch(
      updateBillingNote(selectedNote.monthlyBillId, updatedNote, () => {
        setSelectedNote(null);
        dispatch(
          getBillingPatients(
            currentDateStamp,
            rowsPerPage * currPage,
            billingSearchValue,
            selectedBillingType,
            selectedCptCode,
            sortedBy,
            sortingDirection,
          ),
        );
      }),
    );
  };

  const onCloseNoteModal = () => {
    setSelectedNote(null);
  };

  return (
    <div className="w-full flex flex-col overflow-hidden">
      <MetricsTableHeader onSort={onSort} />

      {loading || !billingPatients ? (
        <TrackingTableSkeleton />
      ) : (
        <MetricsTableData
          columns={columns}
          data={billingPatients}
          setSelectedNote={setSelectedNote}
          onSort={onSort}
        />
      )}

      {selectedNote && isDesktop && (
        <Modal className="pt-5 rounded-2xl">
          <NoteContent
            onClose={onCloseNoteModal}
            onSave={onSaveNote}
            patientName={selectedNote.name}
            name={selectedNote.noteTitle}
            note={selectedNote.note}
            className="w-144 grid grid-rows-chat-layout overflow-hidden h-fit max-h-full"
          />
        </Modal>
      )}

      {!isDesktop && (
        <ModalMobile
          open={!!selectedNote}
          onClickAway={onCloseNoteModal}
          className=""
        >
          {selectedNote && (
            <NoteContent
              onClose={onCloseNoteModal}
              onSave={onSaveNote}
              patientName={selectedNote.name}
              name={selectedNote.noteTitle}
              note={selectedNote.note}
              className="w-full flex flex-col h-full"
              editorClassName="h-fit overflow-hidden"
            />
          )}
        </ModalMobile>
      )}
    </div>
  );
};

export default MetricsTable;
