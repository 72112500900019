import Popover, { type PopoverOrigin } from "@mui/material/Popover";
import { useState } from "react";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";

interface ActionsButtonProps {
  onClick?: () => void;
  actions: {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
  }[];
  width?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popMargin?: number | string;
  className?: string;
  buttonClassName?: string;
  label?: React.ReactNode;
}

const ActionsButton = ({
  onClick = () => {},
  actions,
  width = "175px",
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  popMargin = "0",
  className = "ml-auto w-fit",
  buttonClassName = "w-8 h-8 rounded-md hover:bg-gray-foreground",
  label = <DotsIcon width="20" height="20" />,
}: ActionsButtonProps) => {
  const [openActions, setOpenActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onCloseOptions = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenActions(false);
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <button
        type="button"
        className={`flex items-center justify-center ${buttonClassName}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpenActions(true);
          setAnchorEl(e.currentTarget);
          onClick();
        }}
      >
        {label}
      </button>

      {openActions && anchorEl && (
        <Popover
          open={openActions}
          onClose={onCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: width,
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: popMargin,
              },
            },
          }}
        >
          <div className="w-full flex flex-col space-y-2 p-2 text-sm font-medium">
            {actions.map(
              (action, index) =>
                action && (
                  <button
                    type="button"
                    key={index}
                    className="p-2.5 flex items-center space-x-2.5 rounded-lg hover:bg-gray-foreground whitespace-nowrap"
                    onClick={(e) => {
                      action.onClick();
                      onCloseOptions(e);
                    }}
                  >
                    {action.icon && action.icon}
                    {action.label && <p>{action.label}</p>}
                  </button>
                ),
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default ActionsButton;
