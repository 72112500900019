import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as SortIcon } from "../../assets/icons/arrows-up-down.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";
import UserTable from "../Basic/UserTable";

const TrackingTableSkeleton = ({
  rows = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  const columns = [
    { label: "Full Name", accessor: "name" },
    {
      label: <p className="w-full text-center">Conditions</p>,
      accessor: "conditions",
    },
    { label: "Streaks", accessor: "streaks" },
    {
      label: (
        <div className="flex items-center space-x-2">
          <p>CPT Codes</p> <SortIcon width="16" height="16" />
        </div>
      ),
      accessor: "code",
    },
    {
      label: (
        <div className="flex items-center space-x-2">
          <p>Billing</p> <SortIcon width="16" height="16" />
        </div>
      ),
      accessor: "billing",
    },
    {
      label: (
        <div className="flex items-center space-x-2">
          <p>Total Time</p> <SortIcon width="16" height="16" />
        </div>
      ),
      accessor: "time",
    },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        name: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={80}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px", marginRight: "50px" }}
          />
        ),
        conditions: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={258}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        streaks: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={32}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        code: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={80}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        billing: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={60}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        time: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={106}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        actions: (
          <div className="flex items-center justify-center w-8 h-8 rounded-md">
            <DotsIcon width="20" height="20" />
          </div>
        ),
      });
    }
    return tableData;
  };

  return (
    <div className="grid w-full">
      <UserTable
        columns={columns}
        data={getTableData()}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-3">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`patient-${index}`}
            className="p-4 rounded-xl border-b border-gray-foreground space-y-2 bg-white"
          >
            <div className="flex items-center justify-between">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={120}
                height={16}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <DotsIcon width="20" height="20" />
              </div>
            </div>
            <Skeleton
              animation={animation}
              variant="rounded"
              width={216}
              height={12}
              sx={{ bgcolor: bgColor, borderRadius: "12px" }}
            />
            <div className="flex items-center justify-between mt-1">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={159}
                height={24}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={64}
                height={24}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrackingTableSkeleton;
