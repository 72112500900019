import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import { baseApi } from "../baseApi";
import type { AppDispatch } from "../index";
import { callCountsSchema } from "./validationSchemas";

export const getCallCounts = () => (dispatch: AppDispatch) => {
  axios
    .post(`${BASE_URL}/inbox/count`, {
      numbers_to_count: [
        "hours_saved",
        "incoming_calls",
        "me",
        "teams",
        "total_reviews",
        "total_completed",
      ],
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load call counts",
        );
      } else {
        const callCounts = response.data;

        handleValidation(callCountsSchema, callCounts, "getCallCounts");
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load call counts");
    });
};

export const addCallNote =
  (assistantCallId: number, note: string, onSuccess: () => void = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/call/note/create/${assistantCallId}`, { note })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to add note to the call",
          );
        } else {
          dispatch(
            baseApi.util.invalidateTags([
              { type: "CallNote", id: assistantCallId },
            ]),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to add note to the call");
      });
  };

export const updateCallNote =
  (
    assistantCallId: number,
    assistantCallNoteId: number,
    note: string,
    onSuccess: () => void = () => {},
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/call/note/update/${assistantCallNoteId}`, { note })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update note",
          );
        } else {
          dispatch(
            baseApi.util.invalidateTags([
              { type: "CallNote", id: assistantCallId },
            ]),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update note");
      });
  };

export const deleteCallNote =
  (assistantCallId: number, assistantCallNoteId: number) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/call/note/delete/${assistantCallNoteId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete note",
          );
        } else {
          dispatch(
            baseApi.util.invalidateTags([
              { type: "CallNote", id: assistantCallId },
            ]),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete note");
      });
  };
