import type React from "react";
import { useEffect, useRef, useState } from "react";
import { type Control, Controller, type FieldError } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import ErrorRoundExclamation from "../ErrorRoundExclamation";
import "react-phone-input-2/lib/style.css";
import "./CountryPhoneInput.css";

interface CountryPhoneInputProps {
  name: string;
  control: Control<any>;
  defaultCountry?: string;
  countries?: string[];
  preferredCountries?: string[];
  placeholder?: string;
  className?: string;
  label?: string;
  required?: boolean | string;
  withoutAsterisk?: boolean;
  error?: FieldError;
  resizeDeps?: any[];
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

interface PhoneInputStyles extends React.CSSProperties {
  borderColor: string;
}

const CountryPhoneInput = ({
  name,
  control,
  defaultCountry = "us",
  countries = ["us"],
  preferredCountries,
  placeholder = "Enter phone number",
  className = "",
  label,
  required = false,
  withoutAsterisk = false,
  error,
  resizeDeps = [],
  ...props
}: CountryPhoneInputProps) => {
  const [width, setWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.clientWidth);
      }
    };

    if (containerRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [...resizeDeps]);

  return (
    <div
      className={`flex flex-col text-sm ${className}`}
      ref={containerRef}
      data-testid="phone-input-container"
    >
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600" data-testid="required-asterisk">
              *
            </span>
          )}
        </p>
      )}
      <div className="relative">
        <Controller
          name={name}
          control={control}
          rules={{
            required:
              typeof required === "string"
                ? required
                : required
                  ? "This field is required"
                  : false,
          }}
          render={({ field: { name, value, onChange, ref, onBlur } }) => (
            <PhoneInput
              placeholder={placeholder}
              country={defaultCountry}
              onlyCountries={countries}
              preferredCountries={preferredCountries}
              preserveOrder={["onlyCountries", "preferredCountries"]}
              countryCodeEditable={false}
              inputStyle={
                {
                  borderColor: error ? "#f87171" : "#d1d5db",
                } as PhoneInputStyles
              }
              dropdownClass="scrollbar"
              dropdownStyle={{ width }}
              value={value}
              onChange={(value: string) => {
                onChange(value);
                props.onChange?.(value);
              }}
              onBlur={() => {
                onBlur();
                props.onBlur?.();
              }}
              inputProps={{
                autoFocus: false,
                name,
                ref,
                "data-testid": "phone-input",
              }}
              {...props}
            />
          )}
        />
        {error && (
          <ErrorRoundExclamation
            className="absolute top-1/2 -translate-y-1/2 right-4"
            data-testid="error-icon"
          />
        )}
      </div>
      {error?.message && (
        <p
          className="text-red-400 font-normal mt-1.5"
          data-testid="error-message"
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

export default CountryPhoneInput;
