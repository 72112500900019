import automaticProfileSrc from "../../../assets/quadrant-ai.svg";
import { useAppSelector } from "../../../store";
import type { Conversation } from "../../../store/call/interfaces";
import {
  type Message,
  MessageType,
} from "../../../store/conversation/interfaces";
import { selectUser } from "../../../store/user/userReducer";
import type { MentionItem } from "../Mentions/interfaces";
import CallTimelineCard from "./CallCard/CallTimelineCard";
import DateDivider from "./DateDivider";
import MessageCloud from "./MessageCloud";
import StatusUpdateMessage from "./StatusUpdateMessage";
import { getMessageDisplayProperties } from "./messageDisplayUtils";
import {
  getMessageProfilePicture,
  getMessageSenderFirstName,
} from "./messageUtils";

interface MessageListProps {
  conversation: Conversation;
  mentionItems: MentionItem[];
  patientPicture: string;
  dateRefs: React.MutableRefObject<any[]>;
}

const MessageList = ({
  conversation,
  mentionItems,
  patientPicture,
  dateRefs,
}: MessageListProps) => {
  const user = useAppSelector(selectUser);

  if (!conversation?.messages.length) {
    return null;
  }

  return (
    <>
      {conversation.messages.map((message: Message, index: number) => {
        const isDoctor = message.from === "doctor";
        const isAutomatic = message.is_automatic === 1;
        const isOutgoing =
          isAutomatic ||
          (isDoctor && message.sender?.doctor_id === user?.doctor_id);
        const { combineWithPrevious, dateDivider } =
          getMessageDisplayProperties(message, index, conversation);

        if (message.type === MessageType.Call) {
          return (
            <CallTimelineCard
              key={index}
              call={message.data.call}
              patient={conversation.patient}
              className="mt-3"
            />
          );
        }

        if (
          message.type === MessageType.StatusUpdate &&
          message.data.status_update
        ) {
          return (
            <StatusUpdateMessage
              key={index}
              className="my-8"
              statusUpdateData={message.data.status_update}
            />
          );
        }

        if (message.type === MessageType.Text) {
          return (
            <div key={index}>
              {dateDivider && (
                <DateDivider
                  date={message.send_at}
                  ref={(el) => {
                    dateRefs.current[index] = el;
                  }}
                />
              )}
              <MessageCloud
                mentionItems={mentionItems}
                combineWithPrevious={combineWithPrevious}
                type={isOutgoing ? "outgoing" : "incoming"}
                firstName={getMessageSenderFirstName(message, isAutomatic)}
                lastName={isAutomatic ? "AI" : message.sender.last_name}
                displayName={isAutomatic ? "" : message.sender.display_name}
                role={
                  isDoctor && !isAutomatic ? message.sender?.occupation : ""
                }
                profilePicture={getMessageProfilePicture(
                  message,
                  isAutomatic,
                  isDoctor,
                  patientPicture,
                  automaticProfileSrc,
                )}
                message={message.data.message}
                time={message.send_at}
                isInternal={message.is_internal === 1}
              />
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export default MessageList;
