import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "./InputMultipleEmails.css";

const InputMultipleEmails = ({
  className = "",
  label,
  placeholder,
  required,
  withoutAsterisk,
  emails,
  setEmails,
  error,
  setError,
}) => {
  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <ReactMultiEmail
        className={error ? "border-red-400" : "border-gray-300"}
        placeholder={placeholder}
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
          setError("");
        }}
        autoFocus={false}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag="tag" key={index}>
              <div data-tag-item="tag-item">{email}</div>
              <span
                data-tag-handle="tag-handle"
                onClick={() => removeEmail(index)}
              >
                ×
              </span>
            </div>
          );
        }}
      />
      {error && <p className="text-red-400 font-normal mt-1.5">{error}</p>}
    </div>
  );
};

export default InputMultipleEmails;
