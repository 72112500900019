import { useState } from "react";
import { useDebounce } from "use-debounce";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ClinicTabs } from "../../../helpers/constants";
import { selectInputText } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setSelectedClinicTabAction,
  setTeamSearchValueAction,
  setUserSearchValueAction,
} from "../../../store/user/actions";
import { useGetTeamsQuery } from "../../../store/user/api";
import { useGetUsersQuery } from "../../../store/user/api";
import Button from "../../Basic/Button";
import TabsBg from "../../Basic/TabsBg";
import Teams from "./Teams/Teams";
import Users from "./Users/Users";

const ClinicUsers = () => {
  const { USERS, TEAMS } = ClinicTabs;
  const { selectedClinicTab, userSearchValue, teamSearchValue } =
    useAppSelector((state) => state.user);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [userSearchFilter] = useDebounce(userSearchValue, 200);
  const [teamSearchFilter] = useDebounce(teamSearchValue, 200);

  const dispatch = useAppDispatch();

  useGetUsersQuery(
    { search: userSearchFilter },
    { skip: selectedClinicTab !== USERS },
  );
  useGetTeamsQuery(
    { search: teamSearchFilter },
    { skip: selectedClinicTab !== TEAMS },
  );

  const handleSearchInput = (e) => {
    if (selectedClinicTab === USERS) {
      dispatch(setUserSearchValueAction(e.target.value));
    } else {
      dispatch(setTeamSearchValueAction(e.target.value));
    }
  };

  return (
    <div className="h-full md:flex md:flex-col md:overflow-hidden">
      <div className="mb-4 flex flex-col md:flex-row w-full md:items-center justify-between gap-4 md:gap-8">
        <TabsBg
          tabs={Object.values(ClinicTabs)}
          activeTab={selectedClinicTab}
          onChange={(tab) => dispatch(setSelectedClinicTabAction(tab))}
          className="w-full md:w-fit bg-white text-sm"
          tabClassName="py-2 px-8 text-center"
        />

        <div className="flex items-center space-x-3 w-full md:max-w-md">
          <div className="relative w-full">
            <input
              value={
                selectedClinicTab === USERS ? userSearchValue : teamSearchValue
              }
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder={
                selectedClinicTab === USERS
                  ? "Search user..."
                  : "Search teams..."
              }
            />
            <SearchIcon
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>

          <Button
            variant="blue-light"
            onClick={() => {
              if (selectedClinicTab === USERS) {
                setOpenUserModal(true);
              }
              if (selectedClinicTab === TEAMS) {
                setOpenTeamModal(true);
              }
            }}
            className="font-semibold text-sm sm:text-base space-x-2 w-10 md:w-[131px] flex-none"
          >
            <PlusIcon
              width="20"
              height="20"
              stroke="#2970FF"
              className="flex-none"
            />
            <p className="hidden md:flex whitespace-nowrap">
              Add {selectedClinicTab === USERS ? "User" : "Team"}
            </p>
          </Button>
        </div>
      </div>

      {selectedClinicTab === USERS && (
        <Users openModal={openUserModal} setOpenModal={setOpenUserModal} />
      )}
      {selectedClinicTab === TEAMS && (
        <Teams openModal={openTeamModal} setOpenModal={setOpenTeamModal} />
      )}
    </div>
  );
};

export default ClinicUsers;
