import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as MessageIcon } from "../../../assets/icons/chat-round-2.svg";
import { ReactComponent as CallIcon } from "../../../assets/icons/phone-new.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x-mark.svg";
import { twilioDeviceStatuses } from "../../../helpers/constants";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import TwilioService from "../../../services/TwilioService";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  useGetConversationQuery,
  useMoveToCompletedMutation,
  useMoveToReviewMutation,
} from "../../../store/call/api";
import {
  getIsMessageInputOpen,
  getSelectedConversationId,
  getSelectedItemPatient,
  setIsMessageInputOpen,
  setSelectedConversationId,
} from "../../../store/call/callSlice";
import type { CallSearchParams } from "../../../store/call/interfaces";
import type { InboxItem, InboxPatient } from "../../../store/call/interfaces";
import { setMessagesAction } from "../../../store/conversation/actions";
import { selectUser } from "../../../store/user/userReducer";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
} from "../../../store/voiceRecorder/actions";
import { getTwilioToken } from "../../../store/voiceRecorder/thunks";
import PatientShortInfo from "../../PatientProfile/PatientShortInfo";
import MessageTab from "../../PatientProfile/RightPanel/Message/MessageTab";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import CallControlButtons from "./CallControlButtons";
import CallStatusButtons from "./CallStatusButtons";

const buttonStyle =
  "w-8 h-8 rounded-lg border border-gray-110 flex items-center justify-center";

interface CallDetailsProps {
  onClose?: () => void;
  className?: string;
  nextConversation: {
    patient: InboxPatient;
    items: InboxItem[];
  };
}

const CallDetails = ({
  onClose = () => {},
  className = "",
  nextConversation,
}: CallDetailsProps) => {
  const { OFFLINE } = twilioDeviceStatuses;

  const user = useAppSelector(selectUser);
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const isMessageInputOpen = useAppSelector(getIsMessageInputOpen);
  const selectedItemPatient = useAppSelector(getSelectedItemPatient);

  const { data: callConversation } = useGetConversationQuery(
    selectedConversationId ?? skipToken,
  );

  const { messages } = useAppSelector((state: RootState) => state.conversation);
  const { twilioToken, deviceStatus, callEvent, openCallModal } =
    useAppSelector((state: RootState) => state.voiceRecorder);

  const dispatch = useAppDispatch();
  const [isMuted, setIsMuted] = useState(false);
  const twilioServiceRef = useRef(new TwilioService(dispatch));
  const [callSeconds, setCallSeconds] = useState(0);
  const callInterval = useRef(null);

  const [moveToReview] = useMoveToReviewMutation();
  const [moveToCompleted] = useMoveToCompletedMutation();

  const patient = callConversation?.patient;

  useEffect(() => {
    if (callInterval.current) {
      clearInterval(callInterval.current);
      callInterval.current = null;
    }

    if (deviceStatus !== twilioDeviceStatuses.IN_PROGRESS) {
      setCallSeconds(0);

      return;
    }

    callInterval.current = setInterval(() => {
      setCallSeconds((callSeconds) => callSeconds + 1);
    }, 1000);

    return () => {
      if (callInterval.current) {
        clearInterval(callInterval.current);
        callInterval.current = null;
      }
    };
  }, [deviceStatus]);

  useEffect(() => {
    twilioServiceRef.current.destroyTwilioDevice();
    if (twilioToken && user.customer?.phone_number) {
      twilioServiceRef.current.initializeTwilioDevice(twilioToken);
    }
  }, [twilioToken, user.customer?.phone_number]);

  useEffect(() => {
    if (deviceStatus === twilioDeviceStatuses.OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, dispatch]);

  useEffect(() => {
    if (callEvent?.status && twilioServiceRef.current) {
      if (callEvent.status === "ringing") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.RINGING));
      }
      if (callEvent.status === "in-progress") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.IN_PROGRESS));
      }
      if (
        callEvent.status === "completed" ||
        callEvent.status === "no-answer"
      ) {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
        twilioServiceRef.current.handleDisconnect();
      }
    }
  }, [callEvent?.status, dispatch]);

  const handleDisconnect = useCallback(() => {
    setIsMuted(false);
    dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
    twilioServiceRef.current.handleDisconnect();
  }, [dispatch]);

  const handleCall = useCallback(() => {
    if (callConversation?.patient.phone_number) {
      dispatch(setOpenCallModalAction(true));
      twilioServiceRef.current.handleCall(
        callConversation.patient.phone_number,
      );
    }
  }, [callConversation?.patient.phone_number, dispatch]);

  const handleMute = useCallback(() => {
    setIsMuted(!isMuted);
    twilioServiceRef.current.toggleMute(!isMuted);
  }, [isMuted]);

  const handleCompleteCall = (patientId: number) => {
    if (!patientId) {
      return;
    }

    moveToCompleted({
      patientId,
      conversationId: selectedConversationId,
    });
    dispatch(
      setSelectedConversationId(nextConversation?.patient.conversation_id),
    );
  };

  const handleMoveToReview = (patientId: number) => {
    if (!patientId) {
      return;
    }

    moveToReview({ conversation: callConversation });
    dispatch(
      setSelectedConversationId(nextConversation?.patient.conversation_id),
    );
  };

  const handleOpenMessageInput = () => {
    dispatch(setIsMessageInputOpen(!isMessageInputOpen));
  };

  if (!callConversation || !patient) {
    return null;
  }

  return (
    <div className={className}>
      <article
        className="w-full h-full rounded-lg border bg-gray-background2 border-gray-foreground
          grid grid-rows-tab-layout relative overflow-hidden"
      >
        <header className="grid grid-cols-2-right bg-white items-center gap-3 overflow-hidden py-3 px-5">
          <button
            className={buttonStyle}
            onClick={onClose}
            type="button"
            aria-label="Close details"
          >
            <CloseIcon width="16" height="16" className="flex-none" />
          </button>

          <div className="space-y-1">
            <div className="grid grid-cols-conv-layout gap-2">
              <p className="font-semibold text-left text-xl lg:text-xl mt-0.5 w-full truncate">
                {selectedItemPatient?.patient_full_name}
              </p>
              <div className="flex space-x-2">
                <CallStatusButtons
                  onComplete={handleCompleteCall}
                  onMoveToReview={handleMoveToReview}
                  patientId={callConversation.patient.patient_id}
                  isCompleted={callConversation.completed === 1}
                  conversation={callConversation}
                />

                <button
                  className={`${buttonStyle}
                    ${isMessageInputOpen ? "bg-primary-blue" : ""}`}
                  onClick={handleOpenMessageInput}
                  disabled={!callConversation}
                  type="button"
                  aria-label="Messages"
                >
                  <MessageIcon
                    width="16"
                    height="16"
                    className="flex-none"
                    stroke={isMessageInputOpen ? "white" : "#2970FF"}
                  />
                </button>
                <button
                  className={`${buttonStyle}
                    ${openCallModal ? "bg-primary-blue" : ""}`}
                  onClick={handleCall}
                  disabled={openCallModal}
                  type="button"
                  aria-label="Call"
                >
                  <CallIcon
                    width="16"
                    height="16"
                    className="flex-none"
                    stroke={openCallModal ? "white" : "#2970FF"}
                    fill={openCallModal ? "white" : "#2970FF"}
                  />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-conv-layout gap-2">
              <PatientShortInfo
                patient={patient}
                className="max-w-full min-w-0 w-fit truncate text-xs text-tertiary bg-gray-110 rounded py-1 px-3
                  font-semibold"
                showBirthdate={false}
              />
              <p className="text-base text-tertiary font-medium">
                {getFormattedPhoneNumber(callConversation.patient.phone_number)}
              </p>
            </div>
          </div>
        </header>

        <main className="overflow-hidden grid grid-rows-note-layout">
          {callConversation &&
          callConversation.conversation_id === selectedConversationId ? (
            <MessageTab
              patientInfo={callConversation.patient}
              messages={messages}
              setMessages={(messages) => dispatch(setMessagesAction(messages))}
              hideInputArea={!isMessageInputOpen}
            />
          ) : (
            <div className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar h-full w-full">
              <ChatSkeleton />
            </div>
          )}

          {openCallModal && (
            <CallControlButtons
              onClose={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              callSeconds={callSeconds}
            />
          )}
        </main>
      </article>
    </div>
  );
};

export default CallDetails;
