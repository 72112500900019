import { useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/teams/calendar.svg";
import { ReactComponent as ClinicIcon } from "../../../../assets/icons/teams/clinic.svg";
import { ReactComponent as ClipboardIcon } from "../../../../assets/icons/teams/clipboard.svg";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/teams/document.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/icons/teams/heart.svg";
import { ReactComponent as IdIcon } from "../../../../assets/icons/teams/id.svg";
import { ReactComponent as InvoiceCheckListIcon } from "../../../../assets/icons/teams/invoice-checklist.svg";
import { ReactComponent as InvoiceIcon } from "../../../../assets/icons/teams/invoice.svg";
import { ReactComponent as NewPatientIcon } from "../../../../assets/icons/teams/new-patient.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/teams/phone.svg";
import { ReactComponent as PillsIcon } from "../../../../assets/icons/teams/pills.svg";
import { ReactComponent as Pills2Icon } from "../../../../assets/icons/teams/pills2.svg";
import { ReactComponent as PushPullIcon } from "../../../../assets/icons/teams/push-pull.svg";
import { ReactComponent as StethoscopeIcon } from "../../../../assets/icons/teams/stethoscope.svg";
import { ReactComponent as TeamDefaultIcon } from "../../../../assets/icons/teams/team-default.svg";
import { ReactComponent as TwoTeamIcon } from "../../../../assets/icons/teams/two-team.svg";
import { ReactComponent as VitalIcon } from "../../../../assets/icons/teams/vital.svg";
import { ReactComponent as WriteIcon } from "../../../../assets/icons/teams/write.svg";
import Button from "../../../Basic/Button";

export const getTeamIcon = (name: string, size: string, color: string) => {
  switch (name) {
    case "team-default":
      return (
        <TeamDefaultIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "calendar":
      return (
        <CalendarIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
          fill={color}
        />
      );
    case "clipboard":
      return (
        <ClipboardIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "clinic":
      return (
        <ClinicIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "document":
      return (
        <DocumentIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "heart":
      return (
        <HeartIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
          fill={color}
        />
      );
    case "id":
      return (
        <IdIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "invoice":
      return (
        <InvoiceIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "invoice-checklist":
      return (
        <InvoiceCheckListIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "new-patient":
      return (
        <NewPatientIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "phone":
      return (
        <PhoneIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "pills":
      return (
        <PillsIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "pills2":
      return (
        <Pills2Icon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "push-pull":
      return (
        <PushPullIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "stethoscope":
      return (
        <StethoscopeIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "two-team":
      return (
        <TwoTeamIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "vital":
      return (
        <VitalIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    case "write":
      return (
        <WriteIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
    default:
      return (
        <TeamDefaultIcon
          width={size}
          height={size}
          className="flex-none"
          stroke={color}
        />
      );
  }
};

const iconSize = "32";
const selectedIconColor = "#2970FF";
const iconColor = "#667085";

interface IconSelectionProps {
  className?: string;
  currentIcon: string;
  onCancel: () => void;
  onConfirm: (icon: string) => void;
}

const IconSelection = ({
  className = "",
  currentIcon,
  onCancel,
  onConfirm,
}: IconSelectionProps) => {
  const [selectedIcon, setSelectedIcon] = useState(
    currentIcon || "team-default",
  );

  const getIconButton = (icon: string) => {
    return (
      <button
        type="button"
        className={`mx-auto w-16 h-16 rounded-full flex items-center justify-center
          ${selectedIcon === icon ? "bg-primary-blue-light" : "bg-gray-110"}`}
        onClick={() => setSelectedIcon(icon)}
      >
        {getTeamIcon(
          icon,
          iconSize,
          selectedIcon === icon ? selectedIconColor : iconColor,
        )}
      </button>
    );
  };

  return (
    <div
      className={`w-full min-h-0 grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div
        className="px-5 items-center grid grid-rows-auto grid-cols-4 md:grid-cols-6 gap-5 md:gap-8
          overflow-y-auto scrollbar min-h-0 pb-5"
      >
        {getIconButton("team-default")}
        {getIconButton("calendar")}
        {getIconButton("stethoscope")}
        {getIconButton("pills")}
        {getIconButton("pills2")}
        {getIconButton("clinic")}
        {getIconButton("new-patient")}
        {getIconButton("two-team")}
        {getIconButton("id")}
        {getIconButton("invoice")}
        {getIconButton("invoice-checklist")}
        {getIconButton("push-pull")}
        {getIconButton("phone")}
        {getIconButton("heart")}
        {getIconButton("vital")}
        {getIconButton("clipboard")}
        {getIconButton("document")}
        {getIconButton("write")}
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          onClick={() => onConfirm(selectedIcon)}
        >
          Select
        </Button>
      </div>
    </div>
  );
};

export default IconSelection;
