import Bugsnag from "@bugsnag/js";
import type { AxiosError } from "axios";
import axios from "axios";
import { convert } from "html-to-text";
import type { AppDispatch } from "../store";
import { setNotificationAction } from "../store/user/actions";

export const isSameDay = (date1: string, date2: string) => {
  const firstDate = new Date(`${date1.replace(/-/g, "/")} GMT+0`);
  const secondDate = new Date(`${date2.replace(/-/g, "/")} GMT+0`);

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return firstDate.getTime() === secondDate.getTime();
};

export const isToday = (date) => {
  const currDate = new Date();
  const checkDate = new Date(date);

  currDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return currDate.getTime() === checkDate.getTime();
};

export const isYesterday = (date) => {
  const yesterdayDate = new Date();
  const checkDate = new Date(date);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  yesterdayDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return yesterdayDate.getTime() === checkDate.getTime();
};

export const isWeek = (date) => {
  const weekAgoDate = new Date();
  const checkDate = new Date(date);
  weekAgoDate.setDate(weekAgoDate.getDate() - 6);
  weekAgoDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return checkDate.getTime() > weekAgoDate.getTime();
};

export const isThisYear = (date) => {
  const currDate = new Date();
  const firstDayOfYear = new Date(currDate.getFullYear(), 0, 1);
  const checkDate = new Date(date);
  firstDayOfYear.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return checkDate.getTime() > firstDayOfYear.getTime();
};

export const getAge = (birthdate) => {
  if (!birthdate) {
    return "-";
  }

  const dob = new Date(`${birthdate.replace(/-/g, "/")} GMT+0`);
  const currDate = new Date();
  const oneOrZero =
    currDate.getMonth() < dob.getMonth() ||
    (currDate.getMonth() === dob.getMonth() &&
      currDate.getDate() < dob.getDate());
  const yearDifference = currDate.getFullYear() - dob.getFullYear();
  return yearDifference - +oneOrZero;
};

export const getFormattedTime = (date) => {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
};

export const getFormattedTimeOrDate = (date) => {
  if (isToday(date)) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  }
  if (isThisYear(date)) {
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
  }
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

export const getFormattedTimeWithSeconds = (duration) => {
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  let time = "";

  if (hrs > 0) {
    time += `${hrs < 10 ? `0${hrs}` : hrs}:`;
  }

  time += `${mins < 10 ? `0${mins}` : mins}:`;
  time += `${secs < 10 ? `0${secs}` : secs}`;

  return time;
};

export const getFormattedTimeColon = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const formattedMinutes = String(minutes);
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const getFormattedTimeHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours > 0 ? `${hours}h ` : ""}${minutes > 0 || hours > 0 ? `${minutes}min ` : ""}${remainingSeconds}s`;
};

export const timeStringToSeconds = (timeString) => {
  const parts = timeString.split(/\s+|:/);

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  for (const part of parts) {
    if (part.endsWith("h")) {
      hours = Number.parseInt(part, 10);
    } else if (part.endsWith("m")) {
      minutes = Number.parseInt(part, 10);
    } else if (part.endsWith("s")) {
      seconds = Number.parseInt(part, 10);
    }
  }
  return hours * 3600 + minutes * 60 + seconds;
};

export const getTimeAndUnit = (minutes) => {
  const mins = Math.abs(minutes);
  let hours = 0;
  let days = 0;
  if (mins >= 60) {
    hours = mins / 60;
  }
  if (hours >= 24) {
    days = hours / 24;
  }
  if (days >= 1) {
    return { value: Math.round(days), unit: "days" };
  }
  if (hours >= 1) {
    return { value: Math.round(hours), unit: "hours" };
  }
  return { value: mins, unit: "minutes" };
};

export const getDateDivider = (date) => {
  if (isToday(date)) {
    return "Today";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }
  if (isWeek(date)) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const addHours = (date, hours) => {
  const dateCopy = new Date(date);
  dateCopy.setTime(dateCopy.getTime() + hours * 60 * 60 * 1000);
  return dateCopy;
};

export const onDragEnd = (result, values, setValues) => {
  const { destination, source } = result;

  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const newOrder = [...values];
  const [removed] = newOrder.splice(result.source.index, 1);
  newOrder.splice(result.destination.index, 0, removed);
  setValues(newOrder);
};

export const getFormattedPhoneNumber = (number) => {
  if (!number) {
    return "";
  }

  const cleanNumber = number.trim().replaceAll(/[^\d]/g, "");

  // US number
  if (cleanNumber.startsWith("1") && cleanNumber.length === 11) {
    const usNumber = cleanNumber.slice(1);
    return usNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
  }

  return cleanNumber.length > 11
    ? cleanNumber.replace(/(\d{2})(\d{3})(\d{3})(\d)/, "+$1 $2-$3-$4")
    : cleanNumber.replace(/(\d)(\d{3})(\d{3})(\d)/, "+$1 $2-$3-$4");
};

export const getTagVariant = (tagName) => {
  switch (tagName) {
    // case "HPI":
    //   return "slate";
    // case "CCM":
    //   return "violet";
    // case "PCM":
    //   return "red";
    // case "Registration":
    //   return "red";
    // case "BHI":
    //   return "amber";
    // case "CC":
    //   return "emerald";
    // case "Patient Initiated":
    //   return "sky";
    default:
      return "slate";
  }
};

export const getEventDotBgColor = (tagName) => {
  switch (tagName) {
    case "HPI":
      return "bg-slate-700";
    case "CCM":
      return "bg-violet-700";
    case "PCM":
      return "bg-red-700";
    case "Registration":
      return "bg-red-700";
    case "BHI":
      return "bg-amber-700";
    case "CC":
      return "bg-emerald-700";
    case "Patient Initiated":
      return "bg-sky-700";
    default:
      return "bg-slate-700";
  }
};

export const selectInputText = (e) => {
  e.target.select();
};

export const removeTags = (textWithHtmlTags) => {
  const hasBR = /<br\s*\/?>/i.test(textWithHtmlTags);
  return !hasBR
    ? textWithHtmlTags
    : convert(
        textWithHtmlTags
          .replace(
            /<\/[a-zA-Z]+>\s*<br\/>/g,
            (match) => `</${match.match(/[a-zA-Z]+/)[0]}>`,
          )
          .replace(/<br><\/[a-zA-Z]+>/g, "</$1>"),
        {
          wordwrap: false,
        },
      );
};

export const copyText = (textWithHtmlTags, onSuccess) => {
  const plainText = removeTags(textWithHtmlTags);
  const htmlText = `<div style="font-family: inherit, sans-serif;">${textWithHtmlTags.replace(/\n/g, "<br>")}</div>`;

  const clipboardItem = new ClipboardItem({
    "text/plain": new Blob([plainText], { type: "text/plain" }),
    "text/html": new Blob([htmlText], { type: "text/html" }),
  });

  navigator.clipboard.write([clipboardItem]).then(onSuccess);
};

export const convertToHtmlList = (text) => {
  const lines = text.split("\n");

  let html = "";
  let inList = false;

  for (const line of lines) {
    if (line.trim().startsWith("- ")) {
      if (!inList) {
        html += "<ul style='list-style-type: disc; padding-left: 18px'>";
        inList = true;
      }
      html += `<li>${line.trim().substring(1).trim()}</li>`;
    } else {
      if (inList) {
        html += "</ul>";
        inList = false;
      }
      html += `<p>${line}</p>`;
    }
  }
  if (inList) {
    html += "</ul>";
  }
  return html;
};

export const deepEqual = (obj1, obj2) => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (Array.isArray(obj1)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    const sortedObj1 = obj1.slice().sort();
    const sortedObj2 = obj2.slice().sort();
    return sortedObj1.every((value, index) =>
      deepEqual(value, sortedObj2[index]),
    );
  }
  if (typeof obj1 === "object" && obj1 !== null) {
    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();
    if (!deepEqual(keys1, keys2)) {
      return false;
    }
    return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
  }
  return obj1 === obj2;
};

export const getFrequencyByInterval = (interval) => {
  switch (interval) {
    case 0:
      return "once";
    case 1440:
      return "daily";
    case 4320:
      return "every_3_days";
    case 10080:
      return "weekly";
    case 21600:
      return "biweekly";
    case 43200:
      return "monthly";
    case 86400:
      return "every_2_months";
    case 129600:
      return "every_3_months";
    case 259200:
      return "every_6_months";
    case 525600:
      return "yearly";
    default:
      return "unknown";
  }
};

export const isInsideNonEditable = (node) => {
  if (node.nodeType === Node.ELEMENT_NODE) {
    return node.closest('[contenteditable="false"]');
  }
  if (node.nodeType === Node.TEXT_NODE) {
    return node.parentElement?.closest('[contenteditable="false"]');
  }
  return null;
};

export const insertTag = (selection, tag) => {
  if (selection?.focusNode?.parentNode.closest('[contenteditable="true"]')) {
    const range = selection.getRangeAt(0);

    // Prevent insertion if inside a non-editable tag
    if (
      isInsideNonEditable(selection.focusNode) ||
      isInsideNonEditable(selection.anchorNode)
    ) {
      return;
    }

    // Create the span element for the tag
    const span = document.createElement("span");
    span.className = "py-0.5 px-1.5 border border-gray-300 rounded-md text-xs";
    span.textContent = tag.name;
    span.contentEditable = "false";

    // Insert the span at the caret position
    range.deleteContents();
    range.insertNode(span);

    // Correctly place the caret after the span
    range.setStartAfter(span);
    range.setEndAfter(span);

    // Update the selection with the new range
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const getNextUpdateInterval = (durationInSeconds: number) => {
  // Calculate when the displayed duration will change next
  let nextUpdateInSeconds: number;

  if (durationInSeconds < 30) {
    // Will change at 30 seconds
    nextUpdateInSeconds = 30 - durationInSeconds;
  } else if (durationInSeconds < 60) {
    // Will change at 60 seconds (1 minute)
    nextUpdateInSeconds = 60 - durationInSeconds;
  } else if (durationInSeconds < 1800) {
    // Will change every minute
    const secondsIntoMinute = durationInSeconds % 60;
    nextUpdateInSeconds = 60 - secondsIntoMinute;
  } else if (durationInSeconds < 3600) {
    // Will change at 30 minutes
    const secondsIntoHalfHour = durationInSeconds % 1800;
    nextUpdateInSeconds = 1800 - secondsIntoHalfHour;
  } else {
    // Will change at the next hour
    const secondsIntoHour = durationInSeconds % 3600;
    nextUpdateInSeconds = 3600 - secondsIntoHour;
  }

  return nextUpdateInSeconds * 1000;
};

export const getDurationString = (durationInSeconds: number) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  if (hours > 0) {
    if (minutes > 30) {
      return `${hours + 1} hr`;
    }

    return `${hours} hr`;
  }

  if (seconds > 30) {
    return `${minutes + 1} min`;
  }

  return `${minutes} min`;
};

function isValidDateFormat(dateString) {
  const regex1 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;
  const regex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
  return regex1.test(dateString) || regex2.test(dateString);
}

export const parseDateString = (dateString: string | null) => {
  if (!dateString) {
    return null;
  }

  if (!isValidDateFormat(dateString)) {
    throw new Error(
      `Invalid date format. Expected formats: "YYYY-MM-DD HH:mm:ss" or "YYYY-MM-DDTHH:mm:ssZ" but received: ${dateString}`,
    );
  }

  if (dateString.includes(" ")) {
    return new Date(`${dateString.replace(" ", "T")}Z`);
  }

  return new Date(dateString);
};

export const handleRequestError = (
  error: Error | AxiosError,
  dispatch?: AppDispatch,
  message?: string,
) => {
  if (axios.isAxiosError(error) && axios.isCancel(error)) {
    return;
  }

  if (axios.isAxiosError(error) && error?.response?.status === 404) {
    console.error("Resource not found", error);
    return;
  }

  dispatch &&
    message &&
    dispatch(
      setNotificationAction({
        status: "error",
        title: "Something went wrong",
        desc: message,
      }),
    );

  console.error(error);
  Bugsnag.notify(error);
};
