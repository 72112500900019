import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Languages, SpeakingLanguages } from "../../../helpers/languages";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setSettings } from "../../../store/user/thunks";
import { selectUser } from "../../../store/user/userReducer";
import Select from "../../Basic/Select";
import Switch from "../../Basic/Switch";
import {
  selectDefaultNoteTemplate,
  selectNoteTemplates,
  setDefaultNoteTemplate,
} from "../../IndependentScribe/store/noteTemplateSlice";
import {
  describeNoteTemplate,
  getNoteTemplates,
} from "../../IndependentScribe/store/thunks";
import Dropdown from "./Dropdown/Dropdown";

const fieldContainerClasses = "space-y-3";
const fieldLabelClasses = "font-semibold";
const dividerClasses = "border-gray-foreground";

interface SwitchFieldProps {
  label: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const SwitchField = ({ label, checked, setChecked }: SwitchFieldProps) => {
  return (
    <div className="w-full flex flex-wrap items-center justify-between">
      <p className={`mr-2 ${fieldLabelClasses}`}>{label}</p>
      <div className="flex items-center space-x-2">
        <p
          className={`w-6 text-center font-medium text-sm
                    ${checked ? "text-primary-blue" : "text-tertiary"}`}
        >
          {checked ? "Yes" : "No"}
        </p>
        <Switch
          size="medium-large"
          enabled={checked}
          onChange={() => setChecked(!checked)}
        />
      </div>
    </div>
  );
};

const NotesGeneral = () => {
  const user = useAppSelector(selectUser);
  const defaultNoteTemplate = useAppSelector(selectDefaultNoteTemplate);
  const noteTemplates = useAppSelector(selectNoteTemplates);
  const dispatch = useAppDispatch();
  const { register, watch } = useForm({
    mode: "onBlur",
    defaultValues: {
      inputLanguage: user.settings.scribe_input_language || Languages[0].value,
      scribeNoteLanguage:
        user.settings.scribe_note_language || Languages[0].value,
    },
  });
  const [showLanguageSelector, setShowLanguageSelector] = useState(
    !!user.settings.show_language_selector,
  );
  // todo: implement all switches
  // const [showNoteTemplateSelector, setShowNoteTemplateSelector] =
  //   useState(false);
  // const [showFormSelector, setShowFormSelector] = useState(false);
  // const [showTemplateDuringEncounter, setShowTemplateDuringEncounter] =
  //   useState(false);

  useEffect(() => {
    dispatch(getNoteTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (user?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.note_template_id, (data) =>
          dispatch(setDefaultNoteTemplate(data)),
        ),
      );
    }
  }, [noteTemplates, user, dispatch]);

  useEffect(() => {
    const subscription = watch((values) =>
      dispatch(
        setSettings(
          {
            scribe_note_language: values.scribeNoteLanguage,
            scribe_input_language: values.inputLanguage,
          },
          false,
        ),
      ),
    );

    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  useEffect(() => {
    // todo: add all switch settings
    dispatch(
      setSettings(
        {
          show_language_selector: showLanguageSelector,
        },
        false,
      ),
    );
    // todo: add all switch states
  }, [dispatch, showLanguageSelector]);

  const handleSelectTemplate = (template) => {
    dispatch(setDefaultNoteTemplate(template));
  };

  return (
    <form className="space-y-4">
      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Default Note Template</p>
        <Dropdown
          customItems={noteTemplates.filter((template) => template.is_custom)}
          preMadeItems={noteTemplates.filter(
            (template) => !template.is_custom && !template.is_hidden,
          )}
          selectedItem={defaultNoteTemplate}
          onSelectItem={handleSelectTemplate}
          itemName="template"
          defaultSelect
          closeDropdownOnSelect
          withoutSearch
        />
      </div>

      <hr className={dividerClasses} />

      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Scribe Encounter Language</p>
        <Select
          placeholder="Select the speaking language"
          options={SpeakingLanguages}
          register={register}
          required={true}
          name="inputLanguage"
        />
      </div>

      <hr className={dividerClasses} />

      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Note Language</p>
        <Select
          placeholder="Select the note language"
          options={Languages}
          register={register}
          required={true}
          name="scribeNoteLanguage"
        />
      </div>

      <hr className={dividerClasses} />

      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Show before each encounter</p>
        <div className="bg-white rounded-lg p-3 space-y-5 border border-gray-300">
          <SwitchField
            label="Language Selector"
            checked={showLanguageSelector}
            setChecked={setShowLanguageSelector}
          />
          {/*<SwitchField*/}
          {/*  label="Note Template Selector"*/}
          {/*  checked={showNoteTemplateSelector}*/}
          {/*  setChecked={setShowNoteTemplateSelector}*/}
          {/*/>*/}
          {/*<SwitchField*/}
          {/*  label="Form Selector"*/}
          {/*  checked={showFormSelector}*/}
          {/*  setChecked={setShowFormSelector}*/}
          {/*/>*/}
          {/*<SwitchField*/}
          {/*  label="Display Note Template During Encounter"*/}
          {/*  checked={showTemplateDuringEncounter}*/}
          {/*  setChecked={setShowTemplateDuringEncounter}*/}
          {/*/>*/}
        </div>
      </div>
    </form>
  );
};

export default NotesGeneral;
