import { useCallback, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setPatientRecordingScribeTitleAction } from "../../../../store/patient/actions";
import GenerationDelayed from "../../../Basic/Warning/GenerationDelayed";
import NotEnoughTranscript from "../../../Basic/Warning/NotEnoughTranscript";
import { setScribeTogglePause } from "../../../IndependentScribe/store/scribeSlice";
import { getSelectedScribe } from "../../../IndependentScribe/store/selectors";
import NewScribe from "../../NewScribe";
import Recording from "./Recording";

const PatientScribe = ({
  onCancel,
  audioContext,
  mediaRecorder,
  togglePause,
  cancelRecording,
  saveRecording,
  startRecording,
  microphoneId,
  setMicrophoneId,
  microphones,
  notEnoughTranscript,
  setNotEnoughTranscript,
  isReadyToRecord,
  readyToRecordErrors,
  requestMicrophonePermissions,
  isStalled,
  showNoteGenerationDelayedWarning,
  hideNoteGenerationDelayedWarning,
}: {
  onCancel: () => void;
  audioContext: AudioContext;
  mediaRecorder: MediaRecorder | null;
  togglePause: () => void;
  cancelRecording: () => void;
  saveRecording: () => void;
  startRecording: () => void;
  microphoneId: string;
  setMicrophoneId: (id: string) => void;
  microphones: { text: string; value: string }[];
  notEnoughTranscript: boolean;
  setNotEnoughTranscript: (status: boolean) => void;
  isReadyToRecord: boolean;
  readyToRecordErrors: string[];
  requestMicrophonePermissions: () => void;
  isStalled: boolean;
  showNoteGenerationDelayedWarning: boolean;
  hideNoteGenerationDelayedWarning: () => void;
}) => {
  const lastTranscriptAudioId = useRef(null);
  const prevSelectedScribe = useRef(null);
  const selectedScribe = useAppSelector(getSelectedScribe);

  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const onNotEnoughTranscriptSubmit = useCallback(() => {
    setNotEnoughTranscript(false);
    togglePause();
    dispatch(setScribeTogglePause());
  }, [togglePause, setNotEnoughTranscript, dispatch]);

  useEffect(() => {
    if (!notEnoughTranscript) {
      lastTranscriptAudioId.current = null;
    }
  }, [notEnoughTranscript]);

  useEffect(() => {
    if (
      prevSelectedScribe.current &&
      prevSelectedScribe.current.audioId !== selectedScribe?.audioId &&
      notEnoughTranscript
    ) {
      setNotEnoughTranscript(false);
    }

    prevSelectedScribe.current = selectedScribe;
  }, [selectedScribe, setNotEnoughTranscript, notEnoughTranscript]);

  return (
    <>
      <div className="h-full w-full overflow-hidden">
        {selectedScribe?.isRecording ||
        selectedScribe?.isGenerating ||
        selectedScribe?.isPaused ? (
          <Recording
            onCancel={onCancel}
            mediaRecorder={mediaRecorder}
            togglePause={togglePause}
            cancelRecording={cancelRecording}
            saveRecording={() => {
              lastTranscriptAudioId.current = selectedScribe?.audioId;
              setNotEnoughTranscript(false);
              saveRecording();
            }}
            isStalled={isStalled}
            notEnoughTranscript={notEnoughTranscript}
            onNotEnoughTranscriptSubmit={onNotEnoughTranscriptSubmit}
            showNoteGenerationDelayedWarning={showNoteGenerationDelayedWarning}
            hideNoteGenerationDelayedWarning={hideNoteGenerationDelayedWarning}
          />
        ) : (
          <div className="px-5 py-3 md:px-8 height-sm:py-8 h-full min-h-fit grid overflow-y-auto scrollbar">
            <NewScribe
              startRecording={() => {
                startRecording();
                dispatch(setPatientRecordingScribeTitleAction("Scribe"));
                dispatch(
                  setPatientRecordingScribeTitleAction(selectedScribe?.title),
                );
              }}
              onBack={onCancel}
              microphoneId={microphoneId}
              microphones={microphones}
              audioContext={audioContext}
              mediaRecorder={mediaRecorder}
              setMicrophoneId={setMicrophoneId}
              hideHeader={true}
              isReadyToRecord={isReadyToRecord}
              readyToRecordErrors={readyToRecordErrors}
              requestMicrophonePermissions={requestMicrophonePermissions}
            />
          </div>
        )}
      </div>

      {notEnoughTranscript &&
        lastTranscriptAudioId.current === selectedScribe?.audioId &&
        !isDesktop && (
          <NotEnoughTranscript
            onSubmit={onNotEnoughTranscriptSubmit}
            onCancel={onCancel}
          />
        )}
      {showNoteGenerationDelayedWarning && !isDesktop && (
        <GenerationDelayed
          onCancel={hideNoteGenerationDelayedWarning}
          onSubmit={hideNoteGenerationDelayedWarning}
        />
      )}
    </>
  );
};

export default PatientScribe;
