import type { ComponentType } from "react";
import type { FixLater } from "../components/IndependentScribe/store/scribeSlice";
import LocalStorageService from "../services/LocalStorageService";

export const lazyRetry = (
  componentImport: () => Promise<FixLater>,
): Promise<{ default: ComponentType<FixLater> }> => {
  const storageService = new LocalStorageService();

  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      storageService.getItem("retry-lazy-refreshed") || "false",
    );
    // try to import the component
    componentImport()
      .then((component) => {
        storageService.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          storageService.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
