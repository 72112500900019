import { ReactComponent as InboundIcon } from "../../../assets/icons/call-incoming.svg";
import { ReactComponent as OutboundIcon } from "../../../assets/icons/call-outcoming.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle-completed.svg";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import { useAppSelector } from "../../../store";
import { getCallsCompletedTmp } from "../../../store/call/callSlice";
import {
  CallDirection,
  CallStatusTab,
  type InboxItem,
  type InboxPatient,
} from "../../../store/call/interfaces";
import { useFilterParams } from "../hooks/useFilterParams";

interface CallListItemProps {
  call: InboxItem;
  patient: InboxPatient;
  numOfSubItems: number;
  handleClickCall: () => void;
  handleCompleteCall?: () => void;
}

const CallListItem = ({
  call,
  patient,
  numOfSubItems,
  handleClickCall,
  handleCompleteCall,
}: CallListItemProps) => {
  const { filters } = useFilterParams();
  const callsCompletedTmp = useAppSelector(getCallsCompletedTmp);

  if (!call) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`bg-white p-4 rounded-xl border-b border-gray-foreground space-y-2 mt-3 overflow-hidden
        ${
          callsCompletedTmp.pending.includes(patient.patient_id) ||
          callsCompletedTmp.completed.includes(patient.patient_id)
            ? "animate-fade-out"
            : ""
        }`}
      onClick={handleClickCall}
    >
      {call.data.reasons?.length > 0 && (
        <div className="grid grid-cols-conv-layout items-center gap-2">
          <p className="truncate text-sm font-medium max-w-full w-fit min-w-0">
            {call.data.reasons.join(", ")}
          </p>
        </div>
      )}

      <div className="grid grid-cols-conv-layout items-center gap-2">
        <p className="text-xs text-tertiary">
          {getFormattedPhoneNumber(patient.patient_phone_number)}
        </p>
        {(call.data.is_urgent || call.data.is_new_patient) && (
          <div
            className={`text-xs text-white px-2 py-0.5 rounded-full flex-none
              ${
                call.data.is_urgent
                  ? "bg-urgent border-2 border-white border-opacity-90"
                  : "bg-system-green-dark"
              }`}
          >
            {call.data.is_urgent ? "Urgent" : "New"}
          </div>
        )}
      </div>

      <div className="grid grid-cols-middle items-center gap-2">
        <div className="h-8 w-8 rounded-full bg-white border border-gray-foreground flex items-center justify-center">
          {call.data.direction === CallDirection.Inbound ? (
            <InboundIcon width="14" height="14" />
          ) : (
            <OutboundIcon width="14" height="14" />
          )}
        </div>
        <div className="truncate">
          <p className="text-sm font-semibold w-full truncate">
            {patient.patient_full_name}
          </p>
          <p className="truncate text-xs text-tertiary font-medium">
            {new Date(
              `${call.data.date.replace(/-/g, "/")} GMT+0`,
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>
        </div>

        {handleCompleteCall && (
          <button
            type="button"
            onClick={(e) => {
              if (filters.status === CallStatusTab.Pending) {
                e.preventDefault();
                e.stopPropagation();
                handleCompleteCall();
              }
            }}
            className="relative"
          >
            <CheckIcon
              width="30"
              height="30"
              fill={
                filters.status === CallStatusTab.Completed
                  ? "#2970FF"
                  : "#D0D5DD"
              }
              className="flex-none"
            />

            {numOfSubItems > 1 && (
              <div
                className="absolute -bottom-1 -right-1.5 rounded-full w-5 h-5 bg-white text-xs font-semibold
                text-primary-blue flex items-center justify-center border border-gray-300"
              >
                {numOfSubItems}
              </div>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default CallListItem;
