import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";
import { setTemplatesAction, setTemplatesLoaderAction } from "./actions";
import { templatesSchema } from "./validationSchemas";

export const getTemplates = () => (dispatch: AppDispatch) => {
  dispatch(setTemplatesLoaderAction(true));
  axios
    .get(`${BASE_URL}/campaign-template/list`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load templates",
        );
      } else {
        const templates = response.data.data.templates;

        handleValidation(templatesSchema, templates, "getTemplates");

        dispatch(setTemplatesAction(templates));
      }
      dispatch(setTemplatesLoaderAction(false));
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load templates");
    });
};

export const createTemplate =
  (name, conditions, goals, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/campaign-template/create`, {
        template: {
          name: name,
          conditions: conditions,
          goals: goals,
        },
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to create template",
          );
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template created successfully",
              desc: "New template has been successfully created",
            }),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to create template");
      });
  };

export const updateTemplate =
  (id, conditions, goals, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/campaign-template/update/${id}`, {
        template: {
          conditions: conditions,
          goals: goals,
        },
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update template",
          );
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template updated successfully",
              desc: "The template has been successfully updated",
            }),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update template");
      });
  };

export const renameTemplate =
  (id, name, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    dispatch(setTemplatesLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaign-template/update/${id}`, {
        template: {
          name: name,
        },
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to rename template",
          );
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template renamed successfully",
              desc: "The template has been successfully renamed",
            }),
          );
          onSuccess();
        }
        dispatch(setTemplatesLoaderAction(false));
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to rename template");
      });
  };

export const deleteTemplate =
  (id, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/campaign-template/delete/${id}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete template",
          );
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template deleted successfully",
              desc: "The template has been successfully deleted",
            }),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete template");
      });
  };
