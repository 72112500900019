import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CallGrayIcon } from "../../assets/icons/calling-gray.svg";
import { ReactComponent as CallIcon } from "../../assets/icons/calling.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/chat-round.svg";
import { ReactComponent as ScribeIcon } from "../../assets/icons/microphone-scribe.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/icons/minimize-square.svg";
import {
  patientPanelTabs,
  twilioDeviceStatuses,
} from "../../helpers/constants";
import { getFormattedTimeWithSeconds } from "../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../store";
import type { Patient } from "../../store/patient/interfaces";
import {
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../../store/voiceRecorder/actions";
import AddPhoneNumber from "../Basic/AddPhoneNumber";
import TabButton from "../Basic/TabButton";
import RecordingContextStalled from "../Basic/Warning/RecordingContextStalled";
import RecordingIndicator from "../IndependentScribe/RecordingIndicator";
import TimerProvider from "../IndependentScribe/TimerProvider";
import { useTimerDisplay } from "../IndependentScribe/hooks/useTimerDisplay";
import {
  type FixLater,
  setInterruptedRecordingAction,
  setScribeSelectedAudioId,
} from "../IndependentScribe/store/scribeSlice";
import {
  getRecordingScribe,
  getSelectedScribe,
} from "../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../IndependentScribe/store/types";
import PatientShortInfo from "../PatientProfile/PatientShortInfo";
import Call from "../PatientProfile/RightPanel/Call/Call";
import CallOptions from "../PatientProfile/RightPanel/Call/CallOptions";
import MessageTab from "../PatientProfile/RightPanel/Message/MessageTab";
import PatientScribe from "../PatientProfile/RightPanel/Scribe/PatientScribe";

const ChatCallScribePanel = ({
  handleCall,
  handleDisconnect,
  handleMute,
  isMuted,
  patient,
  selectedTab,
  setSelectedTab,
  messages,
  setMessages,
  scribeTab = false,
  notEnoughTranscript,
  setNotEnoughTranscript,
  cancelRecording,
  togglePause,
  isStalled,
  audioContext,
  mediaRecorder,
  microphones = [],
  microphoneId,
  setMicrophoneId,
  startRecording,
  saveRecording,
  onCancelScribe,
  isReadyToRecord,
  readyToRecordErrors,
  requestMicrophonePermissions,
  showNoteGenerationDelayedWarning,
  hideNoteGenerationDelayedWarning,
  className = "md:rounded-xl",
  showBottomCompleteButton,
}: {
  handleCall: (phoneNumber: string) => void;
  handleDisconnect: () => void;
  handleMute: () => void;
  isMuted: boolean;
  patient: Patient;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  messages: FixLater;
  setMessages: (messages: any) => void;
  scribeTab?: boolean;
  notEnoughTranscript?: boolean;
  setNotEnoughTranscript?: (value: boolean) => void;
  cancelRecording?: () => void;
  togglePause?: () => void;
  isStalled?: boolean;
  audioContext?: AudioContext;
  mediaRecorder?: MediaRecorder | null;
  microphones?: { text: string; value: string }[];
  microphoneId?: string;
  setMicrophoneId?: (value: string) => void;
  startRecording?: () => void;
  saveRecording?: () => void;
  onCancelScribe?: () => void;
  isReadyToRecord?: boolean;
  readyToRecordErrors?: string[];
  requestMicrophonePermissions?: () => void;
  showNoteGenerationDelayedWarning?: boolean;
  hideNoteGenerationDelayedWarning?: () => void;
  className?: string;
  showBottomCompleteButton?: boolean;
}) => {
  const { openCallModal, deviceStatus } = useAppSelector(
    (state) => state.voiceRecorder,
  );
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const selectedScribe = useAppSelector(getSelectedScribe);
  const recordingScribe = useAppSelector(getRecordingScribe);

  const recordingAndNotOnScribeTab =
    selectedScribe?.isRecording && selectedTab !== patientPanelTabs.SCRIBE;

  const callingAndNotOnCallTab =
    deviceStatus === twilioDeviceStatuses.IN_PROGRESS &&
    selectedTab !== patientPanelTabs.CALL;
  const callInterval = useRef(null);

  const { formattedTime } = useTimerDisplay(
    selectedScribe?.isRecording,
    selectedScribe?.startedAt,
    selectedScribe?.accumulatedDuration,
  );

  const [callSeconds, setCallSeconds] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (recordingScribe) {
      setNotEnoughTranscript(false);
    }
  }, [recordingScribe, setNotEnoughTranscript]);

  useEffect(() => {
    if (callInterval.current) {
      clearInterval(callInterval.current);
      callInterval.current = null;
    }

    if (deviceStatus !== twilioDeviceStatuses.IN_PROGRESS) {
      setCallSeconds(0);

      return;
    }

    callInterval.current = setInterval(() => {
      setCallSeconds((callSeconds) => callSeconds + 1);
    }, 1000);

    return () => {
      if (callInterval.current) {
        clearInterval(callInterval.current);
        callInterval.current = null;
      }
    };
  }, [deviceStatus]);

  const onCall = () => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_CALL,
        }),
      );
      return;
    }

    dispatch(setOpenCallModalAction(true));
    dispatch(setScribeSelectedAudioId({ audioId: null }));
    handleCall(patient?.phone_number);
  };

  return (
    <>
      <div
        className={`bg-white md:border border-gray-200 w-full h-full grid grid-rows-tab-layout overflow-hidden
          ${className}`}
      >
        <div>
          <div className="md:hidden grid items-start grid-cols-conv-layout w-full min-w-0 px-4 py-3 border-b truncate">
            <div className="truncate">
              {patient && (
                <>
                  <p className="font-semibold text-base mt-1">
                    {patient.preferred_name
                      ? patient.preferred_name
                      : patient.first_name}{" "}
                    {patient.last_name}
                  </p>
                  <PatientShortInfo
                    patient={patient}
                    className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                  />
                </>
              )}
            </div>
            <button
              type="button"
              className="w-6 h-8 md:w-8 flex items-center justify-center flex-none"
              onClick={() => {
                if (!isDesktop && recordingScribe) {
                  dispatch(
                    setInterruptedRecordingAction({
                      type: SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING,
                    }),
                  );

                  return;
                }

                dispatch(setOpenCallOptionsModalAction(false));
              }}
            >
              <MinimizeIcon width="24" height="24" stroke="#121212" />
            </button>
          </div>

          <div
            className={`grid h-[52px] lg:h-16 p-2 border-b
            ${scribeTab ? "grid-cols-3" : "grid-cols-2"}`}
          >
            <TabButton
              icon={
                <MessageIcon
                  width="20"
                  height="20"
                  stroke={
                    selectedTab === patientPanelTabs.MESSAGE
                      ? "#2970FF"
                      : "#667085"
                  }
                />
              }
              label={patientPanelTabs.MESSAGE}
              active={selectedTab}
              onClick={() => setSelectedTab(patientPanelTabs.MESSAGE)}
            />
            <TabButton
              rightIcon={
                callingAndNotOnCallTab ? (
                  <RecordingIndicator
                    size={20}
                    isRecording={callingAndNotOnCallTab}
                  />
                ) : null
              }
              icon={
                selectedTab === patientPanelTabs.CALL ? (
                  <CallIcon width="20" height="20" />
                ) : (
                  <CallGrayIcon width="20" height="20" />
                )
              }
              label={
                callingAndNotOnCallTab
                  ? getFormattedTimeWithSeconds(callSeconds)
                  : patientPanelTabs.CALL
              }
              active={selectedTab}
              onClick={() => setSelectedTab(patientPanelTabs.CALL)}
            />
            {scribeTab && (
              <TimerProvider>
                <TabButton
                  rightIcon={
                    recordingAndNotOnScribeTab ? (
                      <RecordingIndicator
                        size={20}
                        isRecording={selectedScribe.isRecording}
                      />
                    ) : null
                  }
                  icon={
                    <ScribeIcon
                      width="20"
                      height="20"
                      stroke={
                        selectedTab === patientPanelTabs.SCRIBE
                          ? "#2970FF"
                          : "#667085"
                      }
                    />
                  }
                  label={
                    recordingAndNotOnScribeTab
                      ? formattedTime
                      : patientPanelTabs.SCRIBE
                  }
                  active={selectedTab}
                  onClick={() => setSelectedTab(patientPanelTabs.SCRIBE)}
                />
              </TimerProvider>
            )}
          </div>
        </div>
        <div className="flex overflow-hidden">
          {selectedTab === patientPanelTabs.CALL &&
            (openCallModal ? (
              <div className="overflow-y-auto scrollbar h-full w-full">
                <Call
                  onClose={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  patient={patient}
                  callSeconds={callSeconds}
                />
              </div>
            ) : patient?.phone_number ? (
              <div className="pl-5 py-3 height-sm:py-5 pr-2 bg-gray-background2 h-full w-full">
                <CallOptions
                  className="h-full min-h-0 overflow-y-auto scrollbar pr-3"
                  onCall={onCall}
                  patient={patient}
                />
              </div>
            ) : (
              <AddPhoneNumber patientId={patient?.patient_id} call={true} />
            ))}

          {selectedTab === patientPanelTabs.MESSAGE && (
            <MessageTab
              patientInfo={patient}
              messages={messages}
              setMessages={setMessages}
              showBottomCompleteButton={showBottomCompleteButton}
            />
          )}

          {selectedTab === patientPanelTabs.SCRIBE && (
            <PatientScribe
              onCancel={onCancelScribe}
              audioContext={audioContext}
              mediaRecorder={mediaRecorder}
              togglePause={togglePause}
              cancelRecording={cancelRecording}
              saveRecording={saveRecording}
              startRecording={startRecording}
              microphoneId={microphoneId}
              setMicrophoneId={setMicrophoneId}
              microphones={microphones}
              notEnoughTranscript={notEnoughTranscript}
              setNotEnoughTranscript={setNotEnoughTranscript}
              isReadyToRecord={isReadyToRecord}
              readyToRecordErrors={readyToRecordErrors}
              requestMicrophonePermissions={requestMicrophonePermissions}
              isStalled={isStalled}
              showNoteGenerationDelayedWarning={
                showNoteGenerationDelayedWarning
              }
              hideNoteGenerationDelayedWarning={
                hideNoteGenerationDelayedWarning
              }
            />
          )}
        </div>
      </div>

      {isStalled &&
        (recordingScribe?.isRecording || !recordingScribe?.isRecording) &&
        !isDesktop && (
          <RecordingContextStalled
            // onCancel={handleTogglePause}
            // onSubmit={() => {
            //   togglePause();
            //   cancelRecording();
            //   onCancel();
            // }}
            onSubmit={() => window.location.reload()}
          />
        )}
    </>
  );
};

export default ChatCallScribePanel;
