import { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../../../store/user/api";
import type { User } from "../../../../store/user/userReducer";
import AutocompleteVirtualized from "../../../Basic/Autocomplete/AutocompleteVirtualized";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";
import TeamMember from "./TeamMember";

interface AddTeamFormProps {
  onClose: () => void;
  onSave: (teamName: string, members: User[]) => void;
  className?: string;
}

const AddTeamForm = ({
  onClose = () => {},
  onSave = () => {},
  className = "",
}: AddTeamFormProps) => {
  const [teamNameValue, setTeamNameValue] = useState("");
  const [members, setMembers] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [editMembers, setEditMembers] = useState(false);

  const { data: users = [] } = useGetUsersQuery({
    search: "",
  });

  useEffect(() => {
    setMemberOptions(
      users.filter((user) => {
        return !members.some((member) => member.doctor_id === user.doctor_id);
      }),
    );
  }, [users, members]);

  const handleRemoveMember = (memberId) => {
    setMembers(members.filter((member) => member.doctor_id !== memberId));
  };

  const handleSelectMember = (member) => {
    setMembers([...members, member]);
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="px-5">
        <p className="text-sm font-medium text-tertiary mb-1 pr-2">
          Mention Name
        </p>
        <div className="relative w-full mb-4">
          <Input
            value={teamNameValue}
            onChange={(e) => setTeamNameValue(e.target.value)}
            name="teamName"
            placeholder="Mention"
            className="w-full"
            inputClassName="h-10 pl-7 text-base font-semibold"
          />
          <p className="absolute left-3 top-2 text-base font-semibold">@</p>
        </div>

        <div className="flex items-center justify-between mb-2 font-bold pr-2">
          <p className="text-sm">Members ({members ? members.length : 0})</p>
          {members?.length > 0 && (
            <button
              type="button"
              className="text-primary-blue text-xs"
              onClick={() => setEditMembers(!editMembers)}
            >
              Edit
            </button>
          )}
        </div>

        {members?.length > 0 && (
          <div className="space-y-1 mb-2 pr-2 overflow-y-auto scrollbar min-h-0">
            {members.map((member) => (
              <TeamMember
                key={member.doctor_id}
                member={member}
                edit={editMembers}
                onRemove={handleRemoveMember}
              />
            ))}
          </div>
        )}

        <AutocompleteVirtualized
          options={memberOptions}
          onSelect={handleSelectMember}
          placeholder="Add new member"
          className="w-full bg-white rounded-lg pr-2"
          isDoctors
        />
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button
          className="w-full md:w-fit"
          variant="gray"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          disabled={!teamNameValue.trim()}
          onClick={() => onSave(teamNameValue, members)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddTeamForm;
