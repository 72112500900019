import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-circle-completed.svg";

const tableRowClasses = "w-full items-center grid grid-cols-12 gap-4";
const statusColumnClasses = "col-span-1";
const activityLogColumnClasses = "col-span-3 truncate";
const callerColumnClasses = "col-span-3 truncate";
const reasonColumnClasses = "col-span-5 truncate";

interface CallsTableRowsSkeletonProps {
  rows?: number;
  bgColor?: string;
  animation?: "wave" | "pulse";
}

const CallsTableRowsSkeleton = ({
  rows = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}: CallsTableRowsSkeletonProps) => {
  return (
    <>
      {Array.from({ length: rows }, (_, index) => (
        <div
          key={`call-${index}`}
          className={`rounded-md py-3 px-4 cursor-pointer text-sm font-medium mt-3 ${tableRowClasses}
        bg-white`}
        >
          <div className={statusColumnClasses}>
            <CheckIcon
              width="30"
              height="30"
              fill="#D0D5DD"
              className="flex-none"
            />
          </div>

          <div className={activityLogColumnClasses}>
            <div className="flex items-center space-x-4 truncate">
              <Skeleton
                animation={animation}
                variant="circular"
                width={40}
                height={40}
                sx={{ bgcolor: bgColor, flex: "none" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={100}
                height={14}
                sx={{ bgcolor: bgColor, borderRadius: "14px" }}
              />
            </div>
          </div>

          <div className={callerColumnClasses}>
            <div className="flex flex-col space-y-1.5">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={130}
                height={14}
                sx={{ bgcolor: bgColor, borderRadius: "14px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={100}
                height={12}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
            </div>
          </div>

          <div className={reasonColumnClasses}>
            <Skeleton
              animation={animation}
              variant="rounded"
              width={120}
              height={14}
              sx={{ bgcolor: bgColor, borderRadius: "14px" }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default CallsTableRowsSkeleton;
