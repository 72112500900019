import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDebounce } from "use-debounce";
import emptyTeamsIllustrationSrc from "../../../../assets/empty-teams.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-redesign.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useGetTeamsQuery } from "../../../../store/user/api";
import { createTeam, deleteTeam } from "../../../../store/user/thunks";
import ActionsButton from "../../../Basic/ActionsButton";
import AvatarStack from "../../../Basic/AvatarStack";
import Button from "../../../Basic/Button";
import DeleteModal from "../../../Basic/DeleteModal";
import UserTable from "../../../Basic/UserTable";
import TeamTableSkeleton from "../../../Skeletons/TeamTableSkeleton";
import DeleteErrorModal from "../DeleteErrorModal";
import AddTeamModal from "./AddTeamModal";
import EditTeamModal from "./EditTeamModal";
import TeamIconButton from "./TeamIconButton";
import TeamListItem from "./TeamListItem";

interface TeamsProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const Teams = ({ openModal, setOpenModal }: TeamsProps) => {
  const { teamSearchValue } = useAppSelector((state) => state.user);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteErrorModal, setOpenDeleteErrorModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [teamSearchFilter] = useDebounce(teamSearchValue, 200);
  const dispatch = useAppDispatch();

  const { data: teams = [], isLoading: teamsLoading } = useGetTeamsQuery({
    search: teamSearchFilter,
  });

  const columns = [
    { label: "Icon", accessor: "icon" },
    { label: "Mention", accessor: "name" },
    { label: "Members", accessor: "users" },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    teams.forEach((team) => {
      tableData.push({
        team: team,
        icon: <TeamIconButton team={team} />,
        name: (
          <p className="truncate md:max-w-[180px] xl:max-w-[210px] 2xl:max-w-[320px]">
            @{team.name}
          </p>
        ),
        users:
          team.members.length > 0 ? (
            <AvatarStack
              users={team.members}
              length={4}
              totalNumber={team.members ? team.members.length : 0}
            />
          ) : (
            <p>-</p>
          ),
        actions: (
          <ActionsButton
            onClick={() => setSelectedTeam(team)}
            actions={[
              {
                icon: (
                  <DeleteIcon width="20" height="20" className="flex-none" />
                ),
                label: "Delete",
                onClick: onDeleteTeam,
              },
            ]}
          />
        ),
      });
    });
    return tableData;
  };

  const onCreateTeam = (teamName, members = []) => {
    dispatch(createTeam(teamName, members, () => setOpenModal(false)));
  };

  const onEditTeam = (team) => {
    if (team) {
      setSelectedTeam(team);
      setOpenEditModal(true);
    }
  };

  const onDeleteTeam = () => {
    setOpenDeleteModal(true);
  };

  const handleDeleteTeam = () => {
    dispatch(deleteTeam(selectedTeam.doctor_team_id));
    setSelectedTeam({});
    setOpenDeleteModal(false);
  };

  return (
    <div className="relative w-full md:h-full overflow-hidden">
      {teamsLoading || teams === null ? (
        <TeamTableSkeleton />
      ) : teams.length > 0 ? (
        isDesktop ? (
          <UserTable
            columns={columns}
            data={getTableData()}
            onClickRow={(team) => onEditTeam(team)}
          />
        ) : (
          <div className="space-y-3">
            {teams.map((team) => (
              <TeamListItem
                key={team.doctor_team_id}
                team={team}
                onClick={() => onEditTeam(team)}
                onClickActions={() => setSelectedTeam(team)}
                onDelete={onDeleteTeam}
              />
            ))}
          </div>
        )
      ) : (
        <div className="w-full flex flex-col items-center justify-center py-10 md:py-16 space-y-7">
          <img
            src={emptyTeamsIllustrationSrc}
            alt="Empty Teams"
            width="459"
            height="165"
          />
          <p className="text-xl font-bold">No Teams Found</p>
          <Button
            className="font-semibold text-sm sm:text-base space-x-2"
            onClick={() => setOpenModal(true)}
          >
            <PlusIcon width="20" height="20" stroke="white" />
            <p>Create Team</p>
          </Button>
        </div>
      )}

      <AddTeamModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onSave={onCreateTeam}
      />

      <EditTeamModal
        open={openEditModal}
        onClose={(e) => {
          e?.preventDefault();
          setSelectedTeam(null);
          setOpenEditModal(false);
        }}
        onCancel={(e) => {
          e?.preventDefault();
          setSelectedTeam(null);
          setOpenEditModal(false);
        }}
        selectedTeam={selectedTeam}
      />

      {openDeleteModal && (
        <DeleteModal
          onClose={() => setOpenDeleteModal(false)}
          onDelete={handleDeleteTeam}
          title="Delete Team"
          description="Are you sure you want to delete the team?"
        />
      )}

      {openDeleteErrorModal && (
        <DeleteErrorModal
          onClose={() => setOpenDeleteErrorModal(false)}
          title="Delete Team"
          message="You cannot delete team with patients assigned"
        />
      )}
    </div>
  );
};

export default Teams;
