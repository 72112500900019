import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { createUser } from "../../../../store/user/thunks";
import Button from "../../../Basic/Button";
import InputMultipleEmails from "../../../Basic/InputMultipleEmails";
import Select from "../../../Basic/Select";
import SiteMultipleSelect from "../../../Basic/SiteMultipleSelect";

interface AddMultipleUsersFormProps {
  onClose: () => void;
  className?: string;
}

const AddMultipleUsersForm = ({
  onClose,
  className = "",
}: AddMultipleUsersFormProps) => {
  const { roleOptions } = useAppSelector((state) => state.user);
  const [emails, setEmails] = useState([]);
  const [emailsError, setEmailsError] = useState("");
  const [selectedRole, setSelectedRole] = useState(1);
  const [selectedSites, setSelectedSites] = useState([]);
  const [sitesError, setSitesError] = useState(null);
  const dispatch = useAppDispatch();

  const onInvite = () => {
    if (emails.length === 0) {
      setEmailsError("Email can’t be blank");
      return;
    }

    if (selectedSites.length === 0) {
      setSitesError("Please select at least one site");
      return;
    }

    const providers = [];
    emails.map((email) => {
      providers.push({
        email: email,
        role_id: Number(selectedRole),
        // todo rename to sites when renamed on backend
        facilities: selectedSites.map((site) => site.customer_id),
      });
    });

    dispatch(createUser(providers, onClose));
    onClose();
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <form className="px-5 space-y-3 flex flex-col min-h-0 overflow-y-auto scrollbar">
        <InputMultipleEmails
          label="Email"
          required
          withoutAsterisk
          placeholder="Input email"
          emails={emails}
          setEmails={setEmails}
          error={emailsError}
          setError={setEmailsError}
        />
        <Select
          label="Role"
          placeholder="Select role"
          options={roleOptions}
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        />
        <div className="space-y-1.5">
          <p className="font-semibold text-sm">Clinics</p>
          <SiteMultipleSelect
            displaySelect
            selectedSites={selectedSites}
            setSelectedSites={setSelectedSites}
            error={sitesError}
            setError={setSitesError}
          />
        </div>
      </form>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button className="w-full md:w-fit" onClick={onInvite}>
          Invite
        </Button>
      </div>
    </div>
  );
};

export default AddMultipleUsersForm;
