import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { occupations } from "../../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { baseApi } from "../../../../store/baseApi";
import { getSelectedConversationId } from "../../../../store/call/callSlice";
import { updateUserSites } from "../../../../store/site/thunks";
import { updateUser } from "../../../../store/user/thunks";
import { type User, selectUser } from "../../../../store/user/userReducer";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";
import Select from "../../../Basic/Select";
import SiteMultipleSelect from "../../../Basic/SiteMultipleSelect";
import type { FixLater } from "../../../IndependentScribe/store/scribeSlice";
import UserPictureName from "./UserPictureName";

interface EditUserFormProps {
  className?: string;
  onClose: () => void;
  selectedUser: User;
}

const EditUserForm = ({
  className = "",
  onClose,
  selectedUser,
}: EditUserFormProps) => {
  const user = useAppSelector(selectUser);
  const { roleOptions } = useAppSelector((state) => state.user);
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const { userSites } = useAppSelector((state) => state.site);
  const [showInputPosition, setShowInputPosition] = useState(false);
  const [positionOther, setPositionOther] = useState("");
  const [positionError, setPositionError] = useState<string | null>(null);
  const [selectedSites, setSelectedSites] = useState(
    userSites[selectedUser.doctor_id] || [],
  );
  const [sitesError, setSitesError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      display_name: selectedUser.display_name,
      role_id: selectedUser.role_id,
      occupation: selectedUser.occupation,
    },
  });

  const watchPosition = watch("occupation");

  const availableRoleOptions = roleOptions.filter(
    (role) => Number(role.value) >= user.role_id,
  );

  useEffect(() => {
    setSelectedSites(userSites[selectedUser.doctor_id] || []);
  }, [userSites, selectedUser]);

  useEffect(() => {
    if (watchPosition === "other") {
      setShowInputPosition(true);
    } else {
      setPositionOther("");
      setShowInputPosition(false);
      clearErrors("occupation"); // Clear occupation errors when not 'other'
    }
  }, [watchPosition, clearErrors]);

  useEffect(() => {
    if (showInputPosition) {
      setPositionOther(selectedUser?.occupation || "");
    } else {
      setPositionOther("");
    }
  }, [showInputPosition, selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      setValue("display_name", selectedUser.display_name);
      setValue("role_id", selectedUser.role_id);

      const isPositionListed = occupations.some(
        (option) => option.value === selectedUser.occupation,
      );

      if (!isPositionListed) {
        setValue("occupation", "other");
        setShowInputPosition(true);
        setPositionOther(selectedUser.occupation);
      } else {
        setValue("occupation", selectedUser.occupation);
        setShowInputPosition(false);
        setPositionOther("");
      }
    }
  }, [selectedUser, setValue]);

  const checkInputPositionOther = useCallback(() => {
    if (watchPosition === "other" && !positionOther?.trim()) {
      setPositionError("Position can't be empty");
    } else {
      setPositionError(null);
    }
  }, [watchPosition, positionOther]);

  useEffect(() => {
    checkInputPositionOther();
  }, [checkInputPositionOther]);

  const onSubmit = (data: any) => {
    const newRoleId = Number(data.role_id);

    // Validate role assignment
    if (newRoleId < user.role_id) {
      setError("role_id", {
        type: "manual",
        message: "You cannot assign a role higher than your own.",
      });
      return;
    }

    clearErrors("role_id");

    data.role_id = newRoleId;

    if (data.occupation === "other") {
      data.occupation = positionOther.trim();
    }

    if (!positionError) {
      if (
        (user.role_id === 1 || user.role_id === 0) &&
        selectedSites.length === 0
      ) {
        setSitesError("Please select at least one site");
        return;
      }

      if (user.role_id === 1 || user.role_id === 0) {
        dispatch(
          updateUserSites(
            selectedUser.doctor_id,
            selectedSites.map((site) => site.customer_id),
          ),
        );
      }

      dispatch(
        updateUser(selectedUser.doctor_id, data, () => {
          dispatch(
            baseApi.util.invalidateTags([
              { type: "Conversation", id: selectedConversationId },
            ]),
          );
          onClose();
        }),
      );
    }
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <form
        id="edit-user"
        onSubmit={handleSubmit(onSubmit)}
        className="px-5 space-y-4 overflow-x-hidden min-h-0 flex flex-col overflow-y-auto scrollbar"
      >
        <UserPictureName user={selectedUser} />

        <Input
          label="Display Name"
          register={register}
          name="display_name"
          placeholder="Type display name"
          required="Display name can't be empty"
          withoutAsterisk
          validate={(value) => {
            if (value.trim()) {
              return true;
            }
            return "Display name can't be empty";
          }}
          error={errors.display_name}
        />
        <Select
          label="Role"
          placeholder="Select role"
          name="role_id"
          options={availableRoleOptions}
          register={register}
          disabled={user.role_id === 2}
          error={errors.role_id}
        />
        <Select
          label="Position"
          placeholder="Select position"
          name="occupation"
          options={occupations}
          register={register}
        />
        {showInputPosition && (
          <Input
            value={positionOther}
            onChange={(e) => setPositionOther(e.target.value)}
            placeholder="Enter position"
            required="Position can't be empty"
            error={positionError as FixLater}
          />
        )}
        <div className="space-y-1.5">
          <p className="font-semibold text-sm">Clinics</p>
          <SiteMultipleSelect
            displaySelect={user.role_id === 1 || user.role_id === 0}
            selectedSites={selectedSites}
            setSelectedSites={setSelectedSites}
            error={sitesError}
            setError={setSitesError}
          />
        </div>
      </form>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button
          className="w-full md:w-fit"
          variant="gray"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          form="edit-user"
          type="submit"
          onClick={checkInputPositionOther}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditUserForm;
