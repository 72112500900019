import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { campaignTypes } from "../../helpers/constants";
import { handleRequestError } from "../../helpers/helpers";
import type { AppDispatch } from "../index";
import { setPatientLoaderAction } from "../patient/actions";
import { setNotificationAction } from "../user/actions";
import {
  setCampaignLoaderAction,
  setCampaignTagsAction,
  setCampaignsAction,
  setSelectedEnrollCampaignAction,
  setSmartEnrollLoadingAction,
  setUpcomingMessagesAction,
} from "./actions";
import {
  campaignSchema,
  campaignsSchema,
  nextCheckinsSchema,
  smartEnrollDataSchema,
} from "./validationSchemas";

export const getCampaigns =
  (onEnd = (_: unknown) => {}) =>
  (dispatch: AppDispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .get(`${BASE_URL}/campaigns/list`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to load campaigns",
          );
        } else {
          const campaigns = response.data.data.campaigns;

          handleValidation(campaignsSchema, campaigns, "getCampaigns");

          dispatch(setCampaignsAction(campaigns));

          const campaignTags = [];
          campaigns.forEach((campaign) => {
            const type = campaignTypes.find(
              (type) => type.value === campaign.campaign_type,
            );
            if (type) {
              campaignTags.push({
                value: campaign.campaign_type,
                text: type.text,
              });
            }
          });
          dispatch(setCampaignTagsAction(campaignTags));
          onEnd(campaigns);
        }
        dispatch(setCampaignLoaderAction(false));
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to load campaigns");
      });
  };

export const getCampaign = (campaignId) => (dispatch: AppDispatch) => {
  axios
    .get(`${BASE_URL}/campaigns/describe/${campaignId}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load campaign",
        );
      } else {
        const campaign = response.data.data.campaign;

        handleValidation(campaignSchema, campaign, "getCampaign");

        dispatch(setSelectedEnrollCampaignAction(campaign));
      }
    })
    .catch((e: Error | AxiosError) => {
      handleRequestError(e, dispatch, "Failed to load campaign");
    });
};

export const getUpcomingMessages = (campaignIds) => (dispatch: AppDispatch) => {
  axios
    .post(`${BASE_URL}/campaigns/next-checkins`, {
      campaign_ids: campaignIds,
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load upcoming messages",
        );
      } else {
        const nextCheckins = response.data.data.next_checkins;

        handleValidation(
          nextCheckinsSchema,
          nextCheckins,
          "getUpcomingMessages",
        );

        dispatch(setUpcomingMessagesAction(nextCheckins));
      }
    })
    .catch((e: Error | AxiosError) => {
      handleRequestError(e, dispatch, "Failed to load upcoming messages");
    });
};

export const updateCampaignDetails =
  (
    campaignId,
    name,
    description,
    firstMessage,
    checkinMessage,
    lastMessage,
    breakMessage,
    onEnd = () => {},
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/update/${campaignId}`, {
        campaign: {
          campaign: name,
          description: description,
          first_message: firstMessage,
          checkin_message: checkinMessage,
          last_message: lastMessage,
          break_message: breakMessage,
        },
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update campaign",
          );
        } else {
          dispatch(getCampaigns());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated",
            }),
          );
        }
        dispatch(setCampaignLoaderAction(true));
        onEnd();
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to update campaign");
      });
  };

export const updateCampaignControls =
  (
    campaignId,
    minQuestions,
    maxQuestions,
    defaultStart,
    defaultFrequency,
    reminderActive,
    reminderMessage,
    reminderTimes,
    firstFollowup,
    onEnd = () => {},
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/update/${campaignId}`, {
        campaign: {
          min_questions: minQuestions,
          max_questions: maxQuestions,
          default_time_to_send: defaultStart,
          default_frequency: defaultFrequency,
          reminder_active: reminderActive,
          reminder_message: reminderMessage,
          reminder_times: reminderTimes,
          first_followup: firstFollowup,
        },
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update campaign",
          );
        } else {
          dispatch(getCampaigns());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated",
            }),
          );
        }
        dispatch(setCampaignLoaderAction(true));
        onEnd();
      })
      .catch((e: Error | AxiosError) => {
        handleRequestError(e, dispatch, "Failed to update campaign");
      });
  };

export const updateCampaign =
  (
    campaignId,
    patientIds,
    outreach_focus,
    outreach_goals,
    ehr_information,
    reschedule = false,
    onSuccess = () => {},
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/schedule/${campaignId}`, {
        patient_ids: patientIds,
        outreach_focus: outreach_focus,
        outreach_goals: outreach_goals,
        ehr_information: ehr_information,
        reschedule: reschedule,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update campaign",
          );
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated for selected patients",
            }),
          );
        }
        dispatch(setPatientLoaderAction(false));
      })
      .catch((e: AxiosError) => {
        handleRequestError(e, dispatch, "Failed to update campaign");
      });
  };

export const scheduleCampaign =
  (
    campaignId,
    patientIds,
    start,
    frequency,
    outreach_focus,
    outreach_goals,
    ehr_information,
    doctor_id,
    consent = true,
    reschedule = false,
    onSuccess = () => {},
    showSuccessNotification = true,
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/schedule/${campaignId}`, {
        patient_ids: patientIds,
        start: start,
        frequency: frequency,
        outreach_focus: outreach_focus,
        outreach_goals: outreach_goals,
        ehr_information: ehr_information,
        reschedule: reschedule,
        doctor_id: doctor_id,
        consent: consent,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to schedule campaign",
          );
        } else {
          onSuccess();
          if (showSuccessNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: reschedule
                  ? "Successfully updated campaign"
                  : "Successfully scheduled campaign",
                desc: reschedule
                  ? "Campaign has been successfully updated for selected patient"
                  : "Campaign has been successfully scheduled for selected patient",
              }),
            );
          }
        }
        dispatch(setPatientLoaderAction(false));
      })
      .catch((e: AxiosError) => {
        handleRequestError(e, dispatch, "Failed to schedule campaign");
      });
  };

export const cancelUpcomingMessage =
  (
    campaignId,
    campaignPatientId,
    patientId,
    onSuccess = () => {},
    showSuccessNotification = true,
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/campaigns/cancel-checkin`, {
        campaign_id: campaignId,
        campaign_patient_id: campaignPatientId,
        patient_id: patientId,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to cancel upcoming message",
          );
        } else {
          if (showSuccessNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Successfully cancelled upcoming message",
                desc: "Upcoming message has been successfully cancelled",
              }),
            );
          }
          onSuccess();
        }
      })
      .catch((e: AxiosError) => {
        handleRequestError(e, dispatch, "Failed to cancel upcoming message");
      });
  };

export const campaignSmartEnroll =
  (patientId, campaignId, onSuccess = (_: unknown) => {}) =>
  (dispatch: AppDispatch) => {
    dispatch(setSmartEnrollLoadingAction(true));
    axios
      .get(`${BASE_URL}/campaigns/smart-enroll/${patientId}/${campaignId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to smart enroll patient",
          );
        } else {
          const smartEnrollData = response.data;

          handleValidation(
            smartEnrollDataSchema,
            smartEnrollData,
            "campaignSmartEnroll",
          );

          onSuccess(smartEnrollData);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to smart enroll patient");
      })
      .finally(() => {
        dispatch(setSmartEnrollLoadingAction(false));
      });
  };
