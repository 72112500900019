import type { Patient } from "../patient/interfaces";
import type { Team } from "../user/userReducer";
import type { User } from "../user/userReducer";

export enum CallStatus {
  Pending = "pending",
  Completed = "completed",
}

export enum CallStatusTab {
  Pending = "review",
  Completed = "completed",
}

export enum CallDirection {
  Inbound = "inbound",
  Outbound = "outbound",
}

export enum CallCategory {
  MedicationRefill = "Medication Refill",
  Scheduling = "Scheduling",
  InsuranceBilling = "Billing/Insurance",
  SickNote = "Sick Note",
  TestResultInquiry = "Test Result Inquiry",
  ReferralRequests = "Referral Requests",
  MedicalQuestion = "Medical Question",
  Other = "Other",
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum InboxItemType {
  Call = "call",
  Text = "text",
}

export interface Call {
  assistant_call_id: number;
  date: string;
  direction: CallDirection | null | "";
  is_new_patient: boolean;
  is_urgent: boolean;
  reasons: CallCategory[];
  status: CallStatus;
  summary: string | null;
  time: number;
}

export interface InboxPatient {
  conversation_id: number;
  patient_birthdate: string;
  patient_full_name: string;
  patient_gender: string | null;
  patient_id: number;
  patient_npi: number | null;
  patient_phone_number: string | null;
  patient_preferred_name: string | null;
  patient_pronouns: string | null;
  patient_sex: string;
}

export interface InboxItem {
  data: Call;
  total: number;
  type: InboxItemType;
}

export interface InboxListItem {
  patient: InboxPatient;
  items: InboxItem[];
}

export interface CallCounts {
  hours_saved: number;
  incoming_calls: number;
  me: number;
  teams: Record<string, number> | null;
  total_completed: number;
  total_reviews: number;
}

export interface CallTags {
  is_new_user: boolean;
  is_urgent: boolean;
}

export interface CallNote {
  assistant_call_id: number;
  assistant_call_note_id: number;
  created_at: string;
  created_by: number;
  note: string;
  updated_at: string;
  updated_by: number;
}

export interface CallNotes {
  [assistant_call_id: number]: CallNote[];
}

export interface Doctor {
  doctor_id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  profile_picture: string;
}

export interface Message {
  from: "patient" | "doctor";
  is_automatic: 0 | 1;
  is_internal: 0 | 1;
  send_at: string;
  sender: Doctor | null;
  type: string;
  data: { message: string };
  message: string;
}

export interface Conversation {
  doctor: Doctor;
  assigned_to: User;
  assigned_to_team: Team;
  patient: Patient;
  summary: string | null;
  outreach_focus: string | null;
  outreach_goals: string | null;
  ehr_information: string | null;
  note: string | null;
  status: string;
  conversation_id: number | string;
  state: number;
  completed: number;
  suggested_answer: string | null;
  consent_status: string;
  waiting_response: number;
  last_message_date: string;
  generate_note_enabled: boolean;
  differential_diagnoses: string | null;
  trackable: boolean;
  is_urgent: number;
  campaign: string;
  campaign_id: number | null;
  campaign_type: string;
  messages: Message[];
}

export interface CallState {
  calls: InboxListItem[] | null;
  callsCompletedTmp: number[];
  callsTotal: number;
  reviewTotal: number;
  callCounts: CallCounts;
  tableScrollPosition: number;
  selectedCall: InboxListItem | null;
  isMessageInputOpen: boolean;
  isFilterByMentions: boolean;
  isFilterByAssignedToCurrentUser: boolean;
  filterByTeamId: number | null;
}

export interface CallSearchParams {
  q?: string;
  categories?: CallCategory[];
  is_new_user?: boolean;
  is_urgent?: boolean;
  direction?: CallDirection | "";
  limit?: number;
  offset?: number;
  sort_direction?: SortDirection;
  has_mention?: 0 | 1;
  assigned_to_me?: 0 | 1;
  team_id?: number | string | null;
}
