import Bugsnag from "@bugsnag/js";
import { createSelector } from "reselect";
import type z from "zod";
import type { RootState } from "..";
import type { FixLater } from "../../components/IndependentScribe/store/scribeSlice";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { rowsPerPage } from "../../helpers/constants";
import { baseApi, validateResponse } from "../baseApi";
import {
  addFailedMessageAction,
  setSentMessagesAction,
} from "../conversation/actions";
import { conversationSchema } from "../conversation/validationSchemas";
import { setNotificationAction } from "../user/actions";
import type { Team } from "../user/userReducer";
import type { User } from "../user/userReducer";
import { addToCompletedTmp, addToPendingTmp } from "./callSlice";
import {
  type CallCounts,
  type CallNote,
  type CallSearchParams,
  type CallStatusTab,
  type Conversation,
  type InboxListItem,
  SortDirection,
} from "./interfaces";
import {
  callCountsSchema,
  callNoteSchema,
  callTotalSchema,
  inboxListSchema,
} from "./validationSchemas";

const defaultSearchParams: CallSearchParams = {
  q: "",
  categories: [],
  is_new_user: false,
  is_urgent: false,
  direction: "",
  limit: rowsPerPage,
  offset: 0,
  sort_direction: SortDirection.Desc,
  has_mention: 0,
  assigned_to_me: 0,
  team_id: null,
};

export const callApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<
      { inbox: InboxListItem[]; total: number },
      { status: CallStatusTab; params: CallSearchParams }
    >({
      query: ({ status, params }) => {
        const searchParams = { ...defaultSearchParams, ...params };

        return {
          url: `/inbox/search/${status}`,
          method: "POST",
          data: searchParams,
        };
      },
      transformResponse: (response: {
        data: unknown;
        total: unknown;
      }): { inbox: InboxListItem[]; total: number } => {
        type ValidatedInboxList = z.infer<typeof inboxListSchema>;
        type ValidatedTotal = z.infer<typeof callTotalSchema>;

        const patients = validateResponse(inboxListSchema)(
          response.data,
        ) as ValidatedInboxList;
        const total = validateResponse(callTotalSchema)(
          response.total,
        ) as ValidatedTotal;

        return {
          inbox: patients as InboxListItem[],
          total,
        };
      },
      providesTags: (result, _, { status, params }) =>
        result
          ? [
              { type: "Call" as const, status, id: "LIST" },
              ...result.inbox.map(({ patient }) => ({
                type: "Call" as const,
                id: patient.patient_id,
                status,
                params,
              })),
            ]
          : [{ type: "Call" as const, status, id: "LIST", params }],
    }),

    getCallCounts: builder.query<CallCounts, void>({
      query: () => ({
        url: "/inbox/count",
        method: "POST",
        data: {
          numbers_to_count: [
            "hours_saved",
            "incoming_calls",
            "me",
            "teams",
            "total_reviews",
            "total_completed",
          ],
        },
      }),
      providesTags: ["CallCounts"],
      transformResponse: (response) => {
        return handleValidation(callCountsSchema, response, "getCallCounts");
      },
    }),

    getConversation: builder.query<Conversation, number>({
      query: (id) => ({
        url: `/conversation/${id}`,
      }),
      transformResponse: (response: {
        data: { conversation: Conversation };
      }) => {
        const conversation = response.data.conversation;

        handleValidation(conversationSchema, conversation, "getConversation");

        return conversation as Conversation;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        const { data } = await queryFulfilled;

        const state = getState() as RootState;
        const sentMessages = JSON.parse(
          JSON.stringify(state.conversation.sentMessages),
        );

        const messages = sentMessages[id] ?? [];

        for (const message of messages) {
          if (data.messages.find((m) => m.message === message.message)) {
            sentMessages[id].splice(sentMessages[id].indexOf(message), 1);
          }
        }

        dispatch(setSentMessagesAction(sentMessages));
      },
      providesTags: (_, __, id) => [{ type: "Conversation", id }],
    }),

    getCallNotes: builder.query<CallNote[], number>({
      query: (id) => ({
        url: `/call/note/${id}`,
      }),
      transformResponse: (response: {
        data: { notes: CallNote[] };
      }) => {
        const notes = response.data.notes;

        handleValidation(callNoteSchema.array(), notes, "getCallNotes");

        return notes as CallNote[];
      },
      providesTags: (result, _, id) =>
        result ? [{ type: "CallNote", id }] : [{ type: "CallNote", id }],
    }),

    assignPatientToProviderOrTeam: builder.mutation<
      { message: string },
      {
        conversation: Conversation;
        assignTo: {
          provider: User | null;
          team: Team | null;
        };
      }
    >({
      query: ({ conversation, assignTo }) => ({
        url: `/inbox/assign/${conversation.patient.patient_id}`,
        method: "POST",
        data: {
          assign_to_id: assignTo.provider?.doctor_id ?? null,
          assign_to_team_id: assignTo.team?.doctor_team_id ?? null,
        },
      }),
      async onQueryStarted(
        { conversation, assignTo },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getConversation",
            Number(conversation.conversation_id),
            (draft) => {
              draft.assigned_to = assignTo.provider;
              draft.assigned_to_team = assignTo.team;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { conversation }) => [
        "Call",
        "CallCounts",
        {
          type: "Conversation",
          id: conversation.conversation_id,
        },
      ],
    }),

    moveToReview: builder.mutation<void, { conversation: Conversation }>({
      query: ({ conversation }) => ({
        url: `/inbox/move-to-review/${conversation.patient.patient_id}`,
        method: "POST",
      }),
      async onQueryStarted({ conversation }, { dispatch, queryFulfilled }) {
        dispatch(addToCompletedTmp(conversation.patient.patient_id));

        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getConversation",
            Number(conversation.conversation_id),
            (draft) => {
              draft.completed = 0;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { conversation }) => [
        {
          type: "Call",
          id: "LIST",
        },
        "CallCounts",
        {
          type: "Conversation",
          id: conversation.conversation_id,
        },
      ],
    }),

    moveToCompleted: builder.mutation<
      void,
      { patientId: number; conversationId: number }
    >({
      query: ({ patientId }) => ({
        url: `/inbox/complete/${patientId}`,
        method: "POST",
      }),
      async onQueryStarted(
        { patientId, conversationId },
        { dispatch, queryFulfilled },
      ) {
        dispatch(addToPendingTmp(patientId));

        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getConversation",
            Number(conversationId),
            (draft) => {
              draft.completed = 1;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { conversationId }) => [
        {
          type: "Call",
          id: "LIST",
        },
        "CallCounts",
        {
          type: "Conversation",
          id: conversationId,
        },
      ],
    }),

    sendMessage: builder.mutation<
      void,
      {
        message: string;
        conversationId: number;
        isInternal: boolean;
      }
    >({
      query: ({ message, conversationId, isInternal }) => ({
        url: "/message/send",
        method: "POST",
        data: {
          conversation_id: conversationId,
          message,
          is_internal: isInternal,
        },
      }),
      async onQueryStarted(
        { message, conversationId, isInternal },
        { dispatch, queryFulfilled, getState },
      ) {
        const onError = (error: FixLater) => {
          dispatch(addFailedMessageAction(message, conversationId));
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to send message",
            }),
          );
          Bugsnag.notify(error);
        };

        const state = getState() as RootState;
        const sentMessages = JSON.parse(
          JSON.stringify(state.conversation.sentMessages),
        );

        if (!sentMessages[conversationId]) {
          sentMessages[conversationId] = [];
        }

        sentMessages[conversationId].push({
          message,
          isInternal,
        });

        dispatch(setSentMessagesAction(sentMessages));

        try {
          const { data }: { data: FixLater } = await queryFulfilled;

          if (data.error) {
            onError(data.error);
          }
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: (_, __, { conversationId, isInternal }) => {
        const list: FixLater = [
          {
            type: "Conversation",
            id: conversationId,
          },
        ];

        if (isInternal) {
          list.push({
            type: "Call",
            id: "LIST",
          });

          list.push("CallCounts");
        }

        return list;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCallsQuery,
  useGetConversationQuery,
  useGetCallCountsQuery,
  useGetCallNotesQuery,
  useAssignPatientToProviderOrTeamMutation,
  useMoveToReviewMutation,
  useMoveToCompletedMutation,
  useSendMessageMutation,
} = callApi;

const selectCallsResult = callApi.endpoints.getCalls.select;

export const selectNextConversation = createSelector(
  [
    // Keep accepting the full queryArg as needed by RTK Query
    (
      state: RootState,
      _conversationId: number,
      queryArg: { status: CallStatusTab; params: CallSearchParams },
    ) => selectCallsResult(queryArg)(state as FixLater).data?.inbox,
    (_, conversationId) => conversationId,
  ],
  (inbox, conversationId) => {
    if (!conversationId || !inbox) {
      return null;
    }

    const callIndex = inbox.findIndex(
      (call) => call.patient.conversation_id === conversationId,
    );

    if (callIndex === -1) {
      return null;
    }

    let nextCallIndex = callIndex + 1;
    if (nextCallIndex < inbox.length) {
      return {
        patient: inbox[nextCallIndex].patient,
        items: inbox[nextCallIndex].items,
      };
    }

    nextCallIndex = callIndex - 1;
    if (nextCallIndex >= 0) {
      return {
        patient: inbox[nextCallIndex].patient,
        items: inbox[nextCallIndex].items,
      };
    }

    return null;
  },
);
