import type { Scribe } from "./store/interfaces";
import type { ScribeGroupItem } from "./store/selectors";

export const initialTitle = (date: Date) => {
  if (date) {
    return `Encounter - ${date.toLocaleDateString(undefined, {
      month: "numeric",
      day: "numeric",
    })}`;
  }

  console.log("initialTitle", date);
  return "Encounter";
};

export const isGeneratedNote = (note: ScribeGroupItem | Scribe) => {
  return (
    note?.note && !note?.isPaused && !note?.isGenerating && !note?.isRecording
  );
};
