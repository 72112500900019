import Bugsnag from "@bugsnag/js";
import axios, { type AxiosError } from "axios";
import { BASE_URL } from "../../helpers/config";
import { Pages } from "../../helpers/constants";
import { handleRequestError } from "../../helpers/helpers";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";
import { setVerificationCode } from "./actions";

export const verifyEmail =
  (code, providerEmail, navigate) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/customer/sign-up/v2/${code}`, { providerEmail })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to verify email address",
          );
        } else {
          dispatch(setVerificationCode(code));
          navigate(Pages.ONBOARDING);
          setTimeout(() => {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Email address successfully verified",
                desc: "Your email address has been successfully verified",
              }),
            );
          }, 300);
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Invalid code",
              desc: "The code you entered is incorrect. Please try again.",
            }),
          );
          console.error(error);
          Bugsnag.notify(error);
        } else {
          handleRequestError(error, dispatch, "Failed to verify email address");
        }
      });
  };

export const resendVerificationCode =
  (providerEmail) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/customer/reset-token/v2`, { providerEmail })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to resend verification code",
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Verification code successfully resent",
              desc: "The verification code has been successfully resent",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error?.response?.status === 429) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Too many requests",
              desc: "You’ve asked for the verification code too many times. Please wait a minute before trying again.",
            }),
          );
          console.error(error);
          Bugsnag.notify(error);
        } else {
          handleRequestError(
            error,
            dispatch,
            "Failed to resend verification code",
          );
        }
      });
  };

export const completeSignUp =
  (specialty, ehr, providerEmail, code, navigate) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/customer/sign-up/v2/${code}/complete`, {
        specialty,
        ehr,
        providerEmail,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to complete sign up",
          );
        } else {
          navigate(Pages.LOGIN);
          setTimeout(() => {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Onboarding completed",
                desc: "Onboarding has been successfully completed",
              }),
            );
          }, 300);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to complete onboarding");
      });
  };
