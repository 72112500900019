import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import { getFormattedTime } from "../../../helpers/helpers";
import ProfilePicture from "../../Basic/ProfilePicture";
import InputWithMentions from "../Mentions/InputWithMentions";
import type { MentionItem } from "../Mentions/interfaces";

const buttonStyle =
  "absolute top-1/2 -translate-y-1/2 w-5 h-5 rounded-full border flex items-center justify-center";

interface MessageCloudProps {
  type: "outgoing" | "incoming";
  combineWithPrevious: boolean;
  profilePicture: string;
  firstName: string;
  lastName: string;
  displayName: string;
  role?: string;
  message: string;
  time: string;
  failed?: boolean;
  isInternal?: boolean;
  mentionItems?: MentionItem[];
  onDelete?: () => void;
  onResend?: () => void;
}

const MessageCloud = ({
  type,
  combineWithPrevious,
  profilePicture,
  firstName,
  lastName,
  displayName,
  role,
  message,
  time,
  failed = false,
  isInternal = false,
  mentionItems = [],
  onDelete = () => {},
  onResend = () => {},
}: MessageCloudProps) => {
  const outgoing = type === "outgoing";

  return (
    <div
      className={`flex max-w-4/5 xl:max-w-2/3 relative
        ${outgoing ? "flex-row-reverse ml-auto" : "space-x-2"}
        ${combineWithPrevious ? "mt-2" : "mt-3"} `}
    >
      {combineWithPrevious ? (
        <div className="h-0 w-8 flex-none" />
      ) : (
        <ProfilePicture
          src={profilePicture}
          firstName={firstName}
          lastName={lastName}
          flagPadding={false}
        />
      )}

      <div className={outgoing ? "mr-2" : ""}>
        {!combineWithPrevious && (
          <div
            className={`h-8 flex flex-col justify-center ${outgoing ? "items-end" : "items-start"} ${role ? "mb-2" : ""}`}
          >
            <p className="text-sm font-semibold">
              {displayName ? displayName : `${firstName} ${lastName}`}
            </p>
            {role && <p className="text-xs text-tertiary">{role}</p>}
          </div>
        )}
        <div
          className={`text-xs leading-snug py-3 px-3 md:px-4 font-medium w-fit flex relative
            ${isInternal ? "bg-[#FFF7E9]" : outgoing ? "bg-primary-blue-light" : "bg-gray-foreground"}
            ${outgoing ? "rounded-outgoing-message ml-auto" : "rounded-incoming-message"}`}
        >
          <div className="whitespace-pre-wrap">
            {isInternal ? (
              <InputWithMentions
                mentionItems={mentionItems}
                value={message}
                disabled={true}
              />
            ) : (
              message
            )}
            <div className="text-xxs text-zinc-450 whitespace-nowrap flex float-right items-end -mb-1 -mr-1 mt-1 ml-1.5">
              {time ? (
                getFormattedTime(new Date(`${time.replace(/-/g, "/")} GMT+0`))
              ) : failed ? (
                <span className="text-red-500 font-semibold">failed</span>
              ) : (
                getFormattedTime(new Date())
              )}
            </div>
          </div>

          {failed && (
            <>
              <button
                type="button"
                onClick={onDelete}
                className={`${buttonStyle} left-full ml-2 border-red-400`}
              >
                <CloseIcon stroke="#ef4444" height="12" width="12" />
              </button>
              <button
                type="button"
                onClick={onResend}
                className={`${buttonStyle} right-full mr-2 border-primary-blue`}
              >
                <RefreshIcon
                  fill="#2970FF"
                  height="13"
                  width="13"
                  className="rotate-45"
                />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCloud;
