import type { User } from "../../../store/user/userReducer";
import MessageCloud from "./MessageCloud";
import { shouldCombineWithFailedPrevious } from "./messageUtils";

interface FailedMessagesListProps {
  failedMessages: any[];
  lastMessage: any;
  user: User;
  sentMessages: Record<string, any[]>;
  conversationId: number;
  onRemoveMessage: (messageId: string) => void;
  onResendMessage: (messageId: string, message: string) => void;
}

const FailedMessagesList = ({
  failedMessages,
  lastMessage,
  user,
  sentMessages,
  conversationId,
  onRemoveMessage,
  onResendMessage,
}: FailedMessagesListProps) => {
  if (!failedMessages.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {failedMessages.map((message, index) => {
        const combineWithPrevious =
          index === 0
            ? shouldCombineWithFailedPrevious(
                lastMessage,
                user,
                sentMessages,
                conversationId,
              )
            : true;

        return (
          <div key={message.id}>
            <MessageCloud
              combineWithPrevious={combineWithPrevious}
              type="outgoing"
              firstName={user.first_name}
              lastName={user.last_name}
              displayName={user.display_name}
              role={user.occupation}
              profilePicture={user.profile_picture}
              message={message.message}
              time=""
              failed
              onDelete={() => onRemoveMessage(message.id)}
              onResend={() => onResendMessage(message.id, message.message)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FailedMessagesList;
