import emptyTableSrc from "../../assets/no-patients.svg";
import Button from "../Basic/Button";

interface EmptyPatientsProps {
  onClick: () => void;
}

const EmptyPatients = ({ onClick }: EmptyPatientsProps) => {
  return (
    <div className="flex flex-col items-center justify-center h-full py-8">
      <img src={emptyTableSrc} alt="No Patients" width="570" height="200" />
      <p className="text-sm md:text-xl text-center font-semibold">
        No Patient Found
      </p>
      <Button
        className="text-base font-semibold whitespace-nowrap mt-8"
        onClick={onClick}
      >
        <p>Add patient</p>
      </Button>
    </div>
  );
};

export default EmptyPatients;
