import axios, { type AxiosError } from "axios";
import { setPatientNotesLoadingAction } from "../../components/IndependentScribe/store/actions";
import {
  type FixLater,
  setScribes,
} from "../../components/IndependentScribe/store/scribeSlice";
import {
  handleValidation,
  patientSchema,
} from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";
import {
  setNumOfFilteredPatientsAction,
  setNumOfPatientsAction,
  setNumOfPatientsByDoctorIdAction,
  setPatientExistsErrorAction,
  setPatientLoaderAction,
  setPatientsAction,
  setProfileLoadingAction,
  setSelectedPatientEnrollmentsAction,
  setSelectedPatientInfoAction,
} from "./actions";
import type { Patient } from "./interfaces";
import {
  enrollmentsSchema,
  numberOfPatientsSchema,
  patientScribesSchema,
  patientsDataSchema,
} from "./validationSchemas";

export const getNumOfPatients = (doctor) => (dispatch: AppDispatch) => {
  axios
    .post(`${BASE_URL}/patient/search`, {
      q: "",
      offset: 0,
      limit: 0,
      doctor_id: doctor
        ? doctor === "unassigned"
          ? null
          : Number(doctor.doctor_id)
        : null,
      unassigned: doctor === "unassigned",
      campaigns: [],
      status: [],
      insurances: [],
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load number of patients",
        );
      } else {
        const numberOfPatients: number = response?.data?.data?.total;

        handleValidation(
          numberOfPatientsSchema,
          numberOfPatients,
          "getNumOfPatients",
        );

        dispatch(setNumOfPatientsAction(numberOfPatients));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load number of patients");
    });
};

export const searchPatients =
  (
    searchInput: string,
    page: number,
    rows: number,
    doctor: FixLater,
    loader = true,
    onSuccess = () => {},
    campaigns = [],
    statuses = [],
    insurances = [],
  ) =>
  (dispatch: AppDispatch) => {
    if (loader) {
      dispatch(setPatientLoaderAction(true));
    }

    axios
      .post(`${BASE_URL}/patient/search`, {
        q: searchInput,
        offset: (page - 1) * rows,
        limit: rows,
        doctor_id: doctor
          ? doctor === "unassigned"
            ? null
            : Number(doctor.doctor_id)
          : null,
        unassigned: doctor === "unassigned",
        campaigns: campaigns,
        status: statuses,
        insurances: insurances,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to load patients",
          );
        } else {
          const patientsData = response.data.data;

          handleValidation(patientsDataSchema, patientsData, "searchPatients");

          if (patientsData) {
            dispatch(setPatientsAction(patientsData.patients));
            dispatch(setNumOfFilteredPatientsAction(patientsData.total));

            const doctorNumbers: FixLater = {};
            patientsData.total_per_doctor.forEach((doctor) => {
              if (doctor.doctor_id === null) {
                doctorNumbers.unassigned = doctor.total_patients;
              }
              doctorNumbers[doctor.doctor_id] = doctor.total_patients;
            });
            doctorNumbers.all = patientsData.total_clinic;

            dispatch(setNumOfPatientsByDoctorIdAction(doctorNumbers));
            onSuccess();
          }
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to load patients");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const createPatient =
  (
    patientData,
    page,
    rows,
    searchValue,
    doctor,
    onSuccess = () => {},
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/create`, patientData)
      .then((response) => {
        if (response.data.error) {
          if (response.data.error.message.includes("Patient already exists")) {
            console.error(response.data.error);
            dispatch(setPatientExistsErrorAction(true));
          } else {
            handleRequestError(
              response.data.error,
              dispatch,
              "Failed to create patient",
            );
          }
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully added patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
          onSuccess();
        }
        dispatch(
          searchPatients(
            searchValue,
            page,
            rows,
            doctor,
            true,
            () => {},
            selectedPatientCampaigns,
            selectedPatientStatuses,
          ),
        );
        dispatch(getNumOfPatients(doctor));
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to create patient");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const updatePatient =
  (
    patientData,
    page,
    rows,
    searchValue,
    doctor,
    onSuccess = () => {},
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/update`, patientData)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update patient",
          );
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update patient");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const removePatients =
  (
    patientIds,
    page,
    rows,
    searchValue,
    doctor,
    onSuccess = () => {},
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/remove`, { ids: patientIds })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete patients",
          );
        } else {
          onSuccess();
          dispatch(getNumOfPatients(doctor));
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully deleted patients",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete patients");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const importPatients =
  (
    file,
    page,
    rows,
    searchValue,
    doctor,
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(
        `${BASE_URL}/patient/import-csv`,
        { csv: file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to import patients",
          );
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(getNumOfPatients(doctor));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully imported patients",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to import patients");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const pausePatient =
  (
    patientId: number,
    page: number,
    rows: number,
    searchValue: string,
    doctor: FixLater,
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/pause/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to pause patient",
          );
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully paused patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to pause patient");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const unpausePatient =
  (
    patientId: number,
    page: number,
    rows: number,
    searchValue: string,
    doctor: FixLater,
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/unpause/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to unpause patient",
          );
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully unpaused patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to unpause patient");
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const getPatientInfo =
  (patientId: number, onSuccess = (_data: Patient) => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .get(`${BASE_URL}/patient/general/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to get patient info",
          );
        } else {
          const patientInfo = response.data.data.patient;

          handleValidation(patientSchema, patientInfo, "getPatientInfo");

          dispatch(setSelectedPatientInfoAction(patientInfo));
          onSuccess(patientInfo);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to get patient info");
      })
      .finally(() => {
        dispatch(setProfileLoadingAction(false));
      });
  };

export const getPatientEnrollments =
  (patientId: number) => (dispatch: AppDispatch) => {
    axios
      .get(`${BASE_URL}/patient/enrollments/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to get patient enrollments",
          );
        } else {
          const enrollments = response.data.data.enrollments;

          handleValidation(
            enrollmentsSchema,
            enrollments,
            "getPatientEnrollments",
          );

          dispatch(setSelectedPatientEnrollmentsAction(enrollments));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to get patient enrollments",
        );
      })
      .finally(() => {
        dispatch(setProfileLoadingAction(false));
      });
  };

export const generateICD10Codes = (noteId) => (dispatch: AppDispatch) => {
  axios
    .post(`${BASE_URL}/voice/${noteId}/generate-codes`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to generate codes",
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to generate codes");
    });
};

export const getPatientScribes = (patientId) => (dispatch: AppDispatch) => {
  axios
    .get(`${BASE_URL}/patient/notes/${patientId}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to get patient notes",
        );
      } else {
        if (!response.data.data) {
          console.error("No data returned from getPatientScribes", response);
        }

        const scribes = response.data.data.notes;

        handleValidation(patientScribesSchema, scribes, "getPatientScribes");

        dispatch(
          setScribes({
            scribes: response.data.data.notes,
            nextCursor: -1,
            isPolling: false,
          }),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to get patient notes");
    })
    .finally(() => {
      dispatch(setProfileLoadingAction(false));
      dispatch(setPatientNotesLoadingAction(false));
    });
};

export const enrollPatientWithAi =
  (
    patientId,
    campaignId,
    conditions,
    goals,
    medicalHistory,
    doctorId,
    aiChat,
    consent = true,
    onSuccess = () => {},
    updateMessage = false,
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/patient/enroll/${patientId}`, {
        campaign_id: campaignId,
        conditions: conditions,
        goals: goals,
        past_medical_history: medicalHistory,
        doctor_id: doctorId,
        ai_chat: aiChat,
        consent: consent,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to enroll patient",
          );
        } else {
          onSuccess();
          if (updateMessage) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Successfully updated patient enrollment",
                desc: "Campaign has been successfully updated for selected patient",
              }),
            );
          } else {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Successfully enrolled patient",
                desc: "Campaign has been successfully scheduled for selected patient",
              }),
            );
          }
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to enroll patient");
      });
  };
