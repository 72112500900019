import { forwardRef, useEffect, useRef } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-box.svg";

const IndeterminateCheckbox = forwardRef(
  (
    {
      indeterminate = false,
      checked = false,
      onChange = () => {},
      ...rest
    }: {
      indeterminate?: boolean;
      checked?: boolean;
      onChange?: () => void;
    },
    ref: React.MutableRefObject<HTMLInputElement>,
  ) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <label>
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          ref={resolvedRef}
          onChange={onChange}
          {...rest}
        />
        <div
          className={`w-5 h-5 rounded border flex items-center justify-center cursor-pointer
            ${checked || indeterminate ? "border-primary-blue text-white bg-primary-blue" : "border-gray-foreground bg-white"}`}
        >
          {checked && <CheckIcon width="10" height="8" stroke="white" />}
          {indeterminate && "-"}
        </div>
      </label>
    );
  },
);

export default IndeterminateCheckbox;
