import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  type CallCategory,
  type CallDirection,
  CallStatusTab,
} from "../../../store/call/interfaces";

export interface FilterParams {
  search?: string;
  direction?: CallDirection | "all";
  status?: CallStatusTab;
  reasons?: CallCategory[];
  isNewUser?: boolean;
  isUrgent?: boolean;
  page?: number;
}

const defaultFilters: FilterParams = {
  search: "",
  direction: "all",
  status: CallStatusTab.Pending,
  reasons: [],
  isNewUser: false,
  isUrgent: false,
  page: 1,
};

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(() => {
    const getParam = (key: string) => searchParams.get(key);

    // Helper function to check if a param exists
    const hasParam = (key: string) => searchParams.has(key);

    return {
      search: hasParam("search") ? getParam("search") : defaultFilters.search,
      direction: hasParam("direction")
        ? (getParam("direction") as CallDirection | "all")
        : defaultFilters.direction,
      status: hasParam("status")
        ? (getParam("status") as CallStatusTab)
        : defaultFilters.status,
      reasons: hasParam("reasons")
        ? (getParam("reasons")?.split(",").filter(Boolean) as CallCategory[])
        : defaultFilters.reasons,
      isNewUser: hasParam("isNewUser")
        ? getParam("isNewUser") === "true"
        : defaultFilters.isNewUser,
      isUrgent: hasParam("isUrgent")
        ? getParam("isUrgent") === "true"
        : defaultFilters.isUrgent,
      page: hasParam("page") ? Number(getParam("page")) : defaultFilters.page,
    };
  }, [searchParams]);

  const updateFilters = useCallback(
    (newFilters: Partial<FilterParams>) => {
      const current = filters;
      const updated = { ...current, ...newFilters };
      const params = new URLSearchParams();

      // Only add params that differ from defaults
      Object.entries(updated).forEach(([key, value]) => {
        const defaultValue = defaultFilters[key as keyof FilterParams];

        if (key === "reasons" && Array.isArray(value) && value.length > 0) {
          params.set(key, value.join(","));
        } else if (
          value !== defaultValue &&
          value !== undefined &&
          value !== ""
        ) {
          params.set(key, String(value));
        }
      });

      setSearchParams(params, { replace: true });
    },
    [filters, setSearchParams],
  );

  const resetFilters = useCallback(() => {
    setSearchParams({}, { replace: true });
  }, [setSearchParams]);

  return {
    filters,
    updateFilters,
    resetFilters,
    defaultFilters,
  };
};
