import { type ChangeEvent, useCallback } from "react";
import { ReactComponent as ChatCompletedIcon } from "../../../assets/icons/chat-completed.svg";
import { ReactComponent as ChatOpenIcon } from "../../../assets/icons/chat-open.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-new-filled.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { selectInputText } from "../../../helpers/helpers";
import { useAppSelector } from "../../../store";
import { CallStatusTab } from "../../../store/call/interfaces";
import { selectUser } from "../../../store/user/userReducer";
import TabsBg from "../../Basic/TabsBg";
import { useCallManagement } from "../hooks/useCallManagement";
import CallFilterMobile from "./CallFilterMobile";
import MentionsDropdown from "./MentionsDropdown";
import NumberCard from "./NumberCard";

const CallsTableHeader = () => {
  const user = useAppSelector(selectUser);
  const {
    callCounts = {
      total_completed: 0,
      total_reviews: 0,
      hours_saved: 0,
      incoming_calls: 0,
      me: 0,
      teams: null,
    },
    reviewCalls,
    filters,
    updateFilters,
  } = useCallManagement();

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    updateFilters({ search: e.target.value });
  };

  const onChangeCallStatusTab = useCallback(
    (tab: CallStatusTab) => {
      if (filters.status !== tab) {
        updateFilters({
          status: tab,
          page: 1,
        });
      }
    },
    [filters.status, updateFilters],
  );

  return (
    <div className="overflow-hidden">
      <div className="flex items-center justify-between space-x-3 mb-4 md:mb-6">
        <p className="font-semibold text-lg truncate">
          {user.customer.display_name || user.customer.name}
        </p>
        <MentionsDropdown />
      </div>

      <div className="flex items-center space-x-3 w-full mb-3 md:mb-4">
        <NumberCard
          icon={<PhoneIcon width="24" height="24" />}
          number={callCounts.incoming_calls}
          title="Incoming Calls"
        />
        <NumberCard
          icon={
            filters.status === CallStatusTab.Completed ? (
              <ChatCompletedIcon width="24" height="24" />
            ) : (
              <ChatOpenIcon width="24" height="24" />
            )
          }
          number={
            filters.status === CallStatusTab.Completed
              ? callCounts.total_completed
              : callCounts.total_reviews
          }
          title={
            filters.status === CallStatusTab.Completed
              ? "Completed Threads"
              : "Open Threads"
          }
        />
      </div>

      <div className="flex flex-col md:flex-row items-center overflow-x-auto scrollbar min-w-0">
        <TabsBg
          tabs={Object.values(CallStatusTab)}
          tabNumbers={
            reviewCalls?.total === undefined
              ? {}
              : {
                  [CallStatusTab.Pending]: reviewCalls?.total,
                }
          }
          activeTab={filters.status}
          onChange={(tab: CallStatusTab) => onChangeCallStatusTab(tab)}
          className="w-full md:w-fit bg-white text-sm mb-4 md:mb-0 md:mr-6"
          tabClassName="py-2 px-6 text-center capitalize"
        />

        <div className="w-full flex items-center space-x-2">
          <div className="relative w-full md:w-1/2 md:min-w-60 md:max-w-96 ml-auto">
            <input
              value={filters.search}
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder="Search..."
              aria-label="Search calls"
              role="searchbox"
            />
            <SearchIcon
              aria-hidden="true"
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>
          <CallFilterMobile className="md:hidden" />
        </div>
      </div>
    </div>
  );
};

export default CallsTableHeader;
