import { isSameDay } from "../../../helpers/helpers";
import type { Conversation } from "../../../store/call/interfaces";
import type { Message } from "../../../store/conversation/interfaces";

interface MessageDisplayProperties {
  combineWithPrevious: boolean;
  dateDivider: boolean;
}

/**
 * Determines display properties for a message in the conversation
 * @param currentMessage - The current message being processed
 * @param index - The index of the current message in the conversation
 * @param conversation - The full conversation object containing all messages
 * @returns Object containing display properties (combineWithPrevious and dateDivider)
 */
export const getMessageDisplayProperties = (
  currentMessage: Message,
  index: number,
  conversation: Conversation,
): MessageDisplayProperties => {
  // Default properties if this is the first message
  if (index === 0) {
    return {
      combineWithPrevious: false,
      dateDivider: true,
    };
  }

  const previousMessage = conversation.messages[index - 1];

  // Check if dates are the same
  const sameDay = isSameDay(currentMessage.send_at, previousMessage.send_at);

  const isDoctor = currentMessage.from === "doctor";
  const isDoctorPrevious = previousMessage.from === "doctor";
  const isAutomatic = currentMessage.is_automatic === 1;
  const isAutomaticPrevious = previousMessage.is_automatic === 1;

  // Determine if messages should be combined
  let combineWithPrevious = false;

  if (sameDay) {
    if (isDoctor && isDoctorPrevious) {
      // Both messages are from doctors
      if (isAutomatic && isAutomaticPrevious) {
        // Both are automatic messages
        combineWithPrevious = true;
      } else if (!isAutomatic && !isAutomaticPrevious) {
        // Both are manual messages from doctors
        combineWithPrevious =
          currentMessage.sender.doctor_id === previousMessage.sender.doctor_id;
      }
    } else if (!isDoctor && !isDoctorPrevious) {
      // Both messages are from the patient
      combineWithPrevious = true;
    }
  }

  return {
    combineWithPrevious,
    dateDivider: !sameDay,
  };
};

/**
 * Example usage:
 *
 * const { combineWithPrevious, dateDivider } = getMessageDisplayProperties(
 *   message,
 *   index,
 *   conversation
 * );
 *
 * if (dateDivider) {
 *   // Show date divider
 * }
 *
 * // Use combineWithPrevious in MessageCloud component
 * <MessageCloud
 *   combineWithPrevious={combineWithPrevious}
 *   ...other props
 * />
 */
