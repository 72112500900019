import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../store";
import { useGetConversationQuery } from "../../../store/call/api";
import { getSelectedConversationId } from "../../../store/call/callSlice";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import MessagingChat from "./MessagingChat";
import MessagingInputArea from "./MessagingInputArea";
import TopBar from "./TopBar";

interface ConversationProps {
  messages: FixLater; //Message[];
  setMessages: FixLater; //(messages: Message[]) => void;
  scrolledUp: boolean;
  setScrolledUp: (scrolledUp: boolean) => void;
}

const Conversation = ({
  messages,
  setMessages,
  scrolledUp,
  setScrolledUp,
}: ConversationProps) => {
  const [showResponse, setShowResponse] = useState(false);
  const selectedConversationId = useAppSelector(getSelectedConversationId);

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ?? skipToken,
  );

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  return (
    <div className="h-full w-full bg-gray-background grid grid-rows-chat-layout">
      <div
        className={`${isDesktop && isHeightSm ? "pt-4 px-4" : "md:pl-4 md:pt-2 md:pr-2"}`}
      >
        <TopBar />
      </div>
      {currentConversation ? (
        <MessagingChat
          mentionItems={[]}
          scrolledUp={scrolledUp}
          setScrolledUp={setScrolledUp}
          showResponse={showResponse}
          currentConversation={currentConversation}
          selectedConversationId={selectedConversationId}
        />
      ) : (
        <ChatSkeleton className="px-4" />
      )}
      <MessagingInputArea
        setScrolledUp={setScrolledUp}
        messages={messages}
        setMessages={setMessages}
        showResponse={showResponse}
        setShowResponse={setShowResponse}
        currentConversation={currentConversation}
        selectedConversationId={selectedConversationId}
        className={isDesktop && !isHeightSm ? "ml-4 mr-2 mb-2 border" : ""}
        completeClassName="bg-white md:bg-transparent"
      />
    </div>
  );
};

export default Conversation;
