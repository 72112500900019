import { z } from "zod";
import {
  CallCategory,
  CallDirection,
  CallStatus,
  InboxItemType,
} from "./interfaces";

export const callStatusSchema = z.nativeEnum(CallStatus);

const callReasonSchema = z.nativeEnum(CallCategory);

const callDirectionSchema = z.nativeEnum(CallDirection);

const inboxItemTypeSchema = z.nativeEnum(InboxItemType);

export const callSchema = z.object({
  assistant_call_id: z.number(),
  date: z.string(),
  direction: z.union([callDirectionSchema.nullable(), z.literal("")]),
  is_new_patient: z.boolean(),
  is_urgent: z.boolean(),
  reasons: z.array(callReasonSchema),
  status: callStatusSchema,
  summary: z.string().nullable(),
  time: z.number().min(0),
});

export const callCountsSchema = z.object({
  hours_saved: z.number().min(0),
  incoming_calls: z.number().min(0),
  me: z.number().min(0),
  teams: z.record(z.coerce.number(), z.number()).nullish(),
  total_completed: z.number().min(0),
  total_reviews: z.number().min(0),
});

export const callTotalSchema = z.number().min(0);

export const callNoteSchema = z.object({
  assistant_call_id: z.number(),
  assistant_call_note_id: z.number(),
  created_at: z.string(),
  created_by: z.number(),
  note: z.string(),
  updated_at: z.string(),
  updated_by: z.number(),
});

export const inboxPatientSchema = z.object({
  conversation_id: z.number(),
  patient_birthdate: z.string(),
  patient_full_name: z.string(),
  patient_gender: z.string().nullable(),
  patient_id: z.number(),
  patient_npi: z.number().nullable(),
  patient_phone_number: z.string().nullable(),
  patient_preferred_name: z.string().nullable(),
  patient_pronouns: z.string().nullable(),
  patient_sex: z.string(),
});
// .strict();

export const inboxItemSchema = z.object({
  data: callSchema,
  total: z.number().min(1),
  type: inboxItemTypeSchema,
});
// .strict();

export const inboxListSchema = z.array(
  z.object({
    patient: inboxPatientSchema,
    items: z.array(inboxItemSchema),
  }),
  // .strict(),
);
