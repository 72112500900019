import type { ReactNode } from "react";

interface TabsBgProps {
  tabs: string[];
  tabNumbers?: Record<string, number>;
  icons?: Record<string, ReactNode>;
  withoutLabel?: boolean;
  activeTab: string;
  onChange: (tab: string) => void;
  className?: string;
  tabClassName?: string;
  activeBgColor?: string;
  activeTextColor?: string;
}

const TabsBg = ({
  tabs,
  tabNumbers = null,
  icons = null,
  withoutLabel = false,
  activeTab,
  onChange,
  className = "bg-gray-150 text-xs",
  tabClassName = "",
  activeBgColor = "primary-blue",
  activeTextColor = "white",
}: TabsBgProps) => {
  const tabWidth = 100 / tabs.length;
  const activeTabIndex = tabs.indexOf(activeTab);
  const translateX =
    activeTabIndex === tabs.length - 1
      ? 100 * activeTabIndex - 5
      : 100 * activeTabIndex;

  const getTab = (tab: string) => {
    return (
      <li
        key={tab}
        onClick={() => onChange(tab)}
        style={{
          width: `${tabWidth}%`,
        }}
        className={`cursor-pointer font-medium rounded-full py-1 px-2
          flex items-center justify-center z-[1] transition-all space-x-2
          ${tabClassName}
          ${activeTab === tab ? `text-${activeTextColor}` : "text-zinc-500"}
        `}
      >
        {icons?.[tab]}
        {!withoutLabel && <p>{tab}</p>}
        {tabNumbers &&
          Object.prototype.hasOwnProperty.call(tabNumbers, tab) && (
            <div
              className={`rounded-full text-xs p-0.5 min-w-5 ${activeTab === tab ? "bg-white/10" : "bg-black/10"}`}
            >
              {tabNumbers[tab]}
            </div>
          )}
      </li>
    );
  };

  return (
    <ul className={`flex rounded-full p-0.5 relative ${className}`}>
      <div
        aria-hidden="true"
        style={{
          transform: `translateX(${translateX}%) translateY(-50%)`,
          width: `${tabWidth}%`,
        }}
        className={`absolute h-[80%] rounded-full z-0 left-0.5 top-1/2 transition-all bg-${activeBgColor}`}
      />
      {tabs.map((tab: string) => getTab(tab))}
    </ul>
  );
};

export default TabsBg;
