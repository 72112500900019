import { ReactComponent as MutedIcon } from "../../../assets/icons/muted.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-down.svg";
import { ReactComponent as UnmutedIcon } from "../../../assets/icons/unmuted.svg";
import { twilioDeviceStatuses } from "../../../helpers/constants";
import { getFormattedTimeWithSeconds } from "../../../helpers/helpers";
import { type RootState, useAppSelector } from "../../../store";
import LoaderDotsSmall from "../../Basic/LoaderDotsSmall";

interface CallControlButtonsProps {
  onClose: () => void;
  handleMute: () => void;
  isMuted: boolean;
  callSeconds: number;
}

const CallControlButtons = ({
  onClose,
  handleMute,
  isMuted,
  callSeconds,
}: CallControlButtonsProps) => {
  const { deviceStatus } = useAppSelector(
    (state: RootState) => state.voiceRecorder,
  );
  const iconSize = 24;

  return (
    <div className="w-full bg-white md:bg-slate-650 py-3 px-4 md:p-3 md:rounded-lg">
      <div className="w-full bg-slate-650 md:bg-white/5 rounded-full md:rounded-lg p-1 md:py-4 md:px-5 flex items-center">
        <button
          type="button"
          className="w-12 h-12 rounded-full md:bg-white/5 flex flex-none items-center justify-center"
          onClick={handleMute}
          aria-label={isMuted ? "Unmute call" : "Mute call"}
          aria-pressed={isMuted}
        >
          {isMuted ? (
            <MutedIcon
              width={iconSize}
              height={iconSize}
              className="flex-none"
              stroke="white"
            />
          ) : (
            <UnmutedIcon
              width={iconSize}
              height={iconSize}
              className="flex-none"
              stroke="white"
            />
          )}
        </button>

        <div className="ml-auto flex items-center space-x-2">
          <div className="text-white font-semibold text-base">
            {deviceStatus === twilioDeviceStatuses.READY ? (
              <div className="flex items-end space-x-2">
                <p>Calling</p>
                <LoaderDotsSmall color="white" size="1" className="mb-2" />
              </div>
            ) : deviceStatus === twilioDeviceStatuses.IN_PROGRESS ? (
              <p className="md:text-xl">
                {getFormattedTimeWithSeconds(callSeconds)}
              </p>
            ) : (
              deviceStatus
            )}
          </div>
          <button
            type="button"
            className="w-12 h-12 md:bg-white/5 flex flex-none rounded-full items-center justify-center"
            onClick={onClose}
            aria-label="End call"
          >
            <PhoneIcon width={iconSize} height={iconSize} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallControlButtons;
