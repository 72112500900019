import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";
import { ReactComponent as CardSendIcon } from "../../../assets/icons/card-send.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-note.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/menu-dots.svg";
import { ReactComponent as RestartIcon } from "../../../assets/icons/restart-square.svg";
import { copyText } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { saveEncounterNote } from "../../../store/conversation/thunks";
import { setNotificationAction } from "../../../store/user/actions";
import TemplatePreview from "../../Basic/TemplatePreview";
import TextEditor from "../../Basic/TextEditor";
import type { Scribe } from "../../IndependentScribe/store/interfaces";
import { selectSelectedNoteTemplate } from "../../IndependentScribe/store/noteTemplateSlice";
import { getSelectedScribeNote } from "../../IndependentScribe/store/thunks";

const buttonClasses =
  "flex items-center gap-3 bg-gray-foreground text-primary whitespace-nowrap h-10 lg:h-11 text-sm font-semibold lg:text-base";

const optionButtonClasses =
  "px-2.5 py-2 flex items-center space-x-2.5 rounded-lg text-tertiary group enabled:hover:text-primary hover:bg-gray-background disabled:bg-gray-background";

const NoteInfo = ({
  scribe,
  onSaveNote,
  onResumeRecording,
}: {
  scribe: Scribe;
  onSaveNote: () => void;
  onResumeRecording: () => void;
}) => {
  const selectedNoteTemplate = useAppSelector(selectSelectedNoteTemplate);
  const [editedNote, setEditedNote] = useState(scribe?.note);
  const [openOptions, setOpenOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();

  const isRecordingOrPaused = scribe.isRecording || scribe.isPaused;
  const isRecordingOrPausedNotGenerated =
    (scribe.isRecording || scribe.isPaused) && !scribe.note;

  const handleCopyNote = () => {
    copyText(scribe.note, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  const handleSaveNote = () => {
    dispatch(saveEncounterNote(scribe.audioId, editedNote, onSaveNote));
  };

  const onCloseOptions = () => {
    setOpenOptions(false);
    setAnchorEl(null);
  };

  const onOpenOptions = (e) => {
    setOpenOptions(true);
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (scribe.note) {
      return;
    }

    dispatch(getSelectedScribeNote());
  }, [dispatch, scribe.note]);

  return (
    <div className="w-full grid grid-rows-tab-layout md:block h-full">
      {!isRecordingOrPausedNotGenerated ? (
        <div className="h-full bg-white px-4 md:rounded-b-xl md:border-x md:border-b pt-4 md:pt-0">
          {scribe.note ? (
            <div className="w-full h-full grid grid-rows-note-layout gap-4">
              <TextEditor
                htmlMarkup={scribe.note}
                onChange={(htmlMarkup) => setEditedNote(htmlMarkup)}
                onBlur={handleSaveNote}
                className="h-full min-h-fit max-h-full border rounded-xl"
                rerenderDeps={[scribe.note]}
                disabled={isRecordingOrPaused}
              />

              {!isRecordingOrPaused && (
                <div className="flex divide-x divide-gray-300 pb-4 ml-auto">
                  <button
                    type="button"
                    className={`${buttonClasses} justify-center rounded-l-lg px-4`}
                    onClick={handleCopyNote}
                  >
                    <CopyIcon width="20" height="20" />
                    <p>Copy Note</p>
                  </button>
                  <button
                    type="button"
                    className={`${buttonClasses} rounded-r-lg px-3`}
                    onClick={onOpenOptions}
                  >
                    <MoreIcon width="20" height="20" />
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center p-3">
              <p>Note is being generated</p>
            </div>
          )}
        </div>
      ) : (
        isRecordingOrPaused &&
        (selectedNoteTemplate?.sections ? (
          <TemplatePreview
            sections={selectedNoteTemplate.sections}
            className="h-full overflow-y-auto px-4 pb-4 scrollbar"
          />
        ) : (
          <p className="px-7">
            {scribe.isRecording ? "Recording..." : "Paused"}
          </p>
        ))
      )}

      {openOptions && anchorEl && (
        <Popover
          open={openOptions}
          onClose={onCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: -8,
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: "200px",
                boxShadow:
                  "0px -4px 20px 0px rgba(0, 0, 0, 0.02), 0px 32px 64px -12px rgba(16, 24, 40, 0.08)",
              },
            },
          }}
        >
          <div className="w-full flex flex-col space-y-1 p-2 text-sm font-medium">
            <button
              type="button"
              className={optionButtonClasses}
              onClick={() => {
                onCloseOptions();
                onResumeRecording();
              }}
            >
              <RestartIcon
                width="20"
                height="20"
                className="opacity-60 enabled:group-hover:opacity-100"
                stroke="#667085"
              />
              <span>Resume Recording</span>
            </button>

            <button
              type="button"
              className={optionButtonClasses}
              // onClick={handleSendToEhr}
              disabled
            >
              <CardSendIcon
                width="20"
                height="20"
                className="opacity-60 enabled:group-hover:opacity-100"
              />
              <span>Send to EHR</span>
            </button>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default NoteInfo;
