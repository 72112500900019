import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import automaticProfileSrc from "../../../assets/quadrant-ai.svg";
import { Pages, convPerPage } from "../../../helpers/constants";
import { getDateDivider, isSameDay, isToday } from "../../../helpers/helpers";
import { useActivityTracker } from "../../../hooks/useActivityTracker";
import { useAppDispatch, useAppSelector } from "../../../store";
import { openConversationEvent } from "../../../store/billingMetrics/thunks";
import { useSendMessageMutation } from "../../../store/call/api";
import { removeFailedMessageAction } from "../../../store/conversation/actions";
import { MessageType } from "../../../store/conversation/interfaces";
import { searchConversationsWithFilters } from "../../../store/conversation/thunks";
import { selectUser } from "../../../store/user/userReducer";
import type { MentionItem } from "../../Inbox/Mentions/interfaces";
import CallTimelineCard from "../../Inbox/RightPanel/CallCard/CallTimelineCard";
import MessageCloud from "../../Inbox/RightPanel/MessageCloud";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";

interface MessagingChatProps {
  scrolledUp: boolean;
  setScrolledUp: (scrolledUp: boolean) => void;
  showResponse: boolean;
  currentConversation: FixLater; //Conversation;
  selectedConversationId: number;
  mentionItems: MentionItem[];
}

const MessagingChat = ({
  scrolledUp,
  setScrolledUp,
  showResponse,
  currentConversation,
  selectedConversationId,
  mentionItems,
}: MessagingChatProps) => {
  const [conversationChanged, setConversationChanged] = useState(true);
  const [dateIndicator, setDateIndicator] = useState("");
  const [showDateIndicator, setShowDateIndicator] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [failedMessageCurrent, setFailedMessageCurrent] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [conversationMessagesLength, setConversationMessagesLength] = useState(
    {},
  );
  const [
    currentConversationLengthChanged,
    setCurrentConversationLengthChanged,
  ] = useState(false);
  const {
    conversationSearchInput,
    activeInbox,
    activeFilters,
    sentMessages,
    failedMessages,
    convCurrPage,
    conversationsUpdated,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const { loader } = useAppSelector((state) => state.patient);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const messageContainer = useRef(null);
  const dateIndicatorRef = useRef(null);
  const dateRefs = useRef([]);
  const scrolledUpRef = useRef(scrolledUp);
  const conversationSearchInputRef = useRef(conversationSearchInput);
  const conversationMessagesLengthRef = useRef(conversationMessagesLength);
  const convCurrPageRef = useRef(convCurrPage);
  const activeFiltersRef = useRef(activeFilters);

  const [sendMessage] = useSendMessageMutation();

  const selectedConversationIdMemoized = useMemo(() => {
    setConversationChanged(true);
    return selectedConversationId;
  }, [selectedConversationId]);

  const patientPicture = useMemo(
    () =>
      currentConversation ? currentConversation.patient.profile_picture : "",
    [currentConversation],
  );

  const doTracking = useMemo(
    () =>
      currentConversation && window.location.pathname === Pages.MESSAGING
        ? currentConversation.trackable
        : false,
    [currentConversation],
  );

  const { restartTimer } = useActivityTracker(doTracking);

  useEffect(() => {
    scrolledUpRef.current = scrolledUp;
  }, [scrolledUp]);

  useEffect(() => {
    conversationSearchInputRef.current = conversationSearchInput;
  }, [conversationSearchInput]);

  useEffect(() => {
    conversationMessagesLengthRef.current = conversationMessagesLength;
  }, [conversationMessagesLength]);

  useEffect(() => {
    convCurrPageRef.current = convCurrPage;
  }, [convCurrPage]);

  useEffect(() => {
    activeFiltersRef.current = activeFilters;
  }, [activeFilters]);

  const scrollChat = useCallback(() => {
    if (
      messageContainer.current?.scrollTop /
        (messageContainer.current?.scrollHeight -
          messageContainer.current?.clientHeight) <
      0.99
    ) {
      setScrolledUp(true);
    } else {
      setScrolledUp(false);
      setUnreadMessages(0);
    }
  }, [setScrolledUp]);

  const scrollToBottom = useCallback((smooth) => {
    messageContainer.current?.scrollTo({
      top: messageContainer.current?.scrollHeight,
      left: 0,
      behavior: smooth ? "smooth" : "auto",
    });
    setUnreadMessages(0);
  }, []);

  useEffect(() => {
    if (scrolledUpRef.current && !conversationsUpdated) {
      setUnreadMessages((prev) => prev + 1);
    }
  }, [conversationsUpdated]);

  useEffect(() => {
    if (showResponse && !scrolledUpRef.current) {
      scrollToBottom(true);
    }
  }, [showResponse, scrollToBottom]);

  useEffect(() => {
    if (
      sentMessages[selectedConversationIdMemoized] &&
      sentMessages[selectedConversationIdMemoized].length > 0
    ) {
      scrollToBottom(true);
    }
  }, [sentMessages, scrollToBottom, selectedConversationIdMemoized]);

  useEffect(() => {
    if (failedMessageCurrent.length > 0) {
      scrollToBottom(false);
    }
  }, [failedMessageCurrent, scrollToBottom]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", scrollChat);

    return () =>
      messageContainer.current?.removeEventListener("scroll", scrollChat);
  }, [scrollChat]);

  useEffect(() => {
    if (currentConversation) {
      setCurrentConversationLengthChanged(
        currentConversation.messages.length >
          conversationMessagesLengthRef.current[selectedConversationIdMemoized],
      );

      setConversationMessagesLength((prev) => ({
        ...prev,
        [selectedConversationIdMemoized]: currentConversation.messages.length,
      }));

      if (currentConversation.messages.length > 0) {
        setLastMessage(
          currentConversation.messages[currentConversation.messages.length - 1],
        );
      }

      dispatch(
        searchConversationsWithFilters(
          conversationSearchInputRef.current,
          activeFiltersRef.current,
          1,
          convPerPage * convCurrPageRef.current,
          activeInbox,
          selectedDoctorInbox,
        ),
      );

      if (conversationChanged) {
        scrollToBottom(false);

        (async () => {
          if (doTracking) {
            restartTimer();
            await openConversationEvent(
              currentConversation.patient.patient_id,
              currentConversation.campaign_type,
            );
          }
        })();
        setConversationChanged(false);
      } else {
        if (!scrolledUpRef.current) {
          scrollToBottom(true);
        }
      }
    }
  }, [
    dispatch,
    currentConversation,
    scrollToBottom,
    restartTimer,
    selectedConversationIdMemoized,
    conversationChanged,
    activeInbox,
    doTracking,
    selectedDoctorInbox,
  ]);

  useEffect(() => {
    setFailedMessageCurrent(
      failedMessages[selectedConversationIdMemoized] || [],
    );
  }, [failedMessages, selectedConversationIdMemoized]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", updateDateIndicator);

    return () =>
      messageContainer.current?.removeEventListener(
        "scroll",
        updateDateIndicator,
      );
  }, []);

  let timeoutId: NodeJS.Timeout;
  const updateDateIndicator = () => {
    clearTimeout(timeoutId);
    if (messageContainer.current && messageContainer.current.scrollTop > 30) {
      setShowDateIndicator(true);
      timeoutId = setTimeout(() => {
        setShowDateIndicator(false);
      }, 4000);
    } else {
      setShowDateIndicator(false);
    }

    dateRefs.current.forEach((dateRef) => {
      if (dateRef && dateIndicatorRef.current) {
        if (
          dateIndicatorRef.current.getBoundingClientRect().top >=
          dateRef.getBoundingClientRect().top
        ) {
          setDateIndicator(dateRef.textContent);
        }
      }
    });
  };

  const removeFailedMessage = (messageId) => {
    dispatch(
      removeFailedMessageAction(messageId, selectedConversationIdMemoized),
    );
  };

  const resendFailedMessage = (messageId, message) => {
    removeFailedMessage(messageId);
    sendMessage({
      message,
      conversationId: selectedConversationIdMemoized,
      isInternal: false,
    });
  };

  const getMessageClouds = () => {
    return currentConversation.messages.map((message, index) => {
      if (message.type === MessageType.Call) {
        return (
          <CallTimelineCard
            key={index}
            call={message.data.call}
            patient={currentConversation.patient}
            className="mt-3"
          />
        );
      }

      if (message.type !== "text") {
        return null;
      }
      const isDoctor = message.from === "doctor";
      const isAutomatic = message.is_automatic === 1;
      const isOutgoing =
        isAutomatic ||
        (isDoctor && message.sender?.doctor_id === user?.doctor_id);

      let combineWithPrevious = false;
      let dateDivider = true;
      if (index > 0) {
        const previousMessage = currentConversation.messages[index - 1];
        const isDoctorPrevious = previousMessage.from === "doctor";
        const isAutomaticPrevious = previousMessage.is_automatic === 1;

        if (isSameDay(message.send_at, previousMessage.send_at)) {
          dateDivider = false;
        }

        if (
          (isDoctor &&
            isAutomatic &&
            isDoctorPrevious &&
            isAutomaticPrevious &&
            isSameDay(message.send_at, previousMessage.send_at)) ||
          (isDoctor &&
            !isAutomatic &&
            isDoctorPrevious &&
            !isAutomaticPrevious &&
            message.sender.doctor_id === previousMessage.sender.doctor_id &&
            isSameDay(message.send_at, previousMessage.send_at)) ||
          (!isDoctor &&
            !isDoctorPrevious &&
            isSameDay(message.send_at, previousMessage.send_at))
        ) {
          combineWithPrevious = true;
        }
      }

      return (
        <div key={index}>
          {dateDivider && (
            <div
              ref={(el) => {
                dateRefs.current[index] = el;
              }}
              className="mx-auto flex items-center justify-center bg-gray-200 rounded-full w-fit text-xxs
                px-2 py-1 mt-4"
            >
              {getDateDivider(
                new Date(`${message.send_at.replace(/-/g, "/")} GMT+0`),
              )}
            </div>
          )}
          <MessageCloud
            combineWithPrevious={combineWithPrevious}
            type={isOutgoing ? "outgoing" : "incoming"}
            firstName={
              isAutomatic
                ? "Quadrant"
                : message.sender.preferred_name
                  ? message.sender.preferred_name
                  : message.sender.first_name
            }
            lastName={isAutomatic ? "AI" : message.sender.last_name}
            displayName={isAutomatic ? "" : message.sender.display_name}
            profilePicture={
              isAutomatic
                ? automaticProfileSrc
                : isDoctor
                  ? message.sender.profile_picture
                  : patientPicture
            }
            message={message.message}
            time={message.send_at}
            mentionItems={mentionItems}
            isInternal={message.is_internal === 1}
          />
        </div>
      );
    });
  };

  return (
    <div className="overflow-hidden relative flex">
      <div
        ref={messageContainer}
        className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar relative h-full w-full"
      >
        {loader ? (
          <ChatSkeleton />
        ) : (
          <>
            <div className="absolute top-0 left-1/2 h-0 w-fit overflow-visible pl-1">
              <div
                ref={dateIndicatorRef}
                className={`fixed z-10 -translate-x-1/2 flex flex-none whitespace-nowrap items-center justify-center
                  shadow-md transition-all duration-500 bg-gray-200 rounded-full w-fit text-xxs px-2 py-1 mt-4
                  ${showDateIndicator ? "translate-y-0 opacity-1" : "-translate-y-4 opacity-0"}`}
              >
                {dateIndicator}
              </div>
            </div>

            {currentConversation &&
              currentConversation.messages.length > 0 &&
              getMessageClouds()}
            {sentMessages[selectedConversationIdMemoized] &&
              sentMessages[selectedConversationIdMemoized].length > 0 &&
              sentMessages[selectedConversationIdMemoized].map(
                (
                  message: {
                    message: string;
                    isInternal: boolean;
                  },
                  index: number,
                ) => {
                  let combineWithPrevious = false;
                  if (
                    lastMessage &&
                    lastMessage.from === "doctor" &&
                    lastMessage.is_automatic === 0 &&
                    lastMessage.sender.doctor_id === user.doctor_id &&
                    isToday(`${lastMessage.send_at.replace(/-/g, "/")} GMT+0`)
                  ) {
                    combineWithPrevious = true;
                  }
                  return (
                    <div key={index}>
                      <MessageCloud
                        combineWithPrevious={
                          index === 0 ? combineWithPrevious : true
                        }
                        type="outgoing"
                        firstName={user.first_name}
                        lastName={user.last_name}
                        displayName={user.display_name}
                        profilePicture={user.profile_picture}
                        message={message.message}
                        time=""
                      />
                    </div>
                  );
                },
              )}
            {failedMessageCurrent.length > 0 &&
              failedMessageCurrent.map(
                (
                  message: {
                    id: string;
                    message: string;
                  },
                  index: number,
                ) => {
                  let combineWithPrevious = false;
                  if (
                    (lastMessage.from === "doctor" &&
                      lastMessage.is_automatic === 0 &&
                      lastMessage.sender.doctor_id === user.doctor_id &&
                      isToday(
                        `${lastMessage.send_at.replace(/-/g, "/")} GMT+0`,
                      )) ||
                    (sentMessages[selectedConversationIdMemoized] &&
                      sentMessages[selectedConversationIdMemoized].length > 0)
                  ) {
                    combineWithPrevious = true;
                  }
                  return (
                    <div key={message.id}>
                      <MessageCloud
                        combineWithPrevious={
                          index === 0 ? combineWithPrevious : true
                        }
                        type="outgoing"
                        firstName={user.first_name}
                        lastName={user.last_name}
                        displayName={user.display_name}
                        profilePicture={user.profile_picture}
                        message={message.message}
                        time=""
                        failed
                        onDelete={() => removeFailedMessage(message.id)}
                        onResend={() =>
                          resendFailedMessage(message.id, message.message)
                        }
                      />
                    </div>
                  );
                },
              )}

            <div className="absolute bottom-8 left-1/2 h-0 w-fit overflow-visible">
              {unreadMessages > 0 &&
                scrolledUp &&
                currentConversationLengthChanged && (
                  <button
                    type="button"
                    className="fixed -translate-x-1/2 w-fit flex items-center justify-center text-xs text-white
              px-3 py-1 rounded-full bg-indigo-350"
                    onClick={() => scrollToBottom(true)}
                  >
                    View new messages
                  </button>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MessagingChat;
