import Bugsnag from "@bugsnag/js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getRecordingScribe, getSelectedScribe } from "../store/selectors";
import useAudioContext from "./useAudioContext";
import useAudioLevel from "./useAudioLevel";
import { useAudioStallDetector } from "./useAudioStallDetector";
import useIosAudioContextResume from "./useIosAudioContextResume";
import useMediaRecorderData from "./useMediaRecorderData";
import useMicrophone from "./useMicrophone";
import useNavigationGuard from "./useNavigationGuard";
import useRecordingControls from "./useRecordingControls";
import useRecordingReadyState from "./useRecordingReadyState";
import useRecordingStatusWatcher from "./useRecordingStatusWatcher";
import useWakeLock from "./useWakeLock";

const useRecorder = (
  sendAudioPart: (blob: Blob, chunkPosition: number) => void,
  onSave: () => void,
) => {
  const dispatch = useAppDispatch();

  const selectedScribe = useAppSelector(getSelectedScribe);
  const recordingScribe = useAppSelector(getRecordingScribe);

  const {
    microphones,
    microphoneId,
    permissionGranted,
    setMicrophoneId,
    requestMicrophonePermissions,
  } = useMicrophone();

  const { requestWakeLock, releaseWakeLock } = useWakeLock();

  const { audioContextRef, mediaRecorderRef, contextState, setupContext } =
    useAudioContext(microphoneId, permissionGranted);

  const { startRecording, togglePause, cancelRecording, saveRecording } =
    useRecordingControls(
      mediaRecorderRef,
      dispatch,
      selectedScribe,
      onSave,
      requestWakeLock,
      releaseWakeLock,
    );

  useIosAudioContextResume(audioContextRef);

  const { isStalled, reset } = useAudioStallDetector(setupContext, {
    stallThresholdFrames: 45, // Custom threshold
    checkIntervalMs: 20, // Custom interval
  });

  useEffect(() => {
    if (isStalled && recordingScribe) {
      Bugsnag.notify(
        new Error("Audio stall detected while recording, pausing"),
      );
      togglePause();
    }
  }, [isStalled, recordingScribe, togglePause]);

  useMediaRecorderData(mediaRecorderRef, sendAudioPart);

  useRecordingStatusWatcher(recordingScribe, mediaRecorderRef);

  useNavigationGuard(recordingScribe?.isRecording);

  const { isReady, errors } = useRecordingReadyState(
    permissionGranted,
    microphoneId,
    contextState,
    mediaRecorderRef,
  );

  return {
    isReadyToRecord: isReady,
    readyToRecordErrors: errors,
    mediaRecorder: mediaRecorderRef.current,
    audioContext: audioContextRef.current,
    microphones,
    microphoneId,
    setMicrophoneId,
    startRecording,
    togglePause,
    cancelRecording,
    saveRecording,
    requestMicrophonePermissions,
    isStalled,
    resetStalled: reset,
  };
};

export default useRecorder;
