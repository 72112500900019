import Popover from "@mui/material/Popover";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import { type RootState, useAppSelector } from "../../store";
import type { Site } from "../../store/site/siteReducer";
import Checkbox from "./Checkbox";

interface SiteMultipleSelectProps {
  className?: string;
  displaySelect?: boolean;
  selectedSites: Site[];
  setSelectedSites: (callback: (previous: Site[]) => Site[]) => void;
  error?: string;
  setError?: (error: string | null) => void;
}

const SiteMultipleSelect = ({
  className = "",
  displaySelect = false,
  selectedSites,
  setSelectedSites,
  error,
  setError = (_) => {},
}: SiteMultipleSelectProps) => {
  const { sites } = useAppSelector((state: RootState) => state.site);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [buttonWidth, setButtonWidth] = useState<number>(0);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (sites?.length > 0 && selectedSites?.length === 0) {
      setSelectedSites(() => [sites[0]]);
    }
  }, [sites, selectedSites?.length, setSelectedSites]);

  const handleResize = useCallback(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (displaySelect) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, displaySelect]);

  const onCloseDropdown = () => {
    setOpenDropdown(false);
    setAnchorEl(null);
  };

  return (
    <div
      className={`w-full flex flex-col space-y-2 ${className}`}
      data-testid="site-select"
    >
      {displaySelect && (
        <div className="w-full">
          <button
            ref={buttonRef}
            type="button"
            data-testid="site-select-button"
            className={`w-full px-3 h-10 bg-white rounded-lg border flex items-center justify-between
              text-left text-base md:text-sm ${error ? "border-red-400" : "border-gray-300"}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenDropdown(!openDropdown);
              setAnchorEl(e.currentTarget);
            }}
          >
            <p>{selectedSites.length} clinics selected</p>
            <ArrowIcon width="10" height="7" />
          </button>
          {error && (
            <p
              className="text-sm text-red-400 font-normal mt-1.5"
              data-testid="error-message"
            >
              {error}
            </p>
          )}

          {openDropdown && anchorEl && (
            <Popover
              open={openDropdown}
              onClose={onCloseDropdown}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              slotProps={{
                paper: {
                  sx: {
                    borderRadius: "8px",
                    width: buttonWidth,
                    boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                    margin: "8px 0 0 0",
                  },
                },
              }}
            >
              <div className="w-full flex flex-col space-y-1 px-4 py-2">
                {sites?.map((site) => (
                  <Checkbox
                    key={site.customer_id}
                    className="py-1.5 font-medium"
                    variant="primary-filled"
                    label={site.display_name}
                    checked={
                      !!selectedSites.find(
                        (selectedSite) =>
                          selectedSite.customer_id === site.customer_id,
                      )
                    }
                    onChange={() => {
                      setError(null);
                      setSelectedSites((prev: Site[]): Site[] =>
                        prev.find(
                          (selectedSite) =>
                            selectedSite.customer_id === site.customer_id,
                        )
                          ? prev.filter(
                              (selectedSite) =>
                                selectedSite.customer_id !== site.customer_id,
                            )
                          : [...prev, site],
                      );
                    }}
                    name={site.display_name}
                  />
                ))}
              </div>
            </Popover>
          )}
        </div>
      )}

      <div className="flex flex-wrap gap-2" data-testid="selected-sites">
        {selectedSites.map((site) => (
          <div
            key={site.customer_id}
            className="text-sm text-tertiary font-medium bg-gray-foreground py-1 px-2.5 rounded"
          >
            {site.display_name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SiteMultipleSelect;
