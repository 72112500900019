import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { baseApi } from "../../../../store/baseApi";
import { getSelectedConversationId } from "../../../../store/call/callSlice";
import { updateUser } from "../../../../store/user/thunks";
import type { User } from "../../../../store/user/userReducer";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";
import InputBlock from "../../../Basic/InputBlock";
import Modal from "../../../Basic/Modal";
import UserPictureName from "./UserPictureName";

interface ChangeEmailModalProps {
  onClose: () => void;
  user: User;
}

const ChangeEmailModal = ({ onClose, user }: ChangeEmailModalProps) => {
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: user.email,
      new_email: "",
    },
  });

  const onSubmit = (data) => {
    dispatch(
      updateUser(
        user.doctor_id,
        {
          first_name: user.first_name,
          last_name: user.last_name,
          occupation: user.occupation,
          display_name: user.display_name,
          phone_number: user.phone_number,
          email: data.new_email,
        },
        () => {
          dispatch(
            baseApi.util.invalidateTags([
              { type: "Conversation", id: selectedConversationId },
            ]),
          );
        },
      ),
    );

    onClose();
  };

  return (
    <Modal className="pt-5 rounded-2xl">
      <div className="w-114 overflow-hidden flex flex-col">
        <p className="text-base md:text-xl font-semibold mb-5 px-5">
          Change Email
        </p>

        <form
          id="change-email"
          onSubmit={handleSubmit(onSubmit)}
          className="px-5 space-y-5 overflow-x-hidden overflow-y-auto scrollbar flex flex-col min-h-0"
        >
          <UserPictureName user={user} />

          <InputBlock title="Current Email" titleClassName="text-sm mt-2.5">
            <Input
              className="col-span-2"
              register={register}
              name="email"
              placeholder="Current email"
              type="email"
              disabled
            />
          </InputBlock>

          <InputBlock title="New Email" titleClassName="text-sm mt-2.5">
            <Input
              className="col-span-2"
              register={register}
              name="new_email"
              placeholder="Enter new email"
              type="email"
              required="Email can’t be blank"
              withoutAsterisk
              error={errors.new_email}
              validationPattern={{
                value: /\S+@\S+\.\S+/,
                message: "Enter a valid email address",
              }}
            />
          </InputBlock>
        </form>

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button form="change-email" type="submit">
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeEmailModal;
