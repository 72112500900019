const switchSizes = {
  small: "w-7.5 h-4 after:h-3 after:w-3 peer-checked:after:translate-x-3.5",
  medium: "w-9 h-5 after:h-4 after:w-4 peer-checked:after:translate-x-full",
  "medium-large":
    "w-10 h-6 after:h-5 after:w-5 peer-checked:after:translate-x-4",
  large: "w-11 h-6 after:h-5 after:w-5 peer-checked:after:translate-x-full",
  big: "w-13.5 h-8 after:h-7 after:w-7 peer-checked:after:translate-x-5.5",
};

interface SwitchProps {
  enabled: boolean;
  onChange: () => void;
  size?: keyof typeof switchSizes;
  disabled?: boolean;
}

const Switch = ({
  enabled,
  onChange,
  size = "big",
  disabled = false,
}: SwitchProps) => {
  return (
    <label
      className={`inline-flex relative items-center ${disabled ? "cursor-default" : "cursor-pointer"}`}
    >
      <input
        type="checkbox"
        className="sr-only peer"
        checked={enabled}
        readOnly
      />
      <div
        onClick={() => {
          if (!disabled) {
            onChange();
          }
        }}
        className={`${switchSizes[size]} bg-zinc-300 rounded-full
            peer peer-checked:bg-primary-blue
            after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white
            after:rounded-full after:transition-all after:shadow after:shadow-gray-900/10`}
      />
    </label>
  );
};

export default Switch;
