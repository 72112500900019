import { forwardRef } from "react";
import { getDateDivider } from "../../../helpers/helpers";

interface DateDividerProps {
  date: string;
  className?: string;
}

const DateDivider = forwardRef<HTMLDivElement, DateDividerProps>(
  ({ date, className = "" }, ref) => {
    const formattedDate = getDateDivider(
      new Date(`${date.replace(/-/g, "/")} GMT+0`),
    );

    return (
      <div
        ref={ref}
        className={`mx-auto flex items-center justify-center bg-gray-200 rounded-full w-fit text-xxs px-2 py-1 mt-4 ${className}`}
      >
        {formattedDate}
      </div>
    );
  },
);

export default DateDivider;
