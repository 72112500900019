import Popover from "@mui/material/Popover";
import type React from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import Button from "../../Basic/Button";
import ModalMobile from "../../Basic/ModalMobile";

interface FilterButtonProps {
  children?: React.ReactNode;
  numberOfSelectedFilters?: number;
  label?: string | React.ReactNode;
  width?: string;
  mobileHeight?: number;
  anchorOrigin?: {
    vertical: "top" | "center" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  transformOrigin?: {
    vertical: "top" | "center" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  popMargin?: string;
  className?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  arrow?: boolean;
  closeOnSelect?: boolean;
  onReset?: () => void;
  maxHeight?: string;
}

const FilterButton = ({
  children,
  numberOfSelectedFilters = 0,
  label = "",
  width = "200px",
  mobileHeight = 400,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  popMargin = "8px 0 0 0",
  className = "",
  buttonClassName = "",
  dropdownClassName = "p-5",
  arrow = true,
  closeOnSelect = false,
  onReset,
  maxHeight = "90vh",
}: FilterButtonProps) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleOpenOptions = (e: React.MouseEvent<HTMLElement>) => {
    setOpenFilter(true);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseOptions = () => {
    setOpenFilter(false);
    setAnchorEl(null);
  };

  const handleReset = () => {
    onReset();
    handleCloseOptions();
  };

  return (
    <div className={className}>
      <button
        type="button"
        className={`space-x-2 h-fit w-fit flex items-center ${buttonClassName}`}
        aria-haspopup="true"
        aria-expanded={openFilter}
        onClick={handleOpenOptions}
      >
        <div>{label}</div>

        {numberOfSelectedFilters > 0 && (
          <div
            className="w-5 h-5 rounded-md bg-primary-blue text-white text-xs leading-none font-semibold text-center
              flex items-center justify-center"
          >
            {numberOfSelectedFilters}
          </div>
        )}

        {arrow && (
          <ArrowIcon
            width="10"
            height="7"
            className={`transition-all ${openFilter ? "-rotate-180" : ""}`}
          />
        )}
      </button>

      {isDesktop && openFilter && anchorEl && (
        <Popover
          open={openFilter}
          onClose={handleCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: width,
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: popMargin,
              },
            },
          }}
        >
          <div
            className="w-full overflow-y-auto scrollbar"
            style={{
              maxHeight: maxHeight,
            }}
          >
            <div
              className={dropdownClassName}
              onClick={closeOnSelect ? handleCloseOptions : () => {}}
            >
              {children}
            </div>
          </div>
        </Popover>
      )}

      {!isDesktop && (
        <ModalMobile
          open={openFilter}
          onClickAway={handleCloseOptions}
          childHeight={mobileHeight}
          header={
            <div className="flex items-center justify-between">
              <p>
                Filter{" "}
                {numberOfSelectedFilters > 0 && (
                  <span>({numberOfSelectedFilters})</span>
                )}
              </p>
              {onReset && (
                <Button
                  variant="text-primary"
                  size="unset"
                  className="text-base"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              )}
            </div>
          }
        >
          <div
            className="w-full h-fit px-1 pb-5 pt-2"
            onClick={closeOnSelect ? handleCloseOptions : () => {}}
          >
            {children}
          </div>
        </ModalMobile>
      )}
    </div>
  );
};

export default FilterButton;
