import type { Dispatch } from "@reduxjs/toolkit";
import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../../helpers/config";
import { sectionDetailLevels, sectionStyles } from "../../../helpers/constants";
import { handleRequestError } from "../../../helpers/helpers";
import type { RootState } from "../../../store";
import { setNotificationAction } from "../../../store/user/actions";
import { getUser, updateStoredUser } from "../../../store/user/thunks";
import { selectUser } from "../../../store/user/userReducer";
import { setProviderNotesLoadingAction } from "./actions";
import {
  setDefaultNoteTemplate,
  setNoteTemplates,
  setSelectedNoteTemplate,
} from "./noteTemplateSlice";
import {
  type FixLater,
  setScribeNoteContent,
  setScribes,
  setScribesPaginationFetching,
} from "./scribeSlice";
import { selectSelectedAudioId } from "./selectors";
import {
  describedNoteTemplateSchema,
  noteContentSchema,
  noteTemplatesSchema,
  providerNotesResponseSchema,
} from "./validationSchemas";

export const getProviderNotes =
  ({ cursor, isPolling = false }: { cursor?: number; isPolling?: boolean }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const user = selectUser(state);

      if (!cursor) {
        dispatch(setProviderNotesLoadingAction(true));
      } else {
        dispatch(setScribesPaginationFetching(true));
      }

      if (user?.doctor_id) {
        const url = new URL(`${BASE_URL}/v2/provider/${user.doctor_id}/notes/`);
        if (cursor && cursor !== -1) {
          url.searchParams.append("cursor", cursor.toString());
        }

        // const data = await fetchWithAuth(url.toString());
        // const response = data.data;

        const response = await axios.get(url.toString());
        const responseData = response.data.data;

        handleValidation(
          providerNotesResponseSchema,
          responseData,
          "getProviderNotes",
        );

        await dispatch(
          setScribes({
            scribes: responseData.notes,
            nextCursor: responseData.pagination?.next_cursor ?? -1,
            isPolling,
          }),
        );
      }
    } catch (error) {
      if (error instanceof TypeError) {
        console.error("Network error or JSON parsing failed:", error);
      } else {
        console.error("Failed to fetch provider notes:", error);
      }

      handleRequestError(error, dispatch, "Failed to get provider notes");
    } finally {
      if (!cursor) {
        dispatch(setProviderNotesLoadingAction(false));
      } else {
        dispatch(setScribesPaginationFetching(false));
      }
    }
  };

export const getSelectedScribeNote =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState();
      const selectedAudioId = selectSelectedAudioId(state);

      // const data = await fetchWithAuth(`${BASE_URL}/note/${selectedAudioId}`);
      // const noteContent = data.data.note;

      if (selectedAudioId) {
        const response = await axios.get(`${BASE_URL}/note/${selectedAudioId}`);
        const noteContent = response.data.data.note;

        handleValidation(
          noteContentSchema,
          noteContent,
          "getSelectedScribeNote",
        );

        dispatch(
          setScribeNoteContent({
            audioId: selectedAudioId,
            noteContent: noteContent,
          }),
        );
      }
    } catch (error) {
      handleRequestError(error, dispatch, "Failed to load note content");
    } finally {
      dispatch(setProviderNotesLoadingAction(false));
    }
  };

export const getNoteTemplates = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/note-template/list?show_hidden=1`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load note templates",
        );
      } else {
        const noteTemplates = response.data;

        handleValidation(
          noteTemplatesSchema,
          noteTemplates,
          "getNoteTemplates",
        );

        dispatch(setNoteTemplates(noteTemplates));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load note templates");
    });
};

export const describeNoteTemplate =
  (noteTemplateId, onSuccess = (_data: FixLater) => {}) =>
  (dispatch) => {
    if (!noteTemplateId) {
      return;
    }

    axios
      .get(`${BASE_URL}/note-template/describe/${noteTemplateId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to describe note template",
          );
        } else {
          const describedNoteTemplate = response.data;

          handleValidation(
            describedNoteTemplateSchema,
            describedNoteTemplate,
            "describeNoteTemplate",
          );

          onSuccess(describedNoteTemplate);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to describe note template");
      });
  };

export const selectDefaultNoteTemplate = (template) => (dispatch) => {
  axios
    .post(`${BASE_URL}/note-template/select/${template.note_template_id}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to select note template",
        );
      } else {
        dispatch(
          describeNoteTemplate(template.note_template_id, (data) =>
            dispatch(setDefaultNoteTemplate(data)),
          ),
        );
        getUser().then((user) => dispatch(updateStoredUser(user)));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to select note template");
    });
};

export const updateNoteTemplate =
  (
    noteTemplateId,
    newName,
    language,
    instructions,
    showIcd10,
    isHidden,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/update/${noteTemplateId}`, {
        name: newName, // text
        note_language: language, // text
        custom_instructions: instructions, // text
        show_icd10_codes_suggestions: showIcd10 ? 1 : 0, // boolean
        is_hidden: isHidden ? 1 : 0, // boolean
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update note template",
          );
        } else {
          dispatch(getNoteTemplates());
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update note template");
      });
  };

export const updateTemplateSectionsOrder =
  (noteTemplateId, sections) => (dispatch) => {
    axios
      .post(
        `${BASE_URL}/note-template/update-sections-order/${noteTemplateId}`,
        sections, // [{note_template_section_id: number, order: number}]
      )
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update template sections order",
          );
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to update template sections order",
        );
      });
  };

export const updateTemplateSection =
  (
    noteTemplateId,
    noteTemplateSectionId,
    newName,
    style,
    hideByDefault,
    subsections,
    detailLevel,
    splitByProblem,
    includeDifferential,
  ) =>
  (dispatch) => {
    axios
      .post(
        `${BASE_URL}/note-template/section/update/${noteTemplateSectionId}`,
        {
          name: newName, // text,
          section_style:
            style === "Bullet points" ? sectionStyles.BULLET : style, // enum('Auto','Bullet','Paragraph'),
          hide_by_default: hideByDefault ? 1 : 0, // boolean,
          subsections: subsections, // array,
          detail_level: detailLevel, // enum('High','Normal')
          split_by_problem: splitByProblem ? 1 : 0, // boolean,
          include_differential: includeDifferential ? 1 : 0, // boolean
        },
      )
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update template section",
          );
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to update template section",
        );
      });
  };

export const deleteTemplateSection =
  (noteTemplateId, noteTemplateSectionId) => (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/delete-section/${noteTemplateSectionId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete template section",
          );
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to delete template section",
        );
      });
  };

export const createTemplateSection =
  (noteTemplateId, sectionOrder) => (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/create-section/${noteTemplateId}`, {
        name: "New section",
        section_style: sectionStyles.AUTO,
        hide_by_default: 0,
        subsections: [],
        detail_level: sectionDetailLevels.NORMAL,
        split_by_problem: 0,
        include_differential: 0,
        order: sectionOrder,
        has_subsections: 0,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to create template section",
          );
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to create template section",
        );
      });
  };

export const createTemplate =
  (
    templateName,
    noteLanguage,
    customInstructions,
    sections,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/create`, {
        template: {
          name: templateName,
          note_language: noteLanguage,
          custom_instructions: customInstructions,
        },
        sections: sections,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to create note template",
          );
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Success",
              desc: "Note template created successfully",
            }),
          );
          const createdNoteTemplate = response.data.data;

          handleValidation(
            describedNoteTemplateSchema,
            createdNoteTemplate,
            "createTemplate",
          );

          dispatch(getNoteTemplates());
          dispatch(setSelectedNoteTemplate(createdNoteTemplate));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to create note template");
      });
  };

export const deleteTemplate =
  (noteTemplateId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/delete/${noteTemplateId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete note template",
          );
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Success",
              desc: "Note template deleted successfully",
            }),
          );
          dispatch(getNoteTemplates());
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete note template");
      });
  };
