import { type KeyboardEvent, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as SendIcon } from "../../../assets/icons/plain.svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useSendMessageMutation } from "../../../store/call/api";
import { setClosedResponsesAction } from "../../../store/conversation/actions";
import TabsBorder from "../../Basic/TabsBorder";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import CompleteConversationButton from "../../PatientProfile/RightPanel/Message/CompleteConversationButton";
import InputWithMentions from "../Mentions/InputWithMentions";
import type { MentionItem } from "../Mentions/interfaces";
import AssignButton from "./AssignButton";
import SuggestedAnswer from "./SuggestedAnswer";

type Conversation = FixLater;

const SendMessageTypes = {
  PATIENT: "Patient",
  INTERNAL: "Internal",
};

type InputAreaProps = {
  setScrolledUp: (scrolledUp: boolean) => void;
  messages: Record<string, string>;
  setMessages: (messages: Record<string, string>) => void;
  showResponse: boolean;
  setShowResponse: (showResponse: boolean) => void;
  currentConversation: Conversation;
  selectedConversationId: number;
  className?: string;
  completeClassName?: string;
  showCompleteButton?: boolean;
  mentionItems: MentionItem[];
};

const InboxInputArea = ({
  setScrolledUp,
  messages,
  setMessages,
  showResponse,
  setShowResponse,
  currentConversation,
  selectedConversationId,
  className = "",
  completeClassName,
  showCompleteButton,
  mentionItems = [],
}: InputAreaProps) => {
  const [suggestedAnswerData, setSuggestedAnswerData] = useState("");
  const [conversationChanged, setConversationChanged] = useState(true);
  const [sendMessageType, setSendMessageType] = useState(
    SendMessageTypes.PATIENT,
  );
  const { closedResponses } = useAppSelector((state) => state.conversation);
  const dispatch = useAppDispatch();

  const [sendMessage] = useSendMessageMutation();

  const messageKey =
    sendMessageType === SendMessageTypes.PATIENT
      ? `${selectedConversationId}.patient_message`
      : `${selectedConversationId}.internal_message`;

  useEffect(() => {
    setConversationChanged(true);

    if (messages[messageKey] === undefined) {
      setMessages({ ...messages, [messageKey]: "" });
    }
  }, [messageKey, messages[messageKey], setMessages]);

  useEffect(() => {
    setSuggestedAnswerData(
      currentConversation ? currentConversation.suggested_answer : "",
    );
    if (conversationChanged) {
      if (currentConversation?.suggested_answer) {
        setShowResponse(
          !closedResponses.includes(currentConversation?.conversation_id),
        );
      } else {
        setShowResponse(false);
      }
      setConversationChanged(false);
    }
  }, [
    currentConversation,
    setShowResponse,
    conversationChanged,
    closedResponses,
  ]);

  const onSendMessage = () => {
    const message = messages[messageKey];
    if (message.trim()) {
      sendMessage({
        message,
        conversationId: selectedConversationId,
        isInternal: sendMessageType !== SendMessageTypes.PATIENT,
      });
      setScrolledUp(false);
    }

    setMessages({
      ...messages,
      [messageKey]: "",
    });
  };

  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      event.key === "Enter"
    ) {
      event.preventDefault();
      onSendMessage();
    }
  };

  return (
    <div className={className}>
      <div className="px-4 pb-4 w-full max-w-5xl mx-auto">
        <div className="bg-white rounded-lg border border-gray-200 ">
          <div className="h-11 border-b grid grid-cols-conv-layout items-end">
            <TabsBorder
              tabs={Object.values(SendMessageTypes)}
              activeTab={sendMessageType}
              setActiveTab={setSendMessageType}
              className="text-sm font-medium ml-3"
            />
            {currentConversation && (
              <AssignButton conversation={currentConversation} />
            )}
          </div>

          {showResponse && currentConversation?.show_response && (
            <SuggestedAnswer
              answer={suggestedAnswerData}
              onCancel={() => {
                setShowResponse(false);
                if (!closedResponses.includes(selectedConversationId)) {
                  dispatch(setClosedResponsesAction(selectedConversationId));
                }
              }}
              onUse={() => {
                setMessages({
                  ...messages,
                  [`${selectedConversationId}.suggested_answer`]:
                    suggestedAnswerData,
                });
                setShowResponse(false);
              }}
            />
          )}

          <div
            className={`pb-3 rounded-b-lg border border-gray-foreground
              ${
                sendMessageType === SendMessageTypes.PATIENT
                  ? "bg-white pt-3"
                  : "bg-[#FFF7E9] pt-3.5"
              }`}
          >
            {sendMessageType === SendMessageTypes.PATIENT ? (
              <TextareaAutosize
                value={messages[messageKey]}
                onChange={(e) =>
                  setMessages({
                    ...messages,
                    [messageKey]: e.target.value,
                  })
                }
                onKeyDown={onKeyDown}
                placeholder="Type a message"
                minRows={2}
                maxRows={14}
                className="w-full resize-none scrollbar border-r-8 border-white pl-3 pr-1 pt-1 text-sm bg-transparent
                  align-top mb-1 leading-[1.3] outline-none"
              />
            ) : (
              <InputWithMentions
                onKeyDown={onKeyDown}
                mentionItems={mentionItems}
                value={messages[messageKey]}
                onChange={(e) =>
                  setMessages({
                    ...messages,
                    [messageKey]: e.target.value,
                  })
                }
                placeholder="Mention people using '@'"
              />
            )}

            <div className="px-3 flex w-full justify-end">
              <button
                type="button"
                aria-label="Send message"
                onClick={onSendMessage}
              >
                <SendIcon width="20" height="20" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {showCompleteButton && (
        <CompleteConversationButton
          conversation={currentConversation}
          className={completeClassName}
        />
      )}
    </div>
  );
};

export default InboxInputArea;
