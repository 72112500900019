import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import ProfilePicture from "../../../Basic/ProfilePicture";

const TeamMember = ({ member, edit, onRemove }) => {
  return (
    member && (
      <div className="grid items-center grid-cols-conv-layout">
        <div className="flex items-center space-x-2 py-2 truncate">
          <ProfilePicture
            firstName={member.first_name}
            lastName={member.last_name}
            src={member.profile_picture}
          />
          <div className="truncate">
            <p className="text-sm font-bold truncate">
              {member.display_name
                ? member.display_name
                : `${member.first_name} ${member.last_name}`}
            </p>
            <p className="text-xs font-medium text-tertiary truncate">
              {member.occupation}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          {edit && (
            <button type="button" onClick={() => onRemove(member.doctor_id)}>
              <CloseIcon stroke="#667085" width="16" height="16" />
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default TeamMember;
