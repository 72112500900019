import { type ReactNode, memo } from "react";

interface NumberCardProps {
  icon?: ReactNode;
  number: number;
  title: string;
}

const NumberCard = ({ icon, number, title }: NumberCardProps) => {
  return (
    <div className="bg-slate-650 rounded-lg py-2 px-3 md:p-4 w-full flex items-center">
      {icon && (
        <div className="mr-3 w-12 h-12 bg-white/10 rounded-full hidden lg:flex items-center justify-center">
          {icon}
        </div>
      )}
      <div className="flex flex-col">
        <p className="text-white text-base md:text-xl font-semibold">
          {number}
        </p>
        <p className="text-gray-foreground text-xs md:text-sm font-medium truncate">
          {title}
        </p>
      </div>
    </div>
  );
};

export default memo(NumberCard);
