import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/file-check.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/icons/pause-filled.svg";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/icons/x-mark.svg";
import { ReactComponent as SettingUpHeader } from "../../../../assets/note-header.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { getPatientScribes } from "../../../../store/patient/thunks";
import { setOpenCallOptionsModalAction } from "../../../../store/voiceRecorder/actions";
import MicrophoneLiveVisualizer from "../../../Basic/MicrophoneLiveVisualizer";
import ProgressBar from "../../../Basic/ProgressBar";
import Warning from "../../../Basic/Warning";
import RecordingIndicator from "../../../IndependentScribe/RecordingIndicator";
import TimerDisplay from "../../../IndependentScribe/TimerDisplay";
import TimerProvider from "../../../IndependentScribe/TimerProvider";
import { useBannerWarnings } from "../../../IndependentScribe/hooks/useBannerWarnings";
import {
  setScribeSelectedAudioId,
  setScribeTogglePause,
} from "../../../IndependentScribe/store/scribeSlice";
import {
  getGroupedScribesByPatientId,
  getSelectedScribe,
} from "../../../IndependentScribe/store/selectors";

const buttonClasses =
  "w-full h-12 height-sm:h-16 text-base font-semibold flex items-center justify-center space-x-2 px-2";

const Recording = ({
  onCancel,
  mediaRecorder,
  togglePause,
  cancelRecording,
  saveRecording,
  isStalled,
  notEnoughTranscript,
  onNotEnoughTranscriptSubmit,
  showNoteGenerationDelayedWarning,
  hideNoteGenerationDelayedWarning,
}: {
  onCancel: () => void;
  mediaRecorder: MediaRecorder | null;
  togglePause: () => void;
  cancelRecording: () => void;
  saveRecording: () => void;
  isStalled: boolean;
  notEnoughTranscript: boolean;
  onNotEnoughTranscriptSubmit: () => void;
  showNoteGenerationDelayedWarning: boolean;
  hideNoteGenerationDelayedWarning: () => void;
}) => {
  const prevSelectedScribe = useRef(null);
  const selectedScribe = useAppSelector(getSelectedScribe);
  const {
    selectedPatientInfo,
    // patientRecordingScribeTitle, // TODO
  } = useAppSelector((state) => state.patient);

  const scribes = useAppSelector((state) =>
    getGroupedScribesByPatientId(
      state,
      Number(selectedPatientInfo?.patient_id),
    ),
  );

  const [notesPrevLength, setNotesPrevLength] = useState(scribes?.length || 0);
  const [checkNote, setCheckNote] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(0);
  const notesLengthRef = useRef(scribes?.length || 0);

  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  const handleCancelClick = () => {
    togglePause();
    cancelRecording();
    onCancel();
  };

  const handleTogglePause = () => {
    togglePause();
    dispatch(setScribeTogglePause());
  };

  const { banner, isBannerWarningVisible, hideBannerWarning } =
    useBannerWarnings(
      isStalled,
      isDesktop,
      notEnoughTranscript,
      onNotEnoughTranscriptSubmit,
      showNoteGenerationDelayedWarning,
      hideNoteGenerationDelayedWarning,
    );

  useEffect(() => {
    if (scribes) {
      setNotesPrevLength(notesLengthRef.current);
      notesLengthRef.current = scribes.length;
    }
  }, [scribes]);

  useEffect(() => {
    if (
      selectedScribe?.isGenerating &&
      scribes &&
      notesLengthRef.current > notesPrevLength
    ) {
      setCheckNote(true);
    }
  }, [selectedScribe?.isGenerating, scribes, notesPrevLength]);

  useEffect(() => {
    if (!selectedScribe?.isGenerating) {
      return;
    }

    const interval = setInterval(() => {
      dispatch(getPatientScribes(selectedPatientInfo.patient_id));
    }, 3000);

    const progressInterval = setInterval(() => {
      setGeneratingProgress((oldProgress) => {
        if (oldProgress < 100) {
          return oldProgress + 1;
        }

        return 100;
      });
    }, 200);

    return () => {
      clearInterval(interval);
      clearInterval(progressInterval);
      setGeneratingProgress(0);
    };
  }, [selectedScribe?.isGenerating, selectedPatientInfo?.patient_id, dispatch]);

  useEffect(() => {
    if (prevSelectedScribe.current && !selectedScribe?.audioId) {
      onCancel();
    }

    if (selectedScribe) {
      prevSelectedScribe.current = selectedScribe;
    }
  }, [selectedScribe, onCancel]);

  useEffect(() => {
    if (checkNote) {
      const sortedNotes = [...scribes];
      sortedNotes.sort(
        (a, b) =>
          new Date(`${b.sortableDate?.replace(/-/g, "/")} GMT+0`).getTime() -
          new Date(`${a.sortableDate?.replace(/-/g, "/")} GMT+0`).getTime(),
      );

      if (sortedNotes[0].entries[0]) {
        if (!isDesktop) {
          dispatch(setOpenCallOptionsModalAction(false));
        }
        dispatch(
          setScribeSelectedAudioId({
            audioId: sortedNotes[0].entries[0].audioId,
          }),
        );
        setCheckNote(false);

        if (!selectedScribe?.isRecording) {
          onCancel();
        }
      }
    }
  }, [
    checkNote,
    scribes,
    dispatch,
    selectedScribe?.isRecording,
    onCancel,
    isDesktop,
  ]);

  return (
    <TimerProvider>
      <div className="h-full w-full grid grid-rows-2 overflow-y-auto scrollbar">
        {selectedScribe?.isGenerating &&
        !selectedScribe?.isRecording &&
        !selectedScribe?.isGenerationDelayed ? (
          <div className="px-8 row-span-2 my-auto">
            <div className="w-full max-w-md mx-auto bg-slate-50 rounded-2xl p-8 flex flex-col items-center ">
              <SettingUpHeader width="200" height="81" className="mb-6" />
              <p className="text-xl text-center font-semibold mb-3">
                We're setting up your note
              </p>
              <p className="text-sm text-center text-tertiary mb-6">
                While waiting, you can explore other things as this may take a
                few minutes
              </p>
              {generatingProgress < 100 && (
                <ProgressBar variant="determinate" value={generatingProgress} />
              )}
            </div>
          </div>
        ) : (
          <>
            {isBannerWarningVisible && banner && isDesktop && (
              <Warning
                image={banner.image}
                title={banner.title}
                description={banner.description}
                submitText={banner.submitText}
                onSubmit={banner.onSubmit}
                onCancel={hideBannerWarning}
                horizontal
                className="relative"
              />
            )}
            <div className="w-full grid items-center relative row-span-2 height-sm:pt-[15dvh]">
              <div className="blur-3xl bg-blue-gradient absolute top-0 left-0 w-full h-full" />
              <div className="px-10 w-full overflow-x-hidden">
                <MicrophoneLiveVisualizer
                  mediaRecorder={mediaRecorder}
                  height={isHeightSm ? 160 : 70}
                />
              </div>
            </div>
            <div className="grid grid-rows-note-layout items-end gap-16">
              <div className="h-fit flex flex-col items-center space-y-1">
                <div className="text-center text-xl md:text-[32px] font-semibold">
                  <TimerDisplay
                    isRecording={selectedScribe?.isRecording}
                    startedAt={selectedScribe?.startedAt}
                    offset={selectedScribe?.accumulatedDuration}
                    className="text-2xl"
                  />
                </div>
                <div className="h-6">
                  {selectedScribe && (
                    <div className="rounded-full pl-2 pr-3 py-1 bg-pink-150 flex items-center space-x-2">
                      <RecordingIndicator
                        isRecording={selectedScribe?.isRecording}
                        size={16}
                      />
                      <p className="text-xs font-medium text-zinc-700">
                        {selectedScribe?.isRecording ? "Recording" : "Paused"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full grid grid-cols-2 relative">
                <button
                  type="button"
                  className={`${buttonClasses} text-tertiary bg-gray-background border-r-2 border-white`}
                  onClick={handleCancelClick}
                >
                  <CancelIcon width="21" height="20" className="flex-none" />
                  <p>Cancel</p>
                </button>

                <button
                  type="button"
                  className={`${buttonClasses} text-primary-blue bg-primary-blue-light border-l-2 border-white`}
                  onClick={saveRecording}
                  disabled={selectedScribe?.isGenerating}
                >
                  <CheckIcon
                    width="20"
                    height="20"
                    stroke="#2970FF"
                    fill="#2970FF"
                    className="flex-none"
                  />
                  <p className="text-center leading-tight">Generate note</p>
                </button>

                <button
                  type="button"
                  className="absolute z-10 -top-10 left-1/2 -translate-x-1/2 rounded-full bg-primary-blue h-14 w-14 md:w-16 md:h-16 xl:w-20 xl:h-20
                    flex items-center justify-center border-[2.8px] border-white box-content m-0 md:border-4"
                  onClick={handleTogglePause}
                >
                  {!selectedScribe?.isPaused &&
                  !selectedScribe?.isGenerating ? (
                    <PauseIcon
                      width="28"
                      height="28"
                      className="md:w-10 md:h-10"
                    />
                  ) : (
                    <PlayIcon
                      width="17.84"
                      height="19.19"
                      fill="white"
                      className="md:w-[25.48px] md:h-[27.42px]"
                    />
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </TimerProvider>
  );
};

export default Recording;
