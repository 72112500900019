import { useContext, useEffect, useState } from "react";
import {
  getDurationString,
  getNextUpdateInterval,
} from "../../../helpers/helpers";
import { TimerContext } from "../TimerProvider";
import type { ScribeGroupItem } from "../store/selectors";

/**
 * A custom hook that calculates and formats the duration of a recording session.
 *
 * @param {ScribeGroupItem} scribe - The scribe object containing recording state and timing information
 * @returns {string} A formatted string representing the duration (e.g., "00:00", "01:23", "12:34:56")
 *
 * @example
 * const duration = useRecordingDuration(scribe);
 * // Returns: "01:23" for 1 minute and 23 seconds
 *
 * @remarks
 * - Updates the duration in real-time while recording is active
 * - Uses dynamic update intervals for efficiency (more frequent updates for shorter durations)
 * - Handles accumulated duration for paused/resumed recordings
 * - Cleans up timeouts on unmount or when recording stops
 */
const useRecordingDuration = (scribe: ScribeGroupItem): string => {
  const [duration, setDuration] = useState<string>(
    getDurationString(Math.floor(scribe.accumulatedDuration / 1000)),
  );
  const currentTime = useContext(TimerContext);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (scribe.isRecording && scribe.startedAt) {
      const updateDuration = () => {
        const startDate = new Date(scribe.startedAt || "");
        const elapsed =
          currentTime.getTime() -
          startDate.getTime() +
          scribe.accumulatedDuration;
        const durationInSeconds = Math.max(0, Math.floor(elapsed / 1000));

        setDuration(getDurationString(durationInSeconds));

        const nextUpdateInMs = getNextUpdateInterval(durationInSeconds);
        timeoutId = setTimeout(updateDuration, nextUpdateInMs);
      };

      updateDuration();
    } else {
      setDuration(
        getDurationString(Math.floor(scribe.accumulatedDuration / 1000)),
      );
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    scribe.isRecording,
    scribe.startedAt,
    scribe.accumulatedDuration,
    currentTime,
  ]);

  return duration;
};

export default useRecordingDuration;
