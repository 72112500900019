import TemplatePreview from "../../Basic/TemplatePreview";
import type { NoteTemplateSection } from "../../IndependentScribe/store/noteTemplateSlice";

interface NotePreviewProps {
  sections: NoteTemplateSection[];
}

const NotePreview = ({ sections }: NotePreviewProps) => {
  return (
    <div className="w-full h-fit grid grid-rows-tab-layout md:block py-4 md:pt-0">
      <TemplatePreview
        sections={sections}
        className="h-full overflow-y-auto px-4 scrollbar"
      />
    </div>
  );
};

export default NotePreview;
