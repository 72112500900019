import { useEffect, useRef, useState } from "react";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle-opacity.svg";
import { ReactComponent as UndoCircleIcon } from "../../../assets/icons/undo-circle.svg";
import type { Conversation } from "../../../store/call/interfaces";
import LoaderDotsSmall from "../../Basic/LoaderDotsSmall";

interface CallStatusButtonsProps {
  onComplete: (patientId: number) => void;
  onMoveToReview: (patientId: number) => void;
  patientId: number;
  isCompleted: boolean;
  conversation: Conversation;
  className?: string;
}

const CallStatusButtons = ({
  onComplete,
  onMoveToReview,
  patientId,
  isCompleted,
  conversation,
  className = "",
}: CallStatusButtonsProps) => {
  const [animation, setAnimation] = useState(false);
  const prevConversationCompletedRef = useRef(-1);

  useEffect(() => {
    if (
      patientId ||
      prevConversationCompletedRef.current !== conversation.completed
    ) {
      setAnimation(false);
    }

    prevConversationCompletedRef.current = conversation.completed;
  }, [patientId, conversation.completed]);

  const handleComplete = () => {
    if (!patientId) {
      return;
    }

    setAnimation(true);
    onComplete(patientId);
  };

  const handleMoveToReview = () => {
    if (!patientId) {
      return;
    }

    setAnimation(true);
    onMoveToReview(patientId);
  };

  return (
    <button
      type="button"
      className={`flex items-center justify-center relative whitespace-nowrap px-1.5 h-8 w-25
        border border-gray-foreground rounded-lg transition-all duration-300 ease-in-out
        ${animation ? "bg-primary-blue text-white" : "bg-white text-primary-blue"} ${className}`}
      onClick={isCompleted ? handleMoveToReview : handleComplete}
      disabled={animation || !conversation}
    >
      {conversation &&
        (isCompleted ? (
          <UndoCircleIcon
            width="20"
            height="20"
            className="flex-none transition-all duration-300"
            stroke={animation ? "white" : "#2970FF"}
          />
        ) : (
          <CheckIcon
            width="20"
            height="20"
            className="flex-none transition-all duration-300"
            stroke={animation ? "white" : "#2970FF"}
          />
        ))}
      <div className="truncate pl-1 min-w-0 text-sm font-semibold">
        {!conversation ? (
          <LoaderDotsSmall />
        ) : isCompleted ? (
          <p>Review</p>
        ) : (
          <p>Complete</p>
        )}
      </div>
    </button>
  );
};

export default CallStatusButtons;
