import type { FixLater } from "../IndependentScribe/store/scribeSlice";
import TextareaAutosizeForm from "./TextareaAutosizeForm";

interface TextareaOrderedLinesProps {
  name: string;
  label?: string;
  placeholder?: string;
  register: FixLater;
  registerOptions?: FixLater;
  setValue: FixLater;
  required?: FixLater;
  error?: FixLater;
  initLength?: number;
  maxLength?: number;
}

const TextareaOrderedLines = ({
  name,
  label = "",
  placeholder = "",
  register,
  registerOptions = {},
  setValue,
  required,
  error,
  initLength,
  maxLength,
  ...props
}: TextareaOrderedLinesProps) => {
  const getOrderedLines = (text) => {
    const lines = text.split(/\r?\n|\r/);

    const numberedLines = lines.map((line, index) => {
      const match = line.match(/^\d+\./);
      const content = match ? line.substring(match[0].length) : line;

      return `${index + 1}. ${content.trim()}`;
    });

    // Check number order
    for (let i = 0; i < numberedLines.length; i++) {
      const line = numberedLines[i];
      const match = line.match(/^(\d+)\./);
      const lineNumber = match ? Number.parseInt(match[1]) : i + 1;
      if (lineNumber !== i + 1) {
        numberedLines[i] = `${i + 1}. ${line.substring(match[0].length)}`;
      }
    }

    return numberedLines.join("\n");
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      setValue(name, getOrderedLines(e.target.value));
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 3;
        e.target.selectionEnd = cursorPosition + 3;
        e.target.focus();
      }, 0);
    }
  };

  const handleFocus = (e) => {
    if (e.target.value.trim() === "") {
      setValue(name, "1. ");
    }
  };

  const handleBlur = (e) => {
    if (e.target.value.trim() === "" || e.target.value.trim() === "1.") {
      setValue(name, "");
    } else {
      setValue(name, getOrderedLines(e.target.value));
    }
  };

  return (
    <TextareaAutosizeForm
      name={name}
      label={label}
      placeholder={placeholder}
      register={register}
      required={required}
      registerOptions={registerOptions}
      error={error}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      {...props}
    />
  );
};

export default TextareaOrderedLines;
