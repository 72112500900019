import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ResendIcon } from "../../../../assets/icons/letter-opened.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/letter.svg";
import { ReactComponent as PasswordIcon } from "../../../../assets/icons/password.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-redesign.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { getUserSites } from "../../../../store/site/thunks";
import { useGetUsersQuery } from "../../../../store/user/api";
import {
  deleteUser,
  forgotPassword,
  resendVerificationEmail,
} from "../../../../store/user/thunks";
import { selectUser } from "../../../../store/user/userReducer";
import ActionsButton from "../../../Basic/ActionsButton";
import DeleteModal from "../../../Basic/DeleteModal";
import ProfilePicture from "../../../Basic/ProfilePicture";
import Tag from "../../../Basic/Tag";
import UserTable from "../../../Basic/UserTable";
import UserTableSkeleton from "../../../Skeletons/UserTableSkeleton";
import DeleteErrorModal from "../DeleteErrorModal";
import AddUserModal from "./AddUserModal";
import ChangeEmailModal from "./ChangeEmailModal";
import EditUserModal from "./EditUserModal";
import EmptyUsers from "./EmptyUsers";
import ResetPasswordModal from "./ResetPasswordModal";
import UserListItem from "./UserListItem";

const Users = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}) => {
  const user = useAppSelector(selectUser);
  const { roles, userSearchValue } = useAppSelector((state) => state.user);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  const { data: users = [], isLoading: usersLoading } = useGetUsersQuery({
    search: userSearchValue,
  });

  useEffect(() => {
    if (selectedUser) {
      dispatch(getUserSites(selectedUser.doctor_id));
    }
  }, [selectedUser, dispatch]);

  const columns = [
    { label: "Display Name", accessor: "display_name" },
    { label: "Email Address", accessor: "email" },
    { label: "Role", accessor: "role" },
    { label: "Position", accessor: "occupation" },
    { label: "Status", accessor: "status" },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];

    if (users && users.length > 0) {
      users.forEach((userItem) =>
        tableData.push({
          user: userItem,
          display_name: (
            <div className="flex items-center space-x-2 xl:space-x-4 truncate md:max-w-[200px] 2xl:max-w-[310px]">
              <ProfilePicture
                firstName={userItem.first_name}
                lastName={userItem.last_name}
                src={userItem.profile_picture}
                flagPadding={false}
              />
              <p className="truncate">{userItem.display_name.trim() || "-"}</p>
            </div>
          ),
          email: (
            <p className="truncate md:max-w-[150px] 2xl:max-w-[250px]">
              {userItem.email}
            </p>
          ),
          role: (
            <div className="flex items-center space-x-2">
              <UserIcon width="20" height="20" />
              <p>{roles[+userItem.role_id]}</p>
            </div>
          ),
          occupation: (
            <p className="truncate md:max-w-[130px] 2xl:max-w-[170px]">
              {userItem.occupation?.trim() || "-"}
            </p>
          ),
          status:
            userItem.status === "Active" ? (
              <Tag text="Active" variant="emerald" />
            ) : userItem.status === "Pending" ? (
              <Tag text="Invited" variant="amber" />
            ) : (
              <></>
            ),
          actions: (
            <ActionsButton
              onClick={() => setSelectedUser(userItem)}
              actions={[
                (user.role_id === 1 || user.role_id === 0) && {
                  icon: (
                    <EmailIcon width="20" height="20" className="flex-none" />
                  ),
                  label: "Change Email",
                  onClick: onChangeEmail,
                },
                userItem.status === "Pending" && {
                  icon: (
                    <ResendIcon width="20" height="20" className="flex-none" />
                  ),
                  label: "Resend Email",
                  onClick: handleResendEmail,
                },
                (user.role_id === 1 || user.role_id === 0) && {
                  icon: (
                    <PasswordIcon
                      width="20"
                      height="20"
                      className="flex-none"
                    />
                  ),
                  label: "Reset Password",
                  onClick: onResetPassword,
                },
                {
                  icon: (
                    <DeleteIcon width="20" height="20" className="flex-none" />
                  ),
                  label: "Delete",
                  onClick: onDeleteUser,
                },
              ]}
            />
          ),
        }),
      );
    }

    return tableData;
  };

  const onCloseUserModal = () => {
    setOpenModal(false);
  };

  const onCloseErrorModal = () => {
    setErrorMessage("");
  };

  const onEditUser = () => {
    setOpenEditModal(true);
  };

  const onDeleteUser = () => {
    setOpenDeleteModal(true);
  };

  const onChangeEmail = () => {
    setOpenEmailModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(
      deleteUser(selectedUser.doctor_id, (message) => setErrorMessage(message)),
    );
    setSelectedUser(null);
    setOpenDeleteModal(false);
  };

  const handleResendEmail = () => {
    dispatch(resendVerificationEmail(selectedUser.doctor_id));
  };

  const onResetPassword = () => {
    dispatch(forgotPassword(selectedUser.email));
  };

  return (
    <div className="relative w-full md:h-full overflow-hidden">
      {usersLoading ? (
        <UserTableSkeleton />
      ) : users.length > 0 ? (
        isDesktop ? (
          <UserTable
            columns={columns}
            data={getTableData()}
            onClickRow={(user) => {
              setSelectedUser(user);
              onEditUser();
            }}
          />
        ) : (
          <div className="space-y-3">
            {users.map((user) => (
              <UserListItem
                key={user.doctor_id}
                userItem={user}
                onClickActions={() => setSelectedUser(user)}
                onDelete={onDeleteUser}
                onResendEmail={handleResendEmail}
                onChangeEmail={onChangeEmail}
                onResetPassword={onResetPassword}
                onClick={() => {
                  setSelectedUser(user);
                  onEditUser();
                }}
              />
            ))}
          </div>
        )
      ) : (
        <EmptyUsers onClick={() => setOpenModal(true)} />
      )}

      <AddUserModal open={openModal} onClose={onCloseUserModal} />

      {openDeleteModal && (
        <DeleteModal
          onClose={() => setOpenDeleteModal(false)}
          onDelete={handleDeleteUser}
          title="Delete User"
          description="Are you sure you want to delete user?"
        />
      )}

      {errorMessage && (
        <DeleteErrorModal
          onClose={onCloseErrorModal}
          title="Delete User"
          message={errorMessage}
        />
      )}

      {openEmailModal && (
        <ChangeEmailModal
          user={selectedUser}
          onClose={() => setOpenEmailModal(false)}
        />
      )}

      {openPasswordModal && (
        <ResetPasswordModal
          user={selectedUser}
          onClose={() => setOpenPasswordModal(false)}
        />
      )}

      <EditUserModal
        open={openEditModal}
        user={selectedUser}
        onClose={() => setOpenEditModal(false)}
      />
    </div>
  );
};

export default Users;
