import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { Pages, patientPanelTabs } from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { setSelectedConversationId } from "../../store/call/callSlice";
import { setSelectedEventAction } from "../../store/campaign/actions";
import { setSelectedPatientPanelTabAction } from "../../store/patient/actions";
import { setOpenCallOptionsModalAction } from "../../store/voiceRecorder/actions";
import { getSelectedScribe } from "../IndependentScribe/store/selectors";
import Navigation from "./Navigation";
import NotificationsWrapper from "./NotificationsWrapper";

const Layout = ({ children }) => {
  const { openCallModal } = useAppSelector((state) => state.voiceRecorder);
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const { isDatePickerOpen, hideMobileNavbar } = useAppSelector(
    (state) => state.ui,
  );
  const selectedScribe = useAppSelector(getSelectedScribe);
  const [isPatientProfile, setIsPatientProfile] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const dispatch = useAppDispatch();
  const location = useLocation();

  const recordingStartedRef = useRef(selectedScribe?.isRecording);
  const openCallModalRef = useRef(openCallModal);

  useEffect(() => {
    recordingStartedRef.current = selectedScribe?.isRecording;
  }, [selectedScribe?.isRecording]);

  useEffect(() => {
    openCallModalRef.current = openCallModal;
  }, [openCallModal]);

  useEffect(() => {
    if (window.location.pathname !== Pages.INBOX && !isDesktop) {
      dispatch(setSelectedConversationId(null));
    }
    if (recordingStartedRef?.current && !isDesktop) {
      dispatch(setOpenCallOptionsModalAction(true));
      dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
    }
    if (openCallModalRef?.current && !isDesktop) {
      dispatch(setOpenCallOptionsModalAction(true));
      dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.CALL));
    }
  }, [isDesktop, dispatch]);

  useEffect(() => {
    dispatch(setSelectedEventAction(null));

    if (location.pathname.match(/\/patients\/\d+/)) {
      setIsPatientProfile(true);
    } else {
      setIsPatientProfile(false);
    }
  }, [dispatch, location.pathname]);

  return (
    <NotificationsWrapper>
      <div
        className={`h-screen-dynamic w-screen overflow-hidden md:pt-0
          ${!isDesktop && (hideMobileNavbar || (selectedPatientInfo && isPatientProfile)) ? "pt-0" : "pt-16"}`}
      >
        <Navigation isPatientProfile={isPatientProfile} />
        <div
          className={`h-full w-full overflow-hidden grid grid-cols-conv-layout
            ${isDesktop ? `pl-[72px] ${!isDatePickerOpen ? "fixed" : ""} h-screen-dynamic` : ""}`}
        >
          {children}
        </div>
      </div>
    </NotificationsWrapper>
  );
};

export default Layout;
