export const getDefaultStyle = (isDisabled?: boolean, isDesktop?: boolean) => {
  return {
    "&multiLine": {
      control: {
        height: "fit-content",
        minHeight: isDisabled ? "min-content" : isDesktop ? 42 : 48,
        maxHeight: isDisabled ? "min-content" : 325,
        fontSize: isDisabled ? 12 : isDesktop ? 14 : 16,
      },
      highlighter: {
        paddingLeft: isDisabled ? 0 : "11px",
        paddingRight: isDisabled ? 0 : "8px",
        height: "min-content",
        minHeight: isDisabled ? "min-content" : isDesktop ? 42 : 48,
        maxHeight: isDisabled ? "min-content" : 325,
        overflowY: isDisabled ? "hidden" : "auto",
        fontSize: isDisabled ? 12 : isDesktop ? 14 : 16,
      },
      input: {
        overflowY: isDisabled ? "hidden" : "auto",
        paddingLeft: isDisabled ? 0 : "12px",
        paddingRight: isDisabled ? 0 : "8px",
        outline: "none",
        fontSize: `${isDisabled ? 12 : isDesktop ? 14 : 16} !important`,
      },
    },
    suggestions: {
      borderRadius: "8px",
      zIndex: 9999,
      list: {
        listStyle: "none",
      },
      item: {
        "&focused": {
          backgroundColor: "rgb(243 245 247 / var(--tw-bg-opacity))",
        },
      },
    },
  };
};
