export enum MentionType {
  USER = "user",
  TEAM = "team",
  SEPARATOR = "separator",
}

export type MentionUser = {
  id: string;
  display: string;
  type: MentionType.USER;
  first_name?: string | null;
  last_name?: string | null;
  profile_picture?: string | null;
};

export type MentionTeam = {
  id: string;
  display: string;
  type: MentionType.TEAM;
  team_icon?: string | null;
};

export type MentionSeparator = {
  type: MentionType.SEPARATOR;
};

export type MentionItem = MentionUser | MentionTeam | MentionSeparator;

export interface CaretPosition {
  top: number;
  left: number;
}
