import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css";

const TextEditor = ({
  htmlMarkup,
  onChange,
  className = "",
  disabled,
  hideToolbar = false,
  rerenderDeps = [],
  onBlur = () => {},
  onFocus = () => {},
}: {
  htmlMarkup: string;
  onChange: (htmlMarkup: string) => void;
  className?: string;
  disabled?: boolean;
  hideToolbar?: boolean;
  rerenderDeps?: unknown[];
  onBlur?: () => void;
  onFocus?: () => void;
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  useEffect(() => {
    let parsedMarkup = htmlMarkup;
    if (parsedMarkup) {
      const hasBR = /<br\s*\/?>/i.test(parsedMarkup);
      if (!hasBR) {
        parsedMarkup = parsedMarkup.replace(/\n/g, "<br/>");
      }
      parsedMarkup = parsedMarkup.replace(
        /<br\s*\/?><br\s*\/?>/g,
        "</p><p></p><p>",
      );

      const blocksFromHtml = htmlToDraft(parsedMarkup);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [htmlMarkup, ...rerenderDeps]);

  const onStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let markup = draftToHtml(rawContent);

    markup = markup.replace(/<p><\/p>/g, "<br/>");
    onChange(markup);
  };

  let editorClassName = `editor-class ${disabled ? "disabled" : ""}`;
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      editorClassName += " hidePlaceholder";
    }
  }

  return (
    <div className={className}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onStateChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Enter your notes here..."
        wrapperClassName={disabled ? "wrapper-disabled-class" : "wrapper-class"}
        editorClassName={editorClassName}
        readOnly={disabled}
        toolbarHidden={hideToolbar || disabled}
        toolbar={{
          inline: {
            options: ["bold", "italic", "underline"],
          },
          options: ["fontSize", "inline", "list"],
        }}
      />
    </div>
  );
};

export default TextEditor;
