interface BadgeProps {
  isUrgent?: boolean;
  isNew?: boolean;
}

const Badge = ({ isUrgent, isNew }: BadgeProps) => {
  if (!isUrgent && !isNew) {
    return null;
  }

  return (
    <div
      aria-label={isUrgent ? "Urgent" : "New"}
      className={`text-xs text-white px-2 py-0.5 rounded-full border-2 border-white border-opacity-90 w-fit
         ${isUrgent ? "bg-urgent" : "bg-system-green-dark"}`}
    >
      {isUrgent ? "Urgent" : "New"}
    </div>
  );
};

export default Badge;
