import { z } from "zod";
import {
  scribeSchema,
  zeroOrOneSchema,
} from "../../../helpers/commonValidationSchemas";

const notePatientSchema = z.object({
  patient_id: z.number().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  birthdate: z.string().nullable(),
  gender: z.string().nullable(),
  pronouns: z.string().nullable(),
});

export const providerNoteSchema = z.object({
  audio_id: z.string(),
  note_id: z.number().nullable(),
  audio_status: z.string(),
  started_at: z.string(),
  finished_at: z.string().nullable(),
  duration: z.number().nullable(),
  title: z.string().nullable(),
  free_text: z.string().nullable(),
  patient: notePatientSchema.optional(),
});

export const providerNotesResponseSchema = z.object({
  notes: z.array(providerNoteSchema),
  pagination: z.object({
    next_cursor: z.number(),
  }),
});
export const providerNotesSchema = z.array(scribeSchema);

export const noteContentSchema = z.string();

export const noteTemplateSchema = z.object({
  note_template_id: z.number(),
  customer_id: z.number(),
  name: z.string(),
  note_language: z.string().nullable(),
  additional_toggles: z.array(z.string()).nullable(),
  custom_instructions: z.string().nullable(),
  is_custom: zeroOrOneSchema,
  is_hidden: zeroOrOneSchema,
  show_icd10_codes_suggestions: zeroOrOneSchema,
  deleted_at: z.string().nullable(),
});

export const noteTemplatesSchema = z.array(noteTemplateSchema);

const noteTemplateSectionSchema = z.object({
  note_template_section_id: z.number(),
  note_template_id: z.number(),
  name: z.string(),
  order: z.number(),
  detail_level: z.string(),
  section_style: z.string(),
  hide_by_default: zeroOrOneSchema,
  include_differential: zeroOrOneSchema,
  split_by_problem: zeroOrOneSchema,
  has_subsections: zeroOrOneSchema,
  subsections: z.array(z.string()).nullable(),
});

export const describedNoteTemplateSchema = noteTemplateSchema.extend({
  sections: z.array(noteTemplateSectionSchema),
});
