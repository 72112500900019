import type { Call, CallStatus } from "../call/interfaces";
import type { User } from "../user/userReducer";

export enum MessageType {
  Text = "text",
  Call = "assistant_call",
  StatusUpdate = "status_update",
}

export interface StatusUpdateData {
  assistant_call_id: number;
  created_at: string;
  status_from: CallStatus;
  status_to: CallStatus;
  status_update_id: number;
  updated_at: string;
  updated_by: User;
}

export type Message = {
  from: "doctor" | "patient";
  is_automatic: 0 | 1;
  message: string;
  send_at: string;
  sender: User;
  type: MessageType;
  data: {
    call: Call;
    status_update: StatusUpdateData;
    message: string;
  };
  is_internal: 0 | 1;
};
