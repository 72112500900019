import { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-redesign.svg";
import { onDragEnd } from "../../../../helpers/helpers";
import { useAppDispatch } from "../../../../store";
import Input from "../../../Basic/Input";
import Switch from "../../../Basic/Switch";
import TabsBg from "../../../Basic/TabsBg";
import type { NoteTemplateSection } from "../../../IndependentScribe/store/noteTemplateSlice";
import { updateTemplateSection } from "../../../IndependentScribe/store/thunks";
import Subsection from "./Subsection";

const subsectionsOptions = ["Auto", "Custom"];
const sectionStyleOptions = ["Auto", "Bullet points", "Paragraph"];
const detailLevelOptions = ["Normal", "High"];

const fieldContainerClasses =
  "w-full flex flex-wrap items-center justify-between";
const fieldLabelClasses = "text-base font-semibold mr-3";

interface TemplateSectionCardProps {
  section: NoteTemplateSection;
  removable: boolean;
  onRemoveSection: () => void;
}

const TemplateSectionCard = ({
  section,
  removable,
  onRemoveSection = () => {},
}: TemplateSectionCardProps) => {
  const [title, setTitle] = useState(section.name || "");
  const [editTitle, setEditTitle] = useState(false);
  const [selectedSubsectionsVariant, setSelectedSubsectionsVariant] = useState(
    section.has_subsections ? "Custom" : "Auto",
  );
  const [subsections, setSubsections] = useState(
    section.has_subsections && section.subsections ? section.subsections : [],
  );
  const [selectedSectionStyle, setSelectedSectionStyle] = useState(
    section.section_style === "Bullet"
      ? "Bullet points"
      : section.section_style || "Auto",
  );
  const [selectedDetailLevel, setSelectedDetailLevel] = useState(
    section.detail_level || "Normal",
  );
  const [hideByDefault, setHideByDefault] = useState(!!section.hide_by_default);
  const [splitByProblem, setSplitByProblem] = useState(
    !!section.split_by_problem,
  );
  const [includeDifferential, setIncludeDifferential] = useState(
    !!section.include_differential,
  );
  const [subsectionIds, setSubsectionIds] = useState(
    section.subsections?.map(() => uuid()) || [],
  );

  const dispatch = useAppDispatch();

  const previousValuesRef = useRef({
    style: section.section_style,
    detailLevel: section.detail_level,
    hideDefault: section.hide_by_default,
    splitProblem: section.split_by_problem,
    includeDifferential: section.include_differential,
    subsections: section.subsections,
    subsectionsVariant: section.has_subsections ? "Custom" : "Auto",
  });

  useEffect(() => {
    const prevValues = previousValuesRef.current;

    const hasChanges =
      prevValues.style !== selectedSectionStyle ||
      prevValues.detailLevel !== selectedDetailLevel ||
      !!prevValues.hideDefault !== hideByDefault ||
      !!prevValues.splitProblem !== splitByProblem ||
      !!prevValues.includeDifferential !== includeDifferential ||
      prevValues.subsectionsVariant !== selectedSubsectionsVariant ||
      (selectedSubsectionsVariant === "Custom" &&
        JSON.stringify(prevValues.subsections) !== JSON.stringify(subsections));

    if (hasChanges) {
      handleUpdateSection();
      previousValuesRef.current = {
        style: selectedSectionStyle,
        detailLevel: selectedDetailLevel,
        hideDefault: hideByDefault,
        splitProblem: splitByProblem,
        includeDifferential: includeDifferential,
        subsections: [...subsections],
        subsectionsVariant: selectedSubsectionsVariant,
      };
    }
  }, [
    selectedSectionStyle,
    selectedDetailLevel,
    hideByDefault,
    splitByProblem,
    includeDifferential,
    subsections,
    selectedSubsectionsVariant,
  ]);

  const handleSubsectionsVariantChange = (tab) => {
    setSelectedSubsectionsVariant(tab);
    if (tab === "Auto") {
      setSubsections([]);
      setSubsectionIds([]);
    } else if (tab === "Custom") {
      const newSubsections =
        section.subsections?.map((subsection) => subsection) || [];
      setSubsections(newSubsections);
      setSubsectionIds(newSubsections.map(() => uuid()));
    }
  };

  const handleUpdateSection = () => {
    const updatedSubsections =
      selectedSubsectionsVariant === "Custom" ? subsections : [];

    dispatch(
      updateTemplateSection(
        section.note_template_id,
        section.note_template_section_id,
        title,
        selectedSectionStyle,
        hideByDefault,
        updatedSubsections,
        selectedDetailLevel,
        splitByProblem,
        includeDifferential,
      ),
    );
  };

  const handleUpdateSubsection = (newName, id) => {
    const newSubsections = [...subsections];
    newSubsections[id] = newName;
    setSubsections(newSubsections);
  };

  const handleRemoveSubsection = (index: number) => {
    const newSubsections = [...subsections];
    const newSubsectionIds = [...subsectionIds];
    newSubsections.splice(index, 1);
    newSubsectionIds.splice(index, 1);
    setSubsections(newSubsections);
    setSubsectionIds(newSubsectionIds);
  };

  return (
    <div className="w-full p-5 rounded-lg bg-white border border-gray-foreground">
      <div className="w-full flex items-center space-x-2 mb-4">
        <div className="h-10 w-full flex items-center">
          {editTitle ? (
            <Input
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => {
                if (title.trim() !== "") {
                  setEditTitle(false);
                  if (section.name !== title) {
                    handleUpdateSection();
                  }
                }
              }}
              className="w-full"
              inputClassName="text-base h-10"
              placeholder="Enter section title"
            />
          ) : (
            <button
              type="button"
              className="w-full font-Inter text-xl font-medium uppercase tracking-wider text-left"
              onClick={() => setEditTitle(true)}
            >
              {title}
            </button>
          )}
        </div>

        {removable && (
          <button type="button" onClick={onRemoveSection}>
            <TrashIcon width="20" height="20" />
          </button>
        )}
      </div>

      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Subsections</p>
        <TabsBg
          tabs={subsectionsOptions}
          activeTab={selectedSubsectionsVariant}
          onChange={handleSubsectionsVariantChange}
          className="bg-gray-150 text-sm w-38"
          tabClassName="py-1.5 text-center whitespace-nowrap"
        />
      </div>
      {selectedSubsectionsVariant === "Custom" && (
        <div className="mt-2 w-full bg-gray-background p-4 rounded-xl">
          <DragDropContext
            onDragEnd={(result) => {
              const reorderedIds = [...subsectionIds];
              const [removedId] = reorderedIds.splice(result.source.index, 1);
              if (result.destination) {
                reorderedIds.splice(result.destination.index, 0, removedId);
                setSubsectionIds(reorderedIds);

                const newSubsections = [...subsections];
                const [removedSubsection] = newSubsections.splice(
                  result.source.index,
                  1,
                );
                newSubsections.splice(
                  result.destination.index,
                  0,
                  removedSubsection,
                );
                setSubsections(newSubsections);
              }
            }}
          >
            <Droppable
              droppableId={`droppable-subsections-${subsections.join("-")}`}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-3"
                >
                  {subsections?.map((subsection: string, index) => (
                    <Subsection
                      key={subsectionIds[index]}
                      subsection={subsection}
                      subsectionId={index}
                      onChangeName={(newName) =>
                        handleUpdateSubsection(newName, index)
                      }
                      onRemove={() => handleRemoveSubsection(index)}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <button
            type="button"
            className={`flex items-center space-x-2 ${subsections.length > 0 ? "mt-3" : ""}`}
            onClick={() => {
              setSubsections([...subsections, ""]);
              setSubsectionIds([...subsectionIds, uuid()]);
            }}
          >
            <PlusIcon width="20" height="20" />
            <p className="text-primary-blue text-sm font-semibold">New entry</p>
          </button>
        </div>
      )}

      <div className="mt-6 w-full flex flex-col space-y-1 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between">
        <p className={fieldLabelClasses}>Section style</p>
        <TabsBg
          tabs={sectionStyleOptions}
          activeTab={selectedSectionStyle}
          onChange={(tab) => setSelectedSectionStyle(tab)}
          className="bg-gray-150 text-sm w-full sm:w-80 ml-auto"
          tabClassName="py-1.5 text-center whitespace-nowrap"
        />
      </div>

      {(title.trim().toLowerCase() === "hpi" ||
        title.trim().toLowerCase() === "history of present illness" ||
        title.trim().toLowerCase() === "subjective") && (
        <div className={`mt-6 ${fieldContainerClasses}`}>
          <p className={fieldLabelClasses}>Detail level</p>
          <TabsBg
            tabs={detailLevelOptions}
            activeTab={selectedDetailLevel}
            onChange={(tab) => setSelectedDetailLevel(tab)}
            className="bg-gray-150 text-sm w-38"
            tabClassName="py-1.5 text-center whitespace-nowrap"
          />
        </div>
      )}

      {(title.trim().toLowerCase() === "hpi" ||
        title.trim().toLowerCase() === "history of present illness" ||
        title.trim().toLowerCase() === "subjective") && (
        <div className={`mt-6 ${fieldContainerClasses}`}>
          <p className={fieldLabelClasses}>Split by problem</p>
          <Switch
            size="medium-large"
            enabled={splitByProblem}
            onChange={() => setSplitByProblem(!splitByProblem)}
          />
        </div>
      )}

      {(title.trim().toLowerCase() === "assessment and plan" ||
        title.trim().toLowerCase() === "plan") && (
        <div className={`mt-6 ${fieldContainerClasses}`}>
          <p className={fieldLabelClasses}>Include differential diagnosis</p>
          <Switch
            size="medium-large"
            enabled={includeDifferential}
            onChange={() => setIncludeDifferential(!includeDifferential)}
          />
        </div>
      )}

      <div className={`mt-6 ${fieldContainerClasses}`}>
        <p className={fieldLabelClasses}>Hide section by default</p>
        <Switch
          size="medium-large"
          enabled={hideByDefault}
          onChange={() => setHideByDefault(!hideByDefault)}
        />
      </div>
    </div>
  );
};

export default TemplateSectionCard;
