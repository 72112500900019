import {
  ADD_FAILED_MESSAGE,
  ADD_SENT_MESSAGE,
  COLLAPSE_SIDEBAR,
  EXPAND_PANEL,
  REMOVE_FAILED_MESSAGE,
  REMOVE_SENT_MESSAGE,
  SET_ACTIVE_FILTERS,
  SET_ACTIVE_INBOX,
  SET_CAMPAIGN_OPTIONS,
  SET_CLOSED_RESPONSES,
  SET_COLLAPSE_LIST,
  SET_CONVERSATIONS,
  SET_CONVERSATIONS_COMPLETED,
  SET_CONVERSATIONS_COMPLETED_TEMP,
  SET_CONVERSATIONS_COUNTS,
  SET_CONVERSATIONS_REVIEW,
  SET_CONVERSATIONS_SCROLL_POSITION,
  SET_CONVERSATIONS_UPDATED,
  SET_CONV_CURR_PAGE,
  SET_FILTERS_UPDATED,
  SET_INIT_FILTERS,
  SET_LOADER,
  SET_MESSAGES,
  SET_NEXT_CONVERSATION_ID,
  SET_NUM_CONV_BY_DOCTOR,
  SET_SEARCH_INPUT,
  SET_SELECTED_CONVERSATION_PATIENT,
  SET_SELECTED_CONVERSATION_TAB,
  SET_SELECTED_DOCTOR_INBOX,
  SET_SENT_MESSAGES,
} from "../types";

export function setConversationsReviewAction(conversationsReview) {
  return {
    type: SET_CONVERSATIONS_REVIEW,
    payload: {
      conversationsReview: conversationsReview,
    },
  };
}

export function setConversationsAction(conversations) {
  return {
    type: SET_CONVERSATIONS,
    payload: {
      conversations: conversations,
    },
  };
}

export function setConversationsScrollPositionAction(
  conversationsScrollPosition,
) {
  return {
    type: SET_CONVERSATIONS_SCROLL_POSITION,
    payload: {
      conversationsScrollPosition: conversationsScrollPosition,
    },
  };
}

export function setConvCurrPageAction(convCurrPage) {
  return {
    type: SET_CONV_CURR_PAGE,
    payload: {
      convCurrPage: convCurrPage,
    },
  };
}

export function setConversationsCompletedAction(conversationsCompleted) {
  return {
    type: SET_CONVERSATIONS_COMPLETED,
    payload: {
      conversationsCompleted: conversationsCompleted,
    },
  };
}

export function setActiveInboxAction(activeInbox) {
  return {
    type: SET_ACTIVE_INBOX,
    payload: {
      activeInbox: activeInbox,
    },
  };
}

export function setConversationsCompletedTempAction(
  conversationsCompletedTemp,
) {
  return {
    type: SET_CONVERSATIONS_COMPLETED_TEMP,
    payload: {
      conversationsCompletedTemp: conversationsCompletedTemp,
    },
  };
}

export function setNextConversationIdAction(conversationId) {
  return {
    type: SET_NEXT_CONVERSATION_ID,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function expandPanelAction(state) {
  return {
    type: EXPAND_PANEL,
    payload: {
      state: state,
    },
  };
}

export function collapseSidebarAction(state) {
  return {
    type: COLLAPSE_SIDEBAR,
    payload: {
      state: state,
    },
  };
}

export function setLoaderAction(loader) {
  return {
    type: SET_LOADER,
    payload: {
      loader: loader,
    },
  };
}

export function setCollapseListAction(state) {
  return {
    type: SET_COLLAPSE_LIST,
    payload: {
      state: state,
    },
  };
}

export function setActiveFiltersAction(activeFilters) {
  return {
    type: SET_ACTIVE_FILTERS,
    payload: {
      activeFilters: activeFilters,
    },
  };
}

export function setInitFiltersAction(initFilters) {
  return {
    type: SET_INIT_FILTERS,
    payload: {
      initFilters: initFilters,
    },
  };
}

export function setCampaignOptionsAction(campaignFilterOptions) {
  return {
    type: SET_CAMPAIGN_OPTIONS,
    payload: {
      campaignFilterOptions: campaignFilterOptions,
    },
  };
}

export function setSearchInputAction(conversationSearchInput) {
  return {
    type: SET_SEARCH_INPUT,
    payload: {
      conversationSearchInput: conversationSearchInput,
    },
  };
}

export function setSentMessagesAction(sentMessages) {
  return {
    type: SET_SENT_MESSAGES,
    payload: {
      sentMessages: sentMessages,
    },
  };
}

export function addSentMessageAction(
  message: string,
  conversationId: number,
  isInternal: boolean,
) {
  return {
    type: ADD_SENT_MESSAGE,
    payload: {
      message: message,
      conversationId: conversationId,
      isInternal: isInternal,
    },
  };
}

export function removeSentMessageAction(conversationId) {
  return {
    type: REMOVE_SENT_MESSAGE,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function addFailedMessageAction(message, conversationId) {
  return {
    type: ADD_FAILED_MESSAGE,
    payload: {
      message: message,
      conversationId: conversationId,
    },
  };
}

export function removeFailedMessageAction(messageId, conversationId) {
  return {
    type: REMOVE_FAILED_MESSAGE,
    payload: {
      messageId: messageId,
      conversationId: conversationId,
    },
  };
}

export function setClosedResponsesAction(conversationId) {
  return {
    type: SET_CLOSED_RESPONSES,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function setConversationsUpdatedAction(conversationsUpdated) {
  return {
    type: SET_CONVERSATIONS_UPDATED,
    payload: {
      conversationsUpdated: conversationsUpdated,
    },
  };
}

export function setConversationsCountsAction(inbox, conversationsCounts) {
  return {
    type: SET_CONVERSATIONS_COUNTS,
    payload: {
      inbox: inbox,
      conversationsCounts: conversationsCounts,
    },
  };
}

export function setFiltersUpdatedAction(filtersUpdated) {
  return {
    type: SET_FILTERS_UPDATED,
    payload: {
      filtersUpdated: filtersUpdated,
    },
  };
}

export function setSelectedDoctorInboxAction(selectedDoctorInbox) {
  return {
    type: SET_SELECTED_DOCTOR_INBOX,
    payload: {
      selectedDoctorInbox: selectedDoctorInbox,
    },
  };
}

export function setNumOfConvByDoctorIdAction(numOfConvByDoctorId) {
  return {
    type: SET_NUM_CONV_BY_DOCTOR,
    payload: {
      numOfConvByDoctorId: numOfConvByDoctorId,
    },
  };
}

export function setSelectedConversationTabAction(selectedConversationTab) {
  return {
    type: SET_SELECTED_CONVERSATION_TAB,
    payload: {
      selectedConversationTab: selectedConversationTab,
    },
  };
}

export function setMessagesAction(messages) {
  return {
    type: SET_MESSAGES,
    payload: {
      messages: messages,
    },
  };
}

export function setSelectedConversationPatientAction(
  selectedConversationPatient,
) {
  return {
    type: SET_SELECTED_CONVERSATION_PATIENT,
    payload: {
      selectedConversationPatient: selectedConversationPatient,
    },
  };
}
