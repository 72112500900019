import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useRef } from "react";
import { ReactComponent as EmptySummary } from "../../../../assets/empty-summary.svg";
import { ReactComponent as WandIcon } from "../../../../assets/icons/wand.svg";
import { convertToHtmlList } from "../../../../helpers/helpers";
import { useAppSelector } from "../../../../store";
import { useGetConversationQuery } from "../../../../store/call/api";
import { getSelectedConversationId } from "../../../../store/call/callSlice";

const ChatSummary = () => {
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const summaryRef = useRef(null);

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ?? skipToken,
  );

  useEffect(() => {
    if (currentConversation.summary && summaryRef.current) {
      summaryRef.current.innerHTML = convertToHtmlList(
        currentConversation.summary,
      );
    }
  }, [currentConversation.summary]);

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <p className="font-semibold">Chat Summary</p>
        <WandIcon width="18" height="18" stroke="#667085" />
      </div>
      <div className="p-4 bg-gray-background rounded-lg pr-2 min-h-[224px] overflow-hidden">
        {currentConversation.summary ? (
          <div className="h-fit overflow-y-auto scrollbar pr-2 max-h-full text-sm font-medium leading-normal whitespace-pre-wrap">
            <p ref={summaryRef} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-47">
            <EmptySummary width="101" height="100" />
            {currentConversation.consent_status !== "no" && (
              <p className="font-normal text-neutral-500">
                Conversation in progress
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatSummary;
