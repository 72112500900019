import { useState } from "react";
import { ReactComponent as BuildingIcon } from "../../../assets/icons/building.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-call.svg";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import type { User } from "../../../store/user/userReducer";
import ClinicInfoCard from "./ClinicInfoCard";
import EditClinicModal from "./EditClinicModal";

interface ClinicInfoProps {
  user: User;
  editable: boolean;
}

const ClinicInfo = ({ user, editable }: ClinicInfoProps) => {
  const [openClinicModal, setOpenClinicModal] = useState(false);

  return (
    <>
      <div className="relative mt-3">
        <div className="hidden xl:flex flex-col p-2 h-fit w-full rounded-lg bg-white border border-gray-foreground">
          <ClinicInfoCard
            icon={<BuildingIcon width="24" height="24" className="flex-none" />}
            title="Clinic Name"
            value={user.customer.name}
          />
          <hr className="border-gray-foreground m-2" />
          <ClinicInfoCard
            icon={<BuildingIcon width="24" height="24" className="flex-none" />}
            title="Clinic Display Name"
            value={user.customer.display_name}
          />
          <hr className="border-gray-foreground m-2" />
          <ClinicInfoCard
            icon={<PhoneIcon width="24" height="24" className="flex-none" />}
            title="Clinic Number"
            value={getFormattedPhoneNumber(user.customer.phone_number) || "-"}
          />
          <hr className="border-gray-foreground m-2" />
          <ClinicInfoCard
            icon={<LocationIcon width="24" height="24" className="flex-none" />}
            title="Clinic Address"
            value={user.customer.clinic_address}
          />
        </div>

        <div className="flex items-center xl:hidden px-5 text-xl font-semibold h-8">
          {user.customer.display_name || user.customer.name}
        </div>

        {editable && (
          <button
            type="button"
            className="absolute top-0 xl:top-4 right-4 z-10 w-8 h-8 flex items-center justify-center rounded-lg
            bg-white border border-gray-foreground"
            onClick={() => setOpenClinicModal(true)}
          >
            <EditIcon width="16" height="16" stroke="#2970FF" />
          </button>
        )}
      </div>

      <EditClinicModal
        user={user}
        open={openClinicModal}
        onClose={() => setOpenClinicModal(false)}
      />
    </>
  );
};

export default ClinicInfo;
