import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { copyText } from "../../helpers/helpers";
import { setNotificationAction } from "../../store/user/actions";
import Button from "../Basic/Button";
import TextEditor from "../Basic/TextEditor";

interface NoteContentProps {
  className?: string;
  editorClassName?: string;
  onClose: () => void;
  onSave: (editedNote: string) => void;
  patientName: string;
  name: string;
  note: string;
}

const NoteContent = ({
  className = "",
  editorClassName = "h-30vh-dynamic height-xs:h-36vh-dynamic height-sm:h-58vh-dynamic max-h-full",
  onClose,
  onSave,
  patientName,
  name,
  note,
}: NoteContentProps) => {
  const [editedNote, setEditedNote] = useState(note);
  const dispatch = useDispatch();

  const handleCopyNote = () => {
    copyText(editedNote, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  return (
    <div className={`text-sm ${className}`}>
      <div className="px-5 text-xl md:text-2xl font-semibold mb-5">
        {patientName}
      </div>

      <div className="bg-gray-110 flex items-center justify-between px-5 py-3">
        <p className="text-base font-semibold">{name}</p>
        <button
          type="button"
          onClick={handleCopyNote}
          className="bg-white rounded-lg border border-gray-foreground w-9 h-9 flex items-center justify-center"
        >
          <CopyIcon width="17" height="17" />
        </button>
      </div>

      <TextEditor
        htmlMarkup={note}
        onChange={(htmlMarkup) => setEditedNote(htmlMarkup)}
        className={editorClassName}
        rerenderDeps={[note]}
      />

      <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold mt-auto">
        <Button variant="gray" onClick={onClose} className="w-full md:w-fit">
          Close
        </Button>
        <Button className="w-full md:w-fit" onClick={() => onSave(editedNote)}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default NoteContent;
