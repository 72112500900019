import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { twoConditionsRegex } from "../../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { baseApi } from "../../../../store/baseApi";
import { useGetConversationQuery } from "../../../../store/call/api";
import { getSelectedConversationId } from "../../../../store/call/callSlice";
import { updateCampaign } from "../../../../store/campaign/thunks";
import Button from "../../../Basic/Button";
import TextareaAutosizeForm from "../../../Basic/TextareaAutosizeForm";
import TextareaOrderedLines from "../../../Basic/TextareaOrderedLines";

const blockClasses = "space-y-0.5";
const titleClasses = "text-sm font-semibold text-primary";
const textClasses = "text-sm font-medium whitespace-pre-wrap text-tertiary";

const OutreachFocus = () => {
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ?? skipToken,
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const resetFields = useCallback(() => {
    if (!currentConversation) {
      return;
    }

    setValue("chronicConditions", currentConversation.outreach_focus || "");
    setValue("goals", currentConversation.outreach_goals || "");
    setValue(
      "pertinentMedicalHistory",
      currentConversation.ehr_information || "",
    );
    clearErrors("chronicConditions");
  }, [currentConversation, setValue, clearErrors]);

  const onCancel = useCallback(() => {
    resetFields();
    setEditMode(false);
  }, [resetFields]);

  useEffect(() => {
    if (selectedConversationId) {
      onCancel();
    }
  }, [selectedConversationId, onCancel]);

  const onSubmit = (data) => {
    if (
      currentConversation.campaign_type === "Outreach" &&
      !twoConditionsRegex.test(getValues("chronicConditions"))
    ) {
      setError("chronicConditions", {
        type: "pattern",
        message: "Please provide at least two conditions",
      });
      return;
    }

    dispatch(
      updateCampaign(
        currentConversation.campaign_id,
        [currentConversation.patient.patient_id],
        data.chronicConditions,
        data.goals,
        data.pertinentMedicalHistory,
        true,
        () =>
          dispatch(
            baseApi.util.invalidateTags([
              { type: "Conversation", id: selectedConversationId },
            ]),
          ),
      ),
    );
    setEditMode(false);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <p className="font-semibold">Outreach Focus</p>
        <button
          type="button"
          onClick={() => setEditMode(true)}
          className="rounded-full p-1.5 hover:bg-gray-150"
        >
          <EditIcon stroke="#2970FF" width="16" height="16" />
        </button>
      </div>
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="p-4 bg-gray-background rounded-lg overflow-hidden space-y-4 ">
          {editMode ? (
            <TextareaOrderedLines
              name="chronicConditions"
              label="Conditions"
              register={register}
              setValue={setValue}
              required={
                currentConversation.campaign_type === "Outreach"
                  ? "Please provide at least two conditions"
                  : "Please provide at least one condition"
              }
              error={errors.chronicConditions}
            />
          ) : (
            <div className={blockClasses}>
              <p className={titleClasses}>Conditions</p>
              <p className={textClasses}>
                {!getValues("chronicConditions")
                  ? "N/A"
                  : getValues("chronicConditions")}
              </p>
            </div>
          )}
          <div className={blockClasses}>
            <p className={titleClasses}>Goals</p>
            {editMode ? (
              <TextareaAutosizeForm name="goals" register={register} />
            ) : (
              <p className={textClasses}>
                {!getValues("goals") ? "N/A" : getValues("goals")}
              </p>
            )}
          </div>
          <div className={blockClasses}>
            <p className={titleClasses}>Pertinent Past Medical History</p>
            {editMode ? (
              <TextareaAutosizeForm
                name="pertinentMedicalHistory"
                register={register}
              />
            ) : (
              <p className={textClasses}>
                {!getValues("pertinentMedicalHistory")
                  ? "N/A"
                  : getValues("pertinentMedicalHistory")}
              </p>
            )}
          </div>
          {editMode && (
            <div className="flex space-x-3 justify-between font-semibold">
              <Button
                variant="cancel"
                className="w-full"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" className="w-full" type="submit">
                Update
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default OutreachFocus;
