import { debounce } from "@mui/material/utils";
import axios, { AxiosError, AxiosResponse } from "axios";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { BASE_URL } from "../../../helpers/config";
import {
  getAge,
  handleRequestError,
  selectInputText,
} from "../../../helpers/helpers";
import { useAppDispatch } from "../../../store";
import { setNotificationAction } from "../../../store/user/actions";
import AddEditPatientModal from "../../Basic/AddEditPatientModal";
import Loader from "../../Basic/Loader";
import LoaderDots from "../../Basic/LoaderDots";
import Modal from "../../Basic/Modal";
import ProfilePicture from "../../Basic/ProfilePicture";
import UserPlusRoundedIcon from "../UserPlusRoundedIcon";
import type { FixLater } from "../store/scribeSlice";

const ITEM_HEIGHT = 52;
const LOADING_INDICATOR_HEIGHT = 40;
const ITEMS_PER_PAGE = 50;

// Memoize the search input component
const SearchInput = memo(
  ({
    value,
    onChange,
    isLoading,
  }: {
    value: string;
    onChange: (value: string) => void;
    isLoading: boolean;
  }) => (
    <div className="relative w-full md:max-w-lg h-fit">
      <input
        onClick={selectInputText}
        value={value}
        className="h-10 text-sm outline-none p-2.5 pr-10 border border-primary-blue w-full rounded-lg caret-primary-blue"
        placeholder="Search patient"
        onChange={(e) => onChange(e.target.value)}
      />
      {isLoading ? (
        <div className="absolute right-2.5 pt-1 top-1/2 -translate-y-1/2">
          <Loader size={5} borderWidth={2} />
        </div>
      ) : (
        <SearchIcon
          width="20"
          height="20"
          className="absolute top-2.5 right-3.5"
          stroke="#667085"
        />
      )}
    </div>
  ),
);

const PatientRow = memo(
  ({
    patient,
    isSelected,
    onSelect,
    onAssign,
    style,
  }: {
    patient: any;
    isSelected: boolean;
    onSelect: (patient: any) => void;
    onAssign: (patientId: number) => void;
    style: React.CSSProperties;
  }) => (
    <div
      style={style}
      onClick={() => onSelect(patient)}
      onDoubleClick={() => onAssign(patient.patient_id)}
      className={`grid grid-cols-2-right items-center gap-3 hover:bg-gray-110 rounded-lg p-2 cursor-pointer w-full overflow-hidden
    ${isSelected ? "bg-[#d5dce3] hover:bg-[#d5dce3]" : ""}`}
    >
      <ProfilePicture
        src={patient.photo}
        firstName={patient.first_name}
        lastName={patient.last_name}
        flagPadding={false}
      />
      <div className="flex flex-col justify-center w-full truncate">
        <p className="font-semibold text-sm truncate">
          {patient.first_name} {patient.last_name}
        </p>
        <p className="text-xs text-gray-500">
          {[
            `${patient.age} yo`,
            patient.sexAbbreviation,
            patient.pronouns,
            patient.birthdate
              ? new Date(patient.birthdate).toLocaleDateString(undefined, {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })
              : "",
          ]
            .filter(Boolean)
            .join(" • ")}
        </p>
      </div>
    </div>
  ),
);

const LoadingRow = memo(({ style }: { style: React.CSSProperties }) => (
  <div style={style} className="w-full h-10 flex items-center justify-center">
    <LoaderDots />
  </div>
));

// Memoize the footer component
const ModalFooter = memo(
  ({
    onClose,
    onAssign,
    selectedPatientId,
  }: {
    onClose: () => void;
    onAssign: (patientId: number) => void;
    selectedPatientId: number;
  }) => (
    <footer className="flex bg-gray-background justify-end gap-4 px-5 py-4 font-semibold h-fit">
      <button
        type="button"
        onClick={onClose}
        className="py-1.5 md:py-2.5 px-4 rounded-lg border border-gray-foreground bg-white"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={() => onAssign(selectedPatientId)}
        disabled={selectedPatientId === -1}
        className="py-1.5 md:py-2.5 px-6 bg-primary-blue text-white rounded-lg disabled:opacity-70"
      >
        Select Patient
      </button>
    </footer>
  ),
);

const AssignPatientModal = memo(
  ({
    handleClose,
    handleAssignPatientId,
  }: {
    handleClose: () => void;
    handleAssignPatientId: (patientId: number) => void;
  }) => {
    const dispatch = useAppDispatch();
    const [selectedPatientId, setSelectedPatientId] = useState(-1);
    const [openCreatePatientModal, setOpenPatientModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [patients, setPatients] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [containerHeight, setContainerHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<VariableSizeList>(null);
    const isLoadingRef = useRef(false);

    const fetchPatients = useCallback(
      async (filter: string, page: number) => {
        if (isLoadingRef.current) {
          return null;
        }

        try {
          isLoadingRef.current = true;
          setIsLoading(true);

          const response = await axios.post(`${BASE_URL}/patient/search`, {
            q: filter,
            offset: (page - 1) * ITEMS_PER_PAGE,
            limit: ITEMS_PER_PAGE,
            doctor_id: null,
            unassigned: false,
            campaigns: [],
            status: [],
          });

          if (response.data.error) {
            dispatch(
              setNotificationAction({
                status: "error",
                title: "Something went wrong",
                desc: "Failed to load patients",
              }),
            );
            return null;
          }

          const newPatients = response.data.data.patients.map((patient) => ({
            ...patient,
            age: getAge(patient.birthdate),
            sexAbbreviation:
              patient.sex === "female"
                ? "F"
                : patient.sex === "male"
                  ? "M"
                  : "",
          }));

          return {
            patients: newPatients,
            hasMore: newPatients.length === ITEMS_PER_PAGE,
          };
        } catch (error: any) {
          handleRequestError(error, dispatch, "Failed to load patients");
          return null;
        } finally {
          isLoadingRef.current = false;
          setIsLoading(false);
        }
      },
      [dispatch],
    );

    const debouncedSearch = useMemo(
      () =>
        debounce(async (value) => {
          if (listRef.current) {
            listRef.current.scrollTo(0);
          }
          const result = await fetchPatients(value, 1);
          if (result) {
            setPatients(result.patients);
            setHasMore(result.hasMore);
          }
        }, 300),
      [fetchPatients],
    );

    const loadMoreItems = useCallback(
      async (startIndex: number) => {
        if (isLoadingRef.current || !hasMore) {
          return Promise.resolve();
        }

        const page = Math.floor(startIndex / ITEMS_PER_PAGE) + 1;
        const result = await fetchPatients(searchValue, page);

        if (result) {
          setPatients((prev) => [...prev, ...result.patients]);
          setHasMore(result.hasMore);
        }

        return Promise.resolve();
      },
      [fetchPatients, hasMore, searchValue],
    );

    const handleSelectPatient = useCallback((patient) => {
      setSelectedPatientId((prevId) =>
        prevId === patient.patient_id ? -1 : patient.patient_id,
      );
    }, []);

    const handleAssign = useCallback(
      (patientId: number) => {
        handleAssignPatientId(patientId);
        handleClose();
      },
      [handleAssignPatientId, handleClose],
    );

    const virtualizedListConfig = useMemo(
      () => ({
        itemCount: hasMore ? patients.length + 1 : patients.length,
        isItemLoaded: (index: number) => !hasMore || index < patients.length,
        getItemSize: (index: number) =>
          index < patients.length ? ITEM_HEIGHT : LOADING_INDICATOR_HEIGHT,
        loadMoreItems,
      }),
      [hasMore, patients.length, loadMoreItems],
    );

    const renderItem = useCallback(
      ({ index, style }) => {
        if (!virtualizedListConfig.isItemLoaded(index)) {
          return <LoadingRow style={style} />;
        }

        const patient = patients[index];
        return (
          <PatientRow
            key={patient.patient_id}
            patient={patient}
            isSelected={selectedPatientId === patient.patient_id}
            onSelect={handleSelectPatient}
            onAssign={handleAssign}
            style={style}
          />
        );
      },
      [
        patients,
        selectedPatientId,
        handleSelectPatient,
        handleAssign,
        virtualizedListConfig.isItemLoaded,
      ],
    );

    useEffect(() => {
      let lastHeight = 0;
      const updateContainerHeight = debounce(() => {
        if (containerRef.current) {
          const height = Math.floor(containerRef.current.clientHeight);
          if (Math.abs(height - lastHeight) > 1) {
            lastHeight = height;
            setContainerHeight(height);
          }
        }
      }, 100);

      const resizeObserver = new ResizeObserver(updateContainerHeight);

      if (containerRef.current) {
        const initialHeight = Math.floor(containerRef.current.clientHeight);
        lastHeight = initialHeight;
        setContainerHeight(initialHeight);
        resizeObserver.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
        updateContainerHeight.clear();
      };
    }, []);

    const handleSearchInput = useCallback(
      (newSearchValue: string) => {
        setSearchValue(newSearchValue);
        setPatients([]);
        setHasMore(true);
        debouncedSearch(newSearchValue);
      },
      [debouncedSearch],
    );

    const getStableHeight = useCallback(() => {
      return Math.floor(containerHeight); // Round down to prevent floating point issues
    }, [containerHeight]);

    const ListComponent = useMemo(
      () => (
        <InfiniteLoader
          isItemLoaded={virtualizedListConfig.isItemLoaded}
          itemCount={virtualizedListConfig.itemCount}
          loadMoreItems={virtualizedListConfig.loadMoreItems}
          threshold={2}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              ref={(list) => {
                listRef.current = list;
                if (typeof ref === "function") {
                  ref(list);
                }
              }}
              height={getStableHeight()}
              itemCount={virtualizedListConfig.itemCount}
              itemSize={virtualizedListConfig.getItemSize}
              width="100%"
              onItemsRendered={onItemsRendered}
              overscanCount={5}
            >
              {renderItem}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      ),
      [virtualizedListConfig, getStableHeight, renderItem],
    );

    useEffect(() => {
      if (listRef.current && patients.length > 0) {
        listRef.current.resetAfterIndex(0, false);
      }
    }, [patients]);

    return (
      <Modal className="flex-col w-full md:w-110 h-full height-md:max-h-[650px] rounded-2xl min-w-20 scrollbar">
        <div className="grid grid-rows-[auto_1fr_auto] h-full w-full pt-4 gap-1 overflow-hidden">
          <header className="flex font-semibold w-full md:w-110 justify-between items-center px-5 h-fit">
            <h1 className="text-xl">Assign Patient</h1>
            <button
              type="button"
              className="text-sm bg-primary-blue-light text-primary-blue flex items-center justify-center rounded-lg py-2 px-4 gap-3"
              onClick={() => setOpenPatientModal(true)}
            >
              <UserPlusRoundedIcon stroke="#2970FF" />
              <p className="hidden sm:block">Add New Patient</p>
            </button>
          </header>

          <main className="px-5 h-full grid grid-rows-tab-layout overflow-hidden">
            <SearchInput
              value={searchValue}
              onChange={handleSearchInput}
              isLoading={isLoading}
            />

            <div className="overflow-hidden h-full w-full" ref={containerRef}>
              {ListComponent}
            </div>
          </main>

          <ModalFooter
            onClose={handleClose}
            onAssign={handleAssign}
            selectedPatientId={selectedPatientId}
          />
        </div>

        <AddEditPatientModal
          open={openCreatePatientModal}
          onClose={() => {
            setOpenPatientModal(false);
            debouncedSearch(searchValue);
          }}
          patientInfo={null}
        />
      </Modal>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if the functions actually changed their reference
    return (
      prevProps.handleClose === nextProps.handleClose &&
      prevProps.handleAssignPatientId === nextProps.handleAssignPatientId
    );
  },
);

export default AssignPatientModal;
