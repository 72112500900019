import { CallCategory, type CallTags } from "../../../store/call/interfaces";
import Checkbox from "../../Basic/Checkbox";
import { type FilterParams, useFilterParams } from "../hooks/useFilterParams";
import FilterButton from "./FilterButton";

interface ReasonFilterProps {
  className?: string;
}

const ReasonFilter = ({ className = "" }: ReasonFilterProps) => {
  const { filters, updateFilters } = useFilterParams();

  const handleSelectReason = (reason: CallCategory) => {
    const updatedReasons = filters.reasons.includes(reason)
      ? filters.reasons.filter((item) => item !== reason)
      : [...filters.reasons, reason];

    updateFilters({
      reasons: updatedReasons,
      page: 1, // Reset to first page when changing filters
    });
  };

  const handleSelectTag = (tag: keyof CallTags) => {
    const updates: Partial<FilterParams> = {
      ...(tag === "is_new_user" && { isNewUser: !filters.isNewUser }),
      ...(tag === "is_urgent" && { isUrgent: !filters.isUrgent }),
      page: 1,
    };

    updateFilters(updates);
  };

  return (
    <FilterButton
      className={className}
      label="Reason"
      numberOfSelectedFilters={
        filters.reasons.length +
        Number(filters.isNewUser) +
        Number(filters.isUrgent)
      }
    >
      <div className="space-y-3" aria-label="Reason filter">
        <p className="text-base font-medium">Reasons</p>

        {Object.values(CallCategory).map((reason) => (
          <Checkbox
            name={reason}
            key={reason}
            labelClassName="capitalize text-sm ml-3 font-medium"
            variant="primary-filled"
            label={reason}
            checked={filters.reasons.includes(reason)}
            onChange={() => handleSelectReason(reason)}
          />
        ))}

        <hr className="border-gray-foreground" />

        <p className="text-base font-medium">Tags</p>

        <Checkbox
          name="New User"
          labelClassName="capitalize text-sm ml-3 font-medium"
          variant="primary-filled"
          label="New"
          checked={filters.isNewUser}
          onChange={() => handleSelectTag("is_new_user")}
        />

        <Checkbox
          name="Urgent"
          labelClassName="capitalize text-sm ml-3 font-medium"
          variant="primary-filled"
          label="Urgent"
          checked={filters.isUrgent}
          onChange={() => handleSelectTag("is_urgent")}
        />
      </div>
    </FilterButton>
  );
};

export default ReasonFilter;
