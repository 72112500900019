import Bugsnag from "@bugsnag/js";
import { type BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig } from "axios";
import type z from "zod";
import { BASE_URL } from "../helpers/config";
import { handleRequestError } from "../helpers/helpers";

export const validateResponse = <T extends z.ZodType>(schema: T) => {
  return (data: unknown): z.infer<T> => {
    return schema.parse(data);
  };
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      Bugsnag.notify(err, (event) => {
        event.severity = "error";
        event.addMetadata("api", {
          endpoint: url,
          error: err instanceof Error ? err.message : "Unknown error",
        });
      });

      handleRequestError(axiosError);

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const baseApi = createApi({
  reducerPath: "api",
  tagTypes: ["User", "Team", "Conversation", "Call", "CallNote", "CallCounts"],
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: () => ({}),
});
