import { type ReactNode, useEffect } from "react";

const ModalMobile = ({
  open,
  childHeight = 1000,
  onClickAway = () => {},
  header,
  children,
  wrapperClassName = "",
  className = "px-4",
  bg = "white",
  ...props
}: {
  open: boolean;
  childHeight?: number;
  onClickAway?: () => void;
  header?: string | ReactNode;
  children: ReactNode;
  wrapperClassName?: string;
  className?: string;
  bg?: string;
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <div
      className={`fixed w-screen h-screen-dynamic inset-0 flex items-end cursor-default
        ${open ? "bg-black/50 z-[50]" : "bg-black/0 z-[-1]"} ${wrapperClassName}`}
      {...props}
      onClick={(e) => {
        if (open) {
          e.preventDefault();
          e.stopPropagation();
          onClickAway();
        }
      }}
    >
      <div
        style={{ height: open ? `${childHeight + 36 + 36}px` : "0" }} // + button + header height
        className={`bg-${bg} rounded-t-3xl w-full max-h-90vh-dynamic flex flex-col transition-all overflow-hidden`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          type="button"
          className={"w-full flex items-center justify-center py-4"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickAway();
          }}
        >
          <div className="h-1 w-10 bg-gray-300 rounded-full" />
        </button>
        {header && (
          <div className="text-xl font-semibold py-1 px-5">{header}</div>
        )}
        <div
          className={`bg-${bg} w-full h-full flex grow sm:grow-0 overflow-y-auto scrollbar ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalMobile;
