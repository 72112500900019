import { useMediaQuery } from "react-responsive";

interface SettingsContainerProps {
  children: React.ReactNode;
}

const SettingsContainer = ({ children }: SettingsContainerProps) => {
  const isXl = useMediaQuery({ minWidth: 1280 });

  return (
    <div className="h-full w-full md:grid overflow-y-auto bg-gray-background overflow-hidden">
      <div
        className={`overflow-y-auto scrollbar p-4 md:p-6 xl:pl-8 ${isXl ? "h-screen-dynamic" : "h-full"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default SettingsContainer;
