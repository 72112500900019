import { z } from "zod";
import {
  baseConversationSchema,
  doctorSchema,
  patientSchema,
  zeroOrOneSchema,
} from "../../helpers/commonValidationSchemas";
import { callSchema, callStatusSchema } from "../call/validationSchemas";
import { MessageType } from "./interfaces";

const messageTypeSchema = z.nativeEnum(MessageType);

const conversationDoctorSchema = z
  .object({
    doctor_id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    display_name: z.string().nullish(),
    profile_picture: z.string().nullish(),
  })
  .strict();

const conversationPatientSchema = z
  .object({
    birthdate: z.string(),
    first_name: z.string(),
    gender: z.string().nullable(),
    is_paused: zeroOrOneSchema.nullish(),
    last_name: z.string(),
    middle_name: z.string().nullable(),
    patient_id: z.number(),
    phone_number: z.string().nullable(),
    preferred_name: z.string().nullable(),
    profile_picture: z.string().nullish(),
    pronouns: z.string().nullable(),
    sex: z.string(),
  })
  .strict();

const messageDataSchema = z.object({
  message: z.string(),
});

const callDataSchema = z.object({
  call: callSchema,
});

const statusUpdateDataSchema = z.object({
  status_update: z.object({
    assistant_call_id: z.number(),
    created_at: z.string(),
    status_from: callStatusSchema,
    status_to: callStatusSchema,
    status_update_id: z.number(),
    updated_at: z.string(),
    updated_by: z
      .object({
        doctor_id: z.number(),
        first_name: z.string(),
        last_name: z.string(),
        display_name: z.string().nullish(),
        profile_picture: z.string().nullish(),
      })
      .nullish(),
  }),
});

const messageSchema = z
  .object({
    from: z.union([z.literal("patient"), z.literal("doctor")]),
    is_automatic: zeroOrOneSchema,
    is_internal: zeroOrOneSchema,
    message: z.string().nullish(),
    send_at: z.string(),
    sender: z
      .union([patientSchema.partial(), doctorSchema.partial()])
      .nullable(),
    type: messageTypeSchema,
    data: z.union([
      messageDataSchema.partial(),
      callDataSchema.partial(),
      statusUpdateDataSchema.partial(),
    ]),
  })
  .strict();

export const conversationSchema = baseConversationSchema
  .extend({
    campaign: z.string(),
    doctor: conversationDoctorSchema,
    assigned_to: conversationDoctorSchema.nullish(),
    assigned_to_team: z
      .object({
        doctor_team_id: z.number(),
        name: z.string(),
        team_icon: z.string().nullable(),
      })
      .nullish(),
    messages: z.array(messageSchema),
    patient: conversationPatientSchema,
    trackable: z.boolean(),
    waiting_response: zeroOrOneSchema,
    is_urgent: zeroOrOneSchema,
    campaign_type: z.string().nullish(),
    show_response: z.boolean().nullish(),
  })
  .strict();

const lastMessageSchema = z.object({
  conversation_id: z.number(),
  from: z.string(),
  is_answer: zeroOrOneSchema,
  is_automatic: zeroOrOneSchema,
  is_question: zeroOrOneSchema,
  message: z.string().nullable(),
  message_id: z.number(),
  read: zeroOrOneSchema,
  send_at: z.string(),
  sender_id: z.number().nullable(),
  state: z.number().nullable(),
});

const conversationPreviewSchema = z.object({
  conversation_id: z.number(),
  flag: z.string().nullable(),
  is_urgent: zeroOrOneSchema,
  lastMessage: lastMessageSchema,
  patient: conversationPatientSchema,
  unread: z.number().min(0),
});

export const conversationsSchema = z.object({
  all: z.array(conversationPreviewSchema),
  total_conversations: z.number().min(0),
});

export const conversationCountsSchema = z.object({
  UNREAD: z.number().min(0).optional(),
  "active TOTAL": z.number().min(0),
  all: z.number().min(0).optional(),
  noResponse: z.number().min(0).optional(),
  patientInitiated: z.number().min(0).optional(),
  paused: z.number().min(0).optional(),
  "review TOTAL": z.number().min(0),
  staffEngaging: z.number().min(0).optional(),
});
