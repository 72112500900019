import { useCallback, useEffect, useRef, useState } from "react";
import type { AudioSetupContext } from "../../../types/audio";
import { useAudioRecoveryStrategy } from "./useAudioRecoveryStrategy";

export interface StallDetectorConfig {
  stallThresholdFrames: number;
  timeDifferenceThreshold: number;
  checkIntervalMs: number;
  maxConsecutiveStalls: number;
  maxConsecutiveFailures: number;
}

export interface StallDetectorState {
  isStalled: boolean;
  consecutiveStalls: number;
  lastStallTime: number | null;
  averageTimeDelta: number;
}

export interface StallDetectorMetrics {
  stallCount: number;
  averageStallDuration: number;
  lastStallTime: number | null;
  isCurrentlyStalled: boolean;
}

function calculateNewAverage(
  currentAverage: number,
  newValue: number,
  count: number,
): number {
  return (currentAverage * (count - 1) + newValue) / count;
}

const DEFAULT_CONFIG: StallDetectorConfig = {
  stallThresholdFrames: 60, // Number of frames to wait before considering audio stalled
  timeDifferenceThreshold: 0.0001, // Minimum time difference to consider as movement
  checkIntervalMs: 100,
  maxConsecutiveStalls: 3, // Maximum number of consecutive stalls before triggering recovery
  maxConsecutiveFailures: 0, // TODO, disabled - needs more work. Maximum number of consecutive recovery failures before giving up
};

export function useAudioStallDetector(
  setupContext: AudioSetupContext,
  config: Partial<StallDetectorConfig> = {},
) {
  const { audioContextRef } = setupContext;

  const mergedConfig = { ...DEFAULT_CONFIG, ...config };

  const previousAudioContextTimeRef = useRef<number>(-1);
  const stallFrameCountRef = useRef<number>(0);
  const intervalRef = useRef<number | null>(null);
  const metricsRef = useRef<StallDetectorMetrics>({
    stallCount: 0,
    averageStallDuration: 0,
    lastStallTime: null,
    isCurrentlyStalled: false,
  });

  const { recover, recoveryState } = useAudioRecoveryStrategy(
    setupContext,
    (successful) => {
      if (successful) {
        console.log("Audio recovery successful");
      } else {
        console.error("Audio recovery failed");
      }
    },
  );

  const [metrics, setMetrics] = useState<StallDetectorMetrics>(
    metricsRef.current,
  );

  const handleStall = useCallback(() => {
    if (
      recoveryState.consecutiveFailures < mergedConfig.maxConsecutiveFailures
    ) {
      recover();
    } else {
      metricsRef.current = {
        ...metricsRef.current,
        stallCount: metricsRef.current.stallCount + 1,
        lastStallTime: Date.now(),
        isCurrentlyStalled: true,
      };
      setMetrics(metricsRef.current);
    }
  }, [
    recover,
    recoveryState.consecutiveFailures,
    mergedConfig.maxConsecutiveFailures,
  ]);

  // Performance optimized stall check using throttle
  const checkStalledAudioContext = useCallback(() => {
    const context = audioContextRef.current;
    if (!context) {
      return;
    }

    const currentTime = context.currentTime;
    const timeDifference = Math.abs(
      currentTime - previousAudioContextTimeRef.current,
    );

    if (timeDifference < mergedConfig.timeDifferenceThreshold) {
      stallFrameCountRef.current += 1;

      if (stallFrameCountRef.current >= mergedConfig.stallThresholdFrames) {
        // Trigger stall callback
        handleStall();
      }
    } else {
      // Reset stall counter if audio is moving
      if (stallFrameCountRef.current > 0) {
        if (metricsRef.current.isCurrentlyStalled) {
          const stallDuration =
            Date.now() - (metricsRef.current.lastStallTime || 0);
          metricsRef.current = {
            ...metricsRef.current,
            averageStallDuration: calculateNewAverage(
              metricsRef.current.averageStallDuration,
              stallDuration,
              metricsRef.current.stallCount,
            ),
            isCurrentlyStalled: false,
          };
          setMetrics(metricsRef.current);
        }
        stallFrameCountRef.current = 0;
      }
    }

    previousAudioContextTimeRef.current = currentTime;
  }, [
    audioContextRef,
    handleStall,
    mergedConfig.timeDifferenceThreshold,
    mergedConfig.stallThresholdFrames,
  ]);

  useEffect(() => {
    previousAudioContextTimeRef.current =
      audioContextRef.current?.currentTime ?? -1;

    intervalRef.current = window.setInterval(
      checkStalledAudioContext,
      mergedConfig.checkIntervalMs,
    );

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [audioContextRef, checkStalledAudioContext, mergedConfig.checkIntervalMs]);

  return {
    metrics,
    isStalled: metrics.isCurrentlyStalled,
    recoveryState,
    reset: useCallback(() => {
      stallFrameCountRef.current = 0;
      previousAudioContextTimeRef.current =
        audioContextRef.current?.currentTime ?? -1;
      metricsRef.current = {
        stallCount: 0,
        averageStallDuration: 0,
        lastStallTime: null,
        isCurrentlyStalled: false,
      };
      setMetrics(metricsRef.current);
    }, [audioContextRef.current]),
  };
}
