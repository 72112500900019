import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo } from "react";
import { rowsPerPage } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  callApi,
  selectNextConversation,
  useGetCallCountsQuery,
  useGetCallsQuery,
  useGetConversationQuery,
} from "../../../store/call/api";
import {
  getCallsCompletedTmp,
  getFilterByTeamId,
  getIsFilterByAssignedToCurrentUser,
  getIsFilterByMentions,
  getSelectedConversationId,
  removeFromCompletedTmp,
  removeFromPendingTmp,
  setSelectedConversationId,
} from "../../../store/call/callSlice";
import {
  type CallSearchParams,
  CallStatusTab,
} from "../../../store/call/interfaces";
import { setHideMobileNavbar } from "../../../store/ui/actions";
import { useFilterParams } from "./useFilterParams";

export const useCallManagement = () => {
  const { filters, updateFilters } = useFilterParams();
  const callsCompletedTmp = useAppSelector(getCallsCompletedTmp);
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const isFilterByMentions = useAppSelector(getIsFilterByMentions);
  const isFilterByAssignedToCurrentUser = useAppSelector(
    getIsFilterByAssignedToCurrentUser,
  );
  const filterByTeamId = useAppSelector(getFilterByTeamId);
  const dispatch = useAppDispatch();

  const searchParams: CallSearchParams = useMemo(() => {
    return {
      limit: rowsPerPage,
      offset: (filters.page - 1) * rowsPerPage,
      q: filters.search,
      direction: filters.direction === "all" ? "" : filters.direction,
      categories: filters.reasons,
      is_new_user: filters.isNewUser,
      is_urgent: filters.isUrgent,
      has_mention: isFilterByMentions ? 1 : 0,
      assigned_to_me: isFilterByAssignedToCurrentUser ? 1 : 0,
      team_id: filterByTeamId,
    };
  }, [
    filters,
    isFilterByMentions,
    isFilterByAssignedToCurrentUser,
    filterByTeamId,
  ]);

  const { isLoading, isFetching, data } = useGetCallsQuery({
    status: filters.status,
    params: searchParams,
  });

  const { data: reviewCalls } = useGetCallsQuery({
    status: CallStatusTab.Pending,
    params: searchParams,
  });

  const nextConversation = useAppSelector((state) =>
    selectNextConversation(state, selectedConversationId, {
      status: filters.status,
      params: searchParams,
    }),
  );

  const { data: callCounts } = useGetCallCountsQuery();

  const { data: call } = useGetConversationQuery(
    selectedConversationId ?? skipToken,
  );

  const handleCloseCallDetails = () => {
    dispatch(setHideMobileNavbar(false));
    dispatch(setSelectedConversationId(null));
  };

  useEffect(() => {
    if (!data?.inbox || !reviewCalls?.inbox) {
      return;
    }

    // Clean up pending list when items appear in completed
    if (filters.status === CallStatusTab.Completed) {
      const appearedInCompleted = callsCompletedTmp.pending.filter((id) =>
        data.inbox.some((call) => call.patient.patient_id === id),
      );

      appearedInCompleted.forEach((id) => {
        dispatch(removeFromPendingTmp(id));
      });
    }

    // Clean up completed list when items appear in pending
    if (filters.status === CallStatusTab.Pending) {
      const appearedInPending = callsCompletedTmp.completed.filter((id) =>
        data.inbox.some((call) => call.patient.patient_id === id),
      );

      appearedInPending.forEach((id) => {
        dispatch(removeFromCompletedTmp(id));
      });
    }
  }, [
    data?.inbox,
    reviewCalls?.inbox,
    filters.status,
    callsCompletedTmp,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(
      callApi.endpoints.getCalls.initiate(
        {
          status: filters.status,
          params: searchParams,
        },
        { forceRefetch: true },
      ),
    );
  }, [filters.status, searchParams, dispatch]);

  return {
    call,
    reviewCalls,
    data,
    filters,
    isLoading,
    isFetching,
    selectedConversationId,
    nextConversation,
    callCounts,
    handleCloseCallDetails,
    updateFilters,
  };
};
