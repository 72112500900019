interface InboxLayoutProps {
  children: React.ReactNode;
  isDesktop: boolean;
  isHeightSm: boolean;
}

export const InboxLayout = ({
  children,
  isDesktop,
  isHeightSm,
}: InboxLayoutProps) => (
  <div
    className={`w-full overflow-hidden grid ${isDesktop ? "h-screen-dynamic" : ""}`}
  >
    <div
      className={`flex overflow-hidden bg-gray-background
      ${isDesktop && isHeightSm ? "p-4 height-md:p-6" : "p-4 md:p-2"}`}
    >
      {children}
    </div>
  </div>
);
