import type { User } from "../../../../store/user/userReducer";
import ProfilePicture from "../../../Basic/ProfilePicture";

interface UserPictureNameProps {
  user: User;
  className?: string;
}

const UserPictureName = ({ user, className = "" }: UserPictureNameProps) => {
  return (
    <div
      className={`w-full bg-gray-background rounded-xl p-3 flex flex-none items-center space-x-3 truncate ${className}`}
    >
      <ProfilePicture
        firstName={user.first_name}
        lastName={user.last_name}
        src={user.profile_picture}
        flagPadding={false}
        size={12}
        fontSize="base"
      />
      <p className="truncate text-base font-semibold">
        {user.first_name} {user.last_name}
      </p>
    </div>
  );
};

export default UserPictureName;
