import emptyEnrollmentsIllustration from "../../../assets/no-enrollments.svg";
import Button from "../../Basic/Button";

interface EmptyEnrollmentsProps {
  hasPhoneNumber: boolean;
  onClick: () => void;
}

const EmptyEnrollments = ({
  hasPhoneNumber,
  onClick,
}: EmptyEnrollmentsProps) => {
  return (
    <div className="mx-auto w-full flex flex-col items-center justify-center">
      <img
        src={emptyEnrollmentsIllustration}
        width="578"
        height="200"
        className="mb-8"
        alt="No Enrollments"
      />
      <p className="text-xl lg:text-2xl font-semibold mb-1">
        No Previous Enrollments
      </p>
      <p className="text-base font-medium text-tertiary">
        Start bridging care gaps
      </p>

      <Button size="unset" className="text-sm h-11 px-4 mt-6" onClick={onClick}>
        {hasPhoneNumber ? "Enroll" : "Add Phone Number"}
      </Button>
    </div>
  );
};

export default EmptyEnrollments;
