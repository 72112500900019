import { isEqual } from "lodash";

import type { AnyAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import type { FixLater } from "../../components/IndependentScribe/store/scribeSlice";
import { ClinicTabs, NotesTabs } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  OPEN_SUCCESS_MODAL,
  SET_ASSISTANTS,
  SET_DOCTOR_OPTIONS,
  SET_ERROR,
  SET_NOTIFICATION,
  SET_ROLES,
  SET_ROLE_OPTIONS,
  SET_SELECTED_CARE_MANAGEMENT_TAB,
  SET_SELECTED_CLINIC_TAB,
  SET_SELECTED_NOTES_TAB,
  SET_SELECTED_SETTINGS_TAB,
  SET_SIGNUP_INFO,
  SET_TEAM_SEARCH_VALUE,
  SET_USER,
  SET_USERS_WITHOUT_TEAM,
  SET_USER_SEARCH_VALUE,
} from "../types";

const storageService = new LocalStorageService();

export type Team = {
  customer_id: number;
  doctor_team_id: number;
  name: string;
  show_inbox: 1 | 0;
  team_icon: string | null;
  members: User[];
};

type UserState = {
  user: User | null;
  roles: Record<number, string>;
  roleOptions: { value: string; text: string }[];
  errorMessage: string;
  notification: Notification | null;
  successModal: boolean;
  assistants: User[];
  signupInfo: Record<string, FixLater>;
  doctorOptions: User[];
  selectedCareManagementTab: string | null;
  selectedSettingsTab: string | null;
  selectedClinicTab: string;
  selectedNotesTab: string;
  userSearchValue: string;
  teamSearchValue: string;
  usersWithoutTeam: User[];
};

const init: UserState = {
  user: storageService.getItem("user") ?? null,
  roles: {
    0: "Super Admin",
    1: "Admin",
    2: "User",
  },
  roleOptions: [
    { value: "0", text: "Super Admin" },
    { value: "1", text: "Admin" },
    { value: "2", text: "User" },
  ],
  errorMessage: "",
  notification: null,
  successModal: false,
  assistants: [],
  signupInfo: {},
  doctorOptions: [],
  selectedCareManagementTab: null,
  selectedSettingsTab: null,
  selectedClinicTab:
    storageService.getItem("selectedClinicTab") ?? ClinicTabs.USERS,
  selectedNotesTab:
    storageService.getItem("selectedNotesTab") ?? NotesTabs.GENERAL,
  userSearchValue: "",
  teamSearchValue: "",
  usersWithoutTeam: [],
};

export default function userReducer(
  state: UserState = init,
  action: AnyAction,
): UserState {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init, user: null };
    case SET_USER:
      if (isEqual(state.user, payload.user)) {
        return state;
      }

      return { ...state, user: payload.user };
    case SET_ROLES:
      return { ...state, roles: payload.roles };
    case SET_ROLE_OPTIONS:
      return { ...state, roleOptions: payload.roleOptions };
    case SET_ERROR:
      return { ...state, errorMessage: payload.errorMessage };
    case SET_NOTIFICATION:
      return { ...state, notification: payload.notification };
    case OPEN_SUCCESS_MODAL:
      return { ...state, successModal: payload.successModal };
    case SET_ASSISTANTS:
      return { ...state, assistants: payload.assistants };
    case SET_SIGNUP_INFO:
      return { ...state, signupInfo: payload.signupInfo };
    case SET_DOCTOR_OPTIONS:
      if (isEqual(state.doctorOptions, payload.doctorOptions)) {
        return state;
      }

      return { ...state, doctorOptions: payload.doctorOptions };
    case SET_SELECTED_CARE_MANAGEMENT_TAB:
      return {
        ...state,
        selectedCareManagementTab: payload.selectedCareManagementTab,
      };
    case SET_SELECTED_SETTINGS_TAB:
      return {
        ...state,
        selectedSettingsTab: payload.selectedSettingsTab,
      };
    case SET_SELECTED_CLINIC_TAB:
      storageService.setItem("selectedClinicTab", payload.selectedClinicTab);
      return {
        ...state,
        selectedClinicTab: payload.selectedClinicTab,
      };
    case SET_SELECTED_NOTES_TAB:
      storageService.setItem("selectedNotesTab", payload.selectedNotesTab);
      return { ...state, selectedNotesTab: payload.selectedNotesTab };
    case SET_USER_SEARCH_VALUE:
      return { ...state, userSearchValue: payload.userSearchValue };
    case SET_TEAM_SEARCH_VALUE:
      return { ...state, teamSearchValue: payload.teamSearchValue };
    case SET_USERS_WITHOUT_TEAM:
      return { ...state, usersWithoutTeam: payload.usersWithoutTeam };
    default:
      return state;
  }
}

export type Clinic = {
  organization_id: number;
  name: string;
};

export type User = {
  doctor_id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  profile_picture: string;
  customer_id: number;
  customer: FixLater;
  note_template_id: number | null;
  settings: FixLater;
  role_id: number;
  occupation: string;
  phone_number: string;
  email: string;
  status: "Active" | "Pending";
  show_inbox?: boolean;
  show_patients?: boolean;
  preferred_name?: string;
  organization: Clinic;
};

export const selectUser = createSelector(
  (state) => state.user,
  (user) => user.user as User,
);
