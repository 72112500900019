import type { Middleware } from "redux";
import LocalStorageService from "../../services/LocalStorageService";

const storageService = new LocalStorageService();

type CallActionType = keyof typeof STORAGE_MAPPING;
type CallAction = {
  type: CallActionType;
  payload: any;
};

const STORAGE_MAPPING = {
  "call/setSelectedConversationId": "selectedConversationId",
  "call/setIsMessageInputOpen": "isMessageInputOpen",
  "call/setIsFilterByMentions": "isFilterByMentions",
  "call/setIsFilterByAssignedToCurrentUser": "isFilterByAssignedToCurrentUser",
  "call/setFilterByTeamId": "filterByTeamId",
} as const;

export const callStorageMiddleware: Middleware =
  (_store) => (next) => (action: CallAction) => {
    const result = next(action);
    const { type } = action;

    if (Object.prototype.hasOwnProperty.call(STORAGE_MAPPING, type)) {
      const storageKey = STORAGE_MAPPING[type];

      try {
        storageService.setItem(storageKey, action.payload);
      } catch (error) {
        console.error(`Failed to persist ${storageKey} to storage:`, error);
      }
    }

    return result;
  };
