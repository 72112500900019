import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store";
import type { Scribe } from "../store/interfaces";
import { setScribe } from "../store/scribeSlice";

export const useScribeGenerationDelay = (selectedScribe: Scribe | null) => {
  const [
    showNoteGenerationDelayedWarning,
    setShowNoteGenerationDelayedWarning,
  ] = useState(false);
  const [hasClosedWarning, setHasClosedWarning] = useState(false);

  const dispatch = useAppDispatch();
  const generationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const delayedGenerationIdsRef = useRef<Set<string>>(new Set());
  const prevSelectedScribeRef = useRef<Scribe | null>(null);
  const isUpdatingRef = useRef(false);

  const setScribeGenerationDelayed = useCallback(
    (audioId: string, isGenerationDelayed: boolean) => {
      if (isUpdatingRef.current) {
        return;
      }

      isUpdatingRef.current = true;

      try {
        if (isGenerationDelayed) {
          if (!delayedGenerationIdsRef.current.has(audioId)) {
            delayedGenerationIdsRef.current.add(audioId);

            if (!hasClosedWarning) {
              setShowNoteGenerationDelayedWarning(true);
            }

            dispatch(
              setScribe({
                audioId,
                scribe: { isGenerationDelayed: true },
              }),
            );
          }
        } else {
          if (delayedGenerationIdsRef.current.has(audioId)) {
            delayedGenerationIdsRef.current.delete(audioId);
            setShowNoteGenerationDelayedWarning(false);

            dispatch(
              setScribe({
                audioId,
                scribe: { isGenerationDelayed: false },
              }),
            );
          }
        }
      } finally {
        isUpdatingRef.current = false;
      }
    },
    [dispatch, hasClosedWarning],
  );

  useEffect(() => {
    // Reset warning state when selected scribe changes
    if (prevSelectedScribeRef.current?.audioId !== selectedScribe?.audioId) {
      setHasClosedWarning(false);
      setShowNoteGenerationDelayedWarning(false);
    }

    prevSelectedScribeRef.current = selectedScribe;

    // Early return if no selected scribe
    if (!selectedScribe?.audioId) {
      return;
    }

    // Handle already delayed generation
    if (
      selectedScribe.isGenerationDelayed ||
      delayedGenerationIdsRef.current.has(selectedScribe.audioId)
    ) {
      if (!isUpdatingRef.current) {
        setShowNoteGenerationDelayedWarning(true);
        setScribeGenerationDelayed(selectedScribe.audioId, true);
      }
      return;
    }

    // Set up timeout for generation delay warning
    if (selectedScribe.isGenerating && !generationTimeoutRef.current) {
      if (
        generationTimeoutRef.current &&
        prevSelectedScribeRef.current?.audioId !== selectedScribe.audioId
      ) {
        clearTimeout(generationTimeoutRef.current);
      }

      generationTimeoutRef.current = setTimeout(
        () => {
          if (selectedScribe.isGenerating) {
            setScribeGenerationDelayed(selectedScribe.audioId, true);
          }
          generationTimeoutRef.current = null;
        },
        2 * 60 * 1000,
      );
    }
    // Clear timeout and reset state when generation completes
    else if (!selectedScribe.isGenerating) {
      setScribeGenerationDelayed(selectedScribe.audioId, false);

      if (generationTimeoutRef.current) {
        clearTimeout(generationTimeoutRef.current);
        generationTimeoutRef.current = null;
      }
    }

    // Cleanup function
    return () => {
      if (generationTimeoutRef.current) {
        clearTimeout(generationTimeoutRef.current);
        generationTimeoutRef.current = null;
      }
    };
  }, [selectedScribe, setScribeGenerationDelayed]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      delayedGenerationIdsRef.current.clear();
      setShowNoteGenerationDelayedWarning(false);
      if (generationTimeoutRef.current) {
        clearTimeout(generationTimeoutRef.current);
      }
    };
  }, []);

  const hideNoteGenerationDelayedWarning = useCallback(() => {
    setShowNoteGenerationDelayedWarning(false);
    setHasClosedWarning(true);
  }, []);

  return {
    showNoteGenerationDelayedWarning:
      showNoteGenerationDelayedWarning && !hasClosedWarning,
    setShowNoteGenerationDelayedWarning,
    hideNoteGenerationDelayedWarning,
    setScribeGenerationDelayed,
  };
};
