import Skeleton from "@mui/material/Skeleton";

interface CareCoordinationSkeletonProps {
  bgColor?: string;
  animation?: "wave" | "pulse";
  className?: string;
}

const CareCoordinationSkeleton = ({
  bgColor = "#EAEDF3",
  animation = "wave",
  className = "",
}: CareCoordinationSkeletonProps) => {
  return (
    <div
      className={`rounded-2.5 p-4 bg-white border border-gray-foreground w-full
        ${className}`}
    >
      <Skeleton
        animation={animation}
        variant="rounded"
        width={91}
        height={20}
        sx={{ bgcolor: bgColor, marginBottom: "8px", borderRadius: "20px" }}
      />
      <div className="flex w-full mb-4">
        <Skeleton
          animation={animation}
          variant="rounded"
          width={64}
          height={16}
          sx={{ bgcolor: bgColor, marginRight: "8px", borderRadius: "16px" }}
        />
        <Skeleton
          animation={animation}
          variant="rounded"
          width={64}
          height={16}
          sx={{ bgcolor: bgColor, borderRadius: "16px" }}
        />
        <Skeleton
          animation={animation}
          variant="rounded"
          width={64}
          height={16}
          sx={{ bgcolor: bgColor, marginLeft: "auto", borderRadius: "16px" }}
        />
      </div>
      <Skeleton
        animation={animation}
        variant="rounded"
        height={160}
        sx={{ bgcolor: bgColor, marginBottom: "16px" }}
      />
      <Skeleton
        animation={animation}
        variant="rounded"
        height={160}
        sx={{ bgcolor: bgColor }}
      />
    </div>
  );
};

export default CareCoordinationSkeleton;
