import axios from "axios";
import axiosRetry from "axios-retry";
import type { FixLater } from "../components/IndependentScribe/store/scribeSlice";
import LocalStorageService from "../services/LocalStorageService";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;

const storageService = new LocalStorageService();
let abortController = new AbortController();

export const setAuthToken = (token) => {
  if (!token) {
    delete axios.defaults.headers.common.Authorization;
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return 1000 * retryCount + Math.random() * 100;
  },
  retryCondition: (error) => {
    return error.config.method === "get";
  },
});

axios.interceptors.request.use(
  (config) => {
    config.signal = abortController.signal;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const handleMissingToken = () => {
  storageService.clear();
  resetAbortController();
};

axios.interceptors.response.use(
  (response) => {
    const token = storageService.getItem("token");
    if (!token) {
      handleMissingToken();
    }
    if (response.status === 401 && token) {
      storageService.clear();
      window.location.reload();
    }
    return response;
  },
  (error) => {
    const token = storageService.getItem("token");
    if (!token) {
      handleMissingToken();
    }
    if (error.response?.status === 401 && token) {
      storageService.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export const fetchWithAuth = async (
  url: string,
  options: FixLater = {},
): Promise<FixLater> => {
  const token = storageService.getItem("token");
  if (!token) {
    console.error(
      "Authorization token is missing",
      storageService.getItem("token"),
    );
    return Promise.reject(new Error("Authorization token is missing"));
  }

  let isUnloading = false;

  // Add an event listener to detect when the page is being unloaded
  window.addEventListener("beforeunload", () => {
    isUnloading = true;
  });

  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (isUnloading) {
        return;
      }

      if (!response.ok) {
        return Promise.reject(new Error(`HTTP error: ${response.status}`));
      }

      return response.json();
    })
    .catch((error) => {
      if (isUnloading) {
        console.warn("Request aborted due to page unload");
        return;
      }

      console.error("Fetch failed:", error);
      throw error;
    });
};

export const resetAbortController = () => {
  abortController.abort();
  abortController = new AbortController();
};
