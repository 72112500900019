interface SectionContentPreviewProps {
  detail: boolean;
  bullet: boolean;
  className?: string;
}

const SectionContentPreview = ({
  detail,
  bullet,
  className = "",
}: SectionContentPreviewProps) => {
  return (
    <div className={`w-full flex flex-col space-y-2 ${className}`}>
      <div className="w-full flex items-center space-x-2">
        {bullet && <div className="w-1 h-1 rounded bg-tertiary" />}
        <div className="h-3 w-full rounded-xl bg-gray-150" />
      </div>

      {detail && (
        <div className="w-full flex items-center space-x-2">
          {bullet && <div className="w-1 h-1 rounded bg-tertiary" />}
          <div className="h-3 w-full rounded-xl bg-gray-150" />
        </div>
      )}

      <div className="w-full flex items-center space-x-2">
        {bullet && <div className="w-1 h-1 rounded bg-tertiary" />}
        <div className="h-3 w-1/3 rounded-xl bg-gray-150" />
      </div>
    </div>
  );
};

export default SectionContentPreview;
