import { type Call, CallDirection } from "../../../../store/call/interfaces";
import ProfilePicture from "../../../Basic/ProfilePicture";
import type { FixLater } from "../../../IndependentScribe/store/scribeSlice";
import CallInfo from "./CallInfo";

interface CallTimelineCardProps {
  call: Call;
  patient: FixLater; //InboxPatient;
  className?: string;
}

const CallTimelineCard = ({
  call,
  patient,
  className = "",
}: CallTimelineCardProps) => {
  return (
    <div
      className={`flex w-full ${className}
        ${call.direction === CallDirection.Outbound ? "flex-row-reverse ml-auto pl-10" : "pr-10"}`}
    >
      <ProfilePicture
        firstName={patient.first_name}
        lastName={patient.last_name}
        flagPadding={false}
      />
      <div
        className={`w-full ${call.direction === CallDirection.Outbound ? "mr-2" : "ml-2"}`}
      >
        <p
          className={`h-8 flex items-center text-sm font-semibold
            ${call.direction === CallDirection.Outbound ? "justify-end" : ""}`}
        >
          {patient.first_name} {patient.last_name}
        </p>
        <CallInfo call={call} />
      </div>
    </div>
  );
};

export default CallTimelineCard;
