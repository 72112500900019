import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowsIcon } from "../assets/icons/double-arrow-left.svg";
import ChatCallScribePanel from "../components/Layout/ChatCallScribePanel";
import Conversation from "../components/Messaging/Conversation";
import EmptyConversation from "../components/Messaging/Conversation/EmptyConversation";
import ConversationSidebar from "../components/Messaging/ConversationSidebar";
import InsightPanel from "../components/Messaging/InsightPanel";
import { convPerPage, twilioDeviceStatuses } from "../helpers/constants";
import TwilioService from "../services/TwilioService";
import { useAppDispatch, useAppSelector } from "../store";
import { baseApi } from "../store/baseApi";
import { useGetConversationQuery } from "../store/call/api";
import { getSelectedConversationId } from "../store/call/callSlice";
import {
  collapseSidebarAction,
  setConversationsCompletedTempAction,
  setConversationsUpdatedAction,
  setFiltersUpdatedAction,
  setMessagesAction,
  setSelectedConversationTabAction,
} from "../store/conversation/actions";
import { searchConversationsWithFilters } from "../store/conversation/thunks";
import { selectUser } from "../store/user/userReducer";
import { setDeviceStatusAction } from "../store/voiceRecorder/actions";
import { getTwilioToken } from "../store/voiceRecorder/thunks";

const Messaging = () => {
  const { OFFLINE } = twilioDeviceStatuses;
  const selectedConversationId = useAppSelector(getSelectedConversationId);
  const {
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
    conversationsUpdated,
    selectedDoctorInbox,
    messages,
    selectedConversationTab,
  } = useAppSelector((state) => state.conversation);
  const { twilioToken, deviceStatus, callEvent } = useAppSelector(
    (state) => state.voiceRecorder,
  );
  const user = useAppSelector(selectUser);

  const { data: currentConversation } = useGetConversationQuery(
    selectedConversationId ?? skipToken,
  );

  const isSidebarStatic = useMediaQuery({ minWidth: 1340 });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const [isMuted, setIsMuted] = useState(false);
  const [showInsightPanel, setShowInsightPanel] = useState(isDesktop);
  const [scrolledUp, setScrolledUp] = useState(false);

  const dispatch = useAppDispatch();

  const twilioServiceRef = useRef(new TwilioService(dispatch));

  useEffect(() => {
    twilioServiceRef.current.destroyTwilioDevice();
    if (twilioToken && user.customer?.phone_number) {
      twilioServiceRef.current.initializeTwilioDevice(twilioToken);
    }
  }, [twilioToken, user.customer?.phone_number]);

  useEffect(() => {
    if (deviceStatus === twilioDeviceStatuses.OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, dispatch]);

  useEffect(() => {
    if (callEvent?.status && twilioServiceRef.current) {
      if (callEvent.status === "ringing") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.RINGING));
      }
      if (callEvent.status === "in-progress") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.IN_PROGRESS));
      }
      if (
        callEvent.status === "completed" ||
        callEvent.status === "no-answer"
      ) {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
        twilioServiceRef.current.handleDisconnect();
      }
    }
  }, [callEvent?.status, dispatch]);

  const updateConversations = useCallback(() => {
    dispatch(setConversationsUpdatedAction(true));

    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
        () => dispatch(setFiltersUpdatedAction(true)),
      ),
    );
  }, [
    dispatch,
    activeFilters,
    selectedDoctorInbox,
    convCurrPage,
    conversationSearchInput,
    activeInbox,
  ]);

  useEffect(() => {
    dispatch(setConversationsCompletedTempAction([]));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch(setMessagesAction({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeFilters) {
      dispatch(setConversationsUpdatedAction(false));
    }
  }, [dispatch, activeFilters]);

  useEffect(() => {
    if (!conversationsUpdated) {
      updateConversations();
    }
  }, [conversationsUpdated, updateConversations]);

  const clickOutsideConversationSidebar = () => {
    if (!isSidebarStatic) {
      dispatch(collapseSidebarAction(true));
    }
  };

  const handleResize = () => {
    setShowInsightPanel(false);
  };

  const handleDisconnect = useCallback(() => {
    dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
    twilioServiceRef.current.handleDisconnect();
  }, [dispatch]);

  const handleCall = useCallback((phone_number) => {
    twilioServiceRef.current.handleCall(phone_number);
  }, []);

  const handleMute = useCallback(() => {
    setIsMuted(!isMuted);
    twilioServiceRef.current.toggleMute(!isMuted);
  }, [isMuted]);

  const handleSetMessages = useCallback(
    (messages) => {
      dispatch(setMessagesAction(messages));
    },
    [dispatch],
  );

  return (
    <>
      <div
        className={`w-full flex min-h-0 md:border-l ${isDesktop ? "h-screen-dynamic" : ""}`}
      >
        {isDesktop ? (
          <div className="flex w-full">
            <ConversationSidebar />
            {!currentConversation && (
              <div className="flex flex-col items-center justify-center w-full">
                <EmptyConversation onClick={clickOutsideConversationSidebar} />
              </div>
            )}
          </div>
        ) : (
          <div className="min-h-0 h-full w-screen relative">
            <div
              className={`absolute top-0 left-0 h-full bg-white transition-all duration-300 overflow-hidden
                ${selectedConversationId ? "w-0" : "w-full"}`}
            >
              <ConversationSidebar />
            </div>
            {selectedConversationId && (
              <>
                <Conversation
                  messages={messages}
                  setMessages={handleSetMessages}
                  scrolledUp={scrolledUp}
                  setScrolledUp={setScrolledUp}
                />
                <div
                  className={`absolute z-20 top-0 right-0 h-full bg-white transition-all duration-300 overflow-hidden
                    ${showInsightPanel ? "w-full" : "w-0"}`}
                >
                  <InsightPanel />
                </div>

                <button
                  type="button"
                  onClick={() => setShowInsightPanel(!showInsightPanel)}
                  className={`w-6 h-6 rounded-full flex items-center justify-center shadow-sm shadow-black/10
                    bg-white absolute z-20 top-[20px] transition-all duration-300
                    ${showInsightPanel ? "left-4" : "left-full -translate-x-full"}`}
                  aria-label="Toggle insight panel"
                >
                  <ArrowsIcon
                    className={`transition-all
                      ${showInsightPanel ? "rotate-180" : ""}`}
                    width="16"
                    height="16"
                  />
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <div
        className="sidebar-static:w-[calc(100%-theme(width.navbar)-theme(width.expandedInboxBar))]
          md:overflow-hidden sidebar-static:overflow-visible"
      >
        {isDesktop && currentConversation && (
          <div
            onClick={clickOutsideConversationSidebar}
            className="flex min-h-0 max-h-full h-full w-screen max-w-full p-6 bg-gray-background space-x-6 md:h-[100dvh]"
          >
            <ChatCallScribePanel
              handleCall={handleCall}
              handleDisconnect={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              patient={currentConversation.patient}
              selectedTab={selectedConversationTab}
              setSelectedTab={(tab) =>
                dispatch(setSelectedConversationTabAction(tab))
              }
              messages={messages}
              setMessages={handleSetMessages}
              showBottomCompleteButton
            />

            <InsightPanel />
          </div>
        )}
      </div>
    </>
  );
};

export default Messaging;
