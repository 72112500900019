import { useNavigate } from "react-router-dom";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../../helpers/constants";
import { getAge } from "../../../helpers/helpers";
import { useAppDispatch } from "../../../store";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../../store/patient/actions";
import type { Patient } from "../../../store/patient/interfaces";
import { getPatientInfo } from "../../../store/patient/thunks";
import { setPathBeforeProfile } from "../../../store/ui/actions";
import CircleDivider from "../CircleDivider";
import ProfilePicture from "../ProfilePicture";

const UserOption = ({
  isDoctors,
  option,
  pathBeforeProfile,
  ...props
}: {
  isDoctors: boolean;
  option: Patient;
  pathBeforeProfile: Pages;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div
      {...props}
      style={{
        height: "50px",
        borderRadius: "8px",
      }}
    >
      <div
        className="grid grid-cols-conv-layout items-center justify-between gap-2.5 w-full min-w-0"
        onClick={(e) => {
          if (!isDoctors && !option.phone_number) {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setPathBeforeProfile(pathBeforeProfile));
            dispatch(
              getPatientInfo(option.patient_id, () => {
                navigate(`${Pages.PATIENTS}/${option.patient_id}`);
              }),
            );
            dispatch(
              setSelectedPatientProfileTabAction(
                patientProfileTabs.INFORMATION,
              ),
            );
            dispatch(
              setSelectedPatientPanelTabAction(patientPanelTabs.MESSAGE),
            );
            dispatch(setOpenEditInfoModalAction(true));
          }
        }}
      >
        <div className="flex items-center space-x-2.5 min-w-0">
          <ProfilePicture
            firstName={
              option.preferred_name ? option.preferred_name : option.first_name
            }
            lastName={option.last_name}
            src={option.profile_picture}
          />
          <div className="truncate">
            <p className="text-sm font-semibold truncate">
              {isDoctors
                ? option.display_name
                : option.preferred_name
                  ? option.preferred_name
                  : option.first_name}{" "}
              {isDoctors ? "" : option.last_name}
            </p>
            {isDoctors && (
              <p className="text-xs font-medium text-tertiary truncate">
                {option.occupation}
              </p>
            )}

            {!isDoctors && (
              <div className="flex items-center space-x-2 text-xs text-tertiary font-medium truncate">
                {option.birthdate && (
                  <p className="whitespace-nowrap">
                    Age {getAge(option.birthdate)}
                  </p>
                )}
                {(option.gender || option.sex) && (
                  <>
                    <CircleDivider className="flex-none" />
                    <p className="capitalize">
                      {option.gender ? option.gender : option.sex}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {!isDoctors && !option.phone_number && (
          <div className="text-xs font-semibold text-tertiary">
            + Add Phone Number
          </div>
        )}
        {!isDoctors &&
          option.phone_number &&
          option.care_coordination.includes("Enrolled") && (
            <div className="text-xs text-primary-blue font-medium">
              {option.care_coordination}
            </div>
          )}
        {!isDoctors && option.phone_number && option.consent === 0 && (
          <div className="text-xs font-semibold text-tertiary">No Consent</div>
        )}
      </div>
    </div>
  );
};

export default UserOption;
