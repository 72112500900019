import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as WandIcon } from "../../../assets/icons/magic-stick.svg";
import { ReactComponent as SendIcon } from "../../../assets/icons/send-outline.svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setClosedResponsesAction } from "../../../store/conversation/actions";
import LoaderDots from "../../Basic/LoaderDots";
import TooltipMui from "../../Basic/TooltipMui";
import SuggestedAnswer from "./SuggestedAnswer";

const MobileInputArea = ({
  messages,
  setMessages,
  onSendMessage,
  suggestedAnswer,
  currentConversation,
  selectedConversationId,
  onClickWand,
  showResponse,
  setShowResponse,
}) => {
  const { closedResponses } = useAppSelector((state) => state.conversation);
  const [openTooltip, setOpenTooltip] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <div className="w-full">
      {showResponse && currentConversation?.show_response && (
        <SuggestedAnswer
          answer={suggestedAnswer}
          onCancel={() => {
            setShowResponse(false);
            if (!closedResponses.includes(selectedConversationId)) {
              dispatch(setClosedResponsesAction(selectedConversationId));
            }
          }}
          onUse={() => {
            setMessages({
              ...messages,
              [selectedConversationId]: suggestedAnswer,
            });
            setShowResponse(false);
          }}
          className="mx-4"
        />
      )}

      <div className="w-full bg-white py-3 px-4">
        <div className="flex items-end relative border px-0.5 rounded-[20px]">
          <TextareaAutosize
            value={messages[selectedConversationId]}
            onChange={(e) =>
              setMessages({
                ...messages,
                [selectedConversationId]: e.target.value,
              })
            }
            placeholder="Type a message"
            minRows={1}
            maxRows={10}
            className="w-full resize-none scrollbar border-r-8 border-white outline-none
              focus:outline-none focus:ring-0 text-xs bg-transparent p-2 leading-tight"
          />
          {messages[selectedConversationId] ? (
            <button
              type="button"
              aria-label="Send message"
              className="w-8 h-8 flex-none bg-primary-blue rounded-full flex items-center justify-center my-0.5"
              onClick={onSendMessage}
            >
              <SendIcon width="15" height="15" />
            </button>
          ) : currentConversation?.show_response ? (
            <button
              type="button"
              aria-label="Suggest answer"
              className="w-8 h-8 flex-none flex items-center justify-center my-0.5"
              onClick={onClickWand}
              disabled={!currentConversation?.show_response}
            >
              <WandIcon
                width="20"
                height="20"
                stroke={
                  currentConversation?.show_response ? "#2970FF" : "#A0A6B2"
                }
              />
            </button>
          ) : (
            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
              <div>
                <TooltipMui
                  title="Conversation in progress"
                  arrow
                  placement="top-end"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setOpenTooltip(false)}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <button
                    type="button"
                    onClick={() => setOpenTooltip(!openTooltip)}
                    className="flex flex-none items-center justify-center rounded-full w-8 h-8 hover:bg-gray-background my-0.5"
                  >
                    <WandIcon width="20" height="20" />
                  </button>
                </TooltipMui>
              </div>
            </ClickAwayListener>
          )}

          {showResponse && !suggestedAnswer && (
            <div className="w-full h-full bg-white flex items-center justify-center space-x-4 text-xs absolute top-0 left-0">
              <LoaderDots />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileInputArea;
