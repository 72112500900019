import { isToday } from "../../../helpers/helpers";
import type { Message } from "../../../store/conversation/interfaces";
import type { User } from "../../../store/user/userReducer";

/**
 * Determines if a sent message should be combined with the previous message
 * Messages are combined if:
 * 1. There is a last message
 * 2. The last message was from a doctor
 * 3. The last message was not automatic
 * 4. The last message was from the same doctor
 * 5. The last message was sent today
 */
export const shouldCombineWithPrevious = (
  lastMessage: Message | null,
  currentUser: User,
): boolean => {
  if (!lastMessage) {
    return false;
  }

  return (
    lastMessage.from === "doctor" &&
    lastMessage.is_automatic === 0 &&
    lastMessage.sender.doctor_id === currentUser.doctor_id &&
    isToday(`${lastMessage.send_at.replace(/-/g, "/")} GMT+0`)
  );
};

/**
 * Determines if a failed message should be combined with the previous message
 * Takes into account both the last regular message and any sent messages
 */
export const shouldCombineWithFailedPrevious = (
  lastMessage: Message | null,
  currentUser: User,
  sentMessages: Record<string, any[]>,
  conversationId: number,
): boolean => {
  const hasSentMessages = sentMessages[conversationId]?.length > 0;

  return hasSentMessages || shouldCombineWithPrevious(lastMessage, currentUser);
};

/**
 * Determines if two messages in the main conversation should be combined
 * Messages are combined if:
 * 1. They're from the same sender type (doctor/patient)
 * 2. They're sent on the same day
 * 3. For doctor messages:
 *    - Both are automatic, or
 *    - Both are non-automatic and from the same doctor
 */
export const shouldCombineConversationMessages = (
  currentMessage: Message,
  previousMessage: Message,
): boolean => {
  const isDoctor = currentMessage.from === "doctor";
  const isDoctorPrevious = previousMessage.from === "doctor";
  const isAutomatic = currentMessage.is_automatic === 1;
  const isAutomaticPrevious = previousMessage.is_automatic === 1;
  const isSameDay = areSameDayMessages(currentMessage, previousMessage);

  // If messages are not from the same day, don't combine
  if (!isSameDay) {
    return false;
  }

  // If one is from doctor and other from patient, don't combine
  if (isDoctor !== isDoctorPrevious) {
    return false;
  }

  // For patient messages, combine if they're on the same day
  if (!isDoctor && !isDoctorPrevious) {
    return true;
  }

  // For doctor messages, check if they're both automatic or both non-automatic from same doctor
  return (
    (isAutomatic && isAutomaticPrevious) ||
    (!isAutomatic &&
      !isAutomaticPrevious &&
      currentMessage.sender.doctor_id === previousMessage.sender.doctor_id)
  );
};

/**
 * Checks if two messages were sent on the same day
 */
export const areSameDayMessages = (
  message1: Message,
  message2: Message,
): boolean => {
  const date1 = new Date(`${message1.send_at.replace(/-/g, "/")} GMT+0`);
  const date2 = new Date(`${message2.send_at.replace(/-/g, "/")} GMT+0`);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

// messageUtils.ts
export const getMessageSenderFirstName = (
  message: Message,
  isAutomatic: boolean,
): string => {
  if (isAutomatic) {
    return "Quadrant";
  }

  return message.sender.preferred_name || message.sender.first_name;
};

export const getMessageProfilePicture = (
  message: Message,
  isAutomatic: boolean,
  isDoctor: boolean,
  patientPicture: string,
  automaticProfilePicture: string,
): string => {
  if (isAutomatic) {
    return automaticProfilePicture;
  }

  if (isDoctor) {
    return message.sender.profile_picture;
  }

  return patientPicture;
};
