import ClinicUsers from "../../components/Settings/Clinic/ClinicUsers";
import SettingsContainer from "../../components/Settings/SettingsContainer";

const ClinicSettings = () => {
  return (
    <SettingsContainer>
      <ClinicUsers />
    </SettingsContainer>
  );
};

export default ClinicSettings;
