import axios, { type AxiosError } from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import LocalStorageService from "../../services/LocalStorageService";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";
import {
  setSitesAction,
  setSitesLoadingAction,
  setUserSitesAction,
} from "./actions";
import { sitesSchema, userSitesSchema } from "./validationSchemas";

const storageService = new LocalStorageService();

export const getSites =
  (searchValue = "") =>
  (dispatch: AppDispatch) => {
    axios
      .get(`${BASE_URL}/facility/list?search=${searchValue}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to load sites",
          );
        } else {
          const sites = response.data.data.facilities;

          handleValidation(sitesSchema, sites, "getSites");

          dispatch(setSitesAction(sites));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to load sites");
      })
      .finally(() => {
        dispatch(setSitesLoadingAction(false));
      });
  };

export const selectSite = (customerId) => (dispatch: AppDispatch) => {
  axios
    .post(`${BASE_URL}/facility/select/${customerId}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to change clinic",
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Clinic changed",
            desc: "Clinic changed successfully",
          }),
        );
        const token = storageService.getItem("token");
        const user = storageService.getItem("user");

        storageService.clear();

        storageService.setItem("token", token);
        storageService.setItem("user", user);

        window.location.reload();
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to change clinic");
    });
};

export const getUserSites = (doctorId) => (dispatch: AppDispatch) => {
  axios
    .get(`${BASE_URL}/facility/user-list/${doctorId}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load user sites",
        );
      } else {
        const userSites = response.data.data.facilities;

        handleValidation(userSitesSchema, userSites, "getUserSites");

        dispatch(setUserSitesAction(doctorId, userSites));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load user sites");
    });
};

export const updateUserSites =
  (doctorId, siteIds) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/facility/user/update/${doctorId}`, {
        facilities: siteIds,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update user sites",
          );
        } else {
          dispatch(getUserSites(doctorId));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update user sites");
      });
  };
