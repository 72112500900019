import type { User } from "../../store/user/userReducer";
import ProfilePicture from "./ProfilePicture";

interface AvatarStackProps {
  users: User[];
  length: number;
  totalNumber: number;
}

const AvatarStack = ({ users, length, totalNumber }: AvatarStackProps) => {
  return (
    <div className="flex -space-x-2.5">
      {users && users.length > 0 && (
        <>
          {users.map(
            (user, index) =>
              index < length && (
                <ProfilePicture
                  key={index}
                  src={user.profile_picture}
                  firstName={
                    user.preferred_name ? user.preferred_name : user.first_name
                  }
                  lastName={user.last_name}
                  className="border-2 border-white"
                  flagPadding={false}
                />
              ),
          )}
          {(totalNumber ? totalNumber > length : users.length > length) && (
            <div
              className="w-8 h-8 rounded-full border-2 border-white bg-gray-200
                flex items-center justify-center text-xxs relative"
            >
              +{totalNumber ? totalNumber - length : users.length - length}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AvatarStack;
