import type React from "react";
import {
  type RefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import { getFormattedTimeOrDate } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  getSelectedConversationId,
  setSelectedConversationId,
} from "../../../store/call/callSlice";
import {
  collapseSidebarAction,
  expandPanelAction,
  setSelectedConversationPatientAction,
} from "../../../store/conversation/actions";
import { setHideMobileNavbar } from "../../../store/ui/actions";
import ProfilePicture from "../../Basic/ProfilePicture";
import ConversationContextMenu from "./ConversationContextMenu";

// Separate component for the message content to prevent unnecessary rerenders
const MessageContent = memo(({ message }: { message: string }) => {
  const parseMentions = useCallback((text: string) => {
    const mentionRegex = /@\[(.*?)]\((?:user|team|doctor)-\d+\)/g;
    const parts = text.split(mentionRegex);

    return (
      <span>
        {parts.map((part, index) => {
          const isMention = index % 2 === 1;
          if (isMention) {
            return (
              <span
                key={index}
                className="text-primary-blue bg-primary-blue-light"
              >
                @{part}
              </span>
            );
          }
          return part;
        })}
      </span>
    );
  }, []);

  return (
    <div className="line-clamp-2 leading-5">
      {message && parseMentions(message)}
    </div>
  );
});

MessageContent.displayName = "MessageContent";

const ConversationPreview = memo(
  ({
    conversation,
    containerRef,
    clickedConversation,
    setClickedConversation,
  }: {
    conversation: any;
    containerRef: RefObject<HTMLDivElement>;
    clickedConversation: any;
    setClickedConversation: (conversation: any) => void;
  }) => {
    const {
      patient,
      lastMessage,
      conversation_id,
      collapsePatientList,
      isCompletedTemp,
      nextConversationId,
    } = conversation;

    const selectedConversationId = useAppSelector(getSelectedConversationId);

    const isCurrentConversation =
      selectedConversationId === conversation?.conversation_id;

    const [fadedOutConversations, setFadedOutConversations] = useState<
      string[]
    >([]);
    const [hiddenConversations, setHiddenConversations] = useState<string[]>(
      [],
    );
    const [contextMenu, setContextMenu] = useState<{
      isOpen: boolean;
      position: {
        top: number | "auto";
        left: number | "auto";
        bottom: string | number;
        right: string | number;
      };
    }>({
      isOpen: false,
      position: {
        top: 0,
        left: 0,
        bottom: "auto",
        right: "auto",
      },
    });

    const dispatch = useAppDispatch();
    const isSidebarStatic = useMediaQuery({ minWidth: 1260 });

    const lastMessageDate = useMemo(
      () => new Date(`${lastMessage.send_at.replace(/-/g, "/")} GMT+0`),
      [lastMessage.send_at],
    );

    useEffect(() => {
      if (isCompletedTemp) {
        setFadedOutConversations((prev) => [...prev, conversation_id]);
      }
    }, [isCompletedTemp, conversation_id]);

    const selectConversation = useCallback(() => {
      dispatch(setHideMobileNavbar(true));
      dispatch(setSelectedConversationId(lastMessage.conversation_id));
      dispatch(setSelectedConversationPatientAction(conversation.patient));

      if (collapsePatientList || !isSidebarStatic) {
        dispatch(collapseSidebarAction(true));
        dispatch(expandPanelAction(true));
      }
    }, [
      dispatch,
      lastMessage.conversation_id,
      collapsePatientList,
      isSidebarStatic,
      conversation.patient,
    ]);

    const hideAndSelectNext = useCallback(() => {
      setTimeout(() => {
        setHiddenConversations((prev) => [...prev, conversation_id]);
        if (isCurrentConversation) {
          if (nextConversationId) {
            dispatch(setSelectedConversationId(nextConversationId));
          } else {
            dispatch(setSelectedConversationId(null));
          }
        }
      }, 400);
    }, [conversation_id, isCurrentConversation, nextConversationId, dispatch]);

    const onRightClick = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        setClickedConversation(conversation);

        const bounds = e.currentTarget.getBoundingClientRect();
        const listBounds = containerRef.current?.getBoundingClientRect();

        if (!listBounds) {
          return;
        }

        const isItemAtBottom = bounds.bottom > listBounds.bottom - 50;
        const isClickAtRight = e.clientX > listBounds.right - 170;

        setContextMenu({
          isOpen: true,
          position: {
            left: isClickAtRight ? "auto" : e.clientX - bounds.left,
            top: isItemAtBottom ? "auto" : e.clientY - bounds.top,
            right: isClickAtRight ? "-6px" : "auto",
            bottom: isItemAtBottom ? bounds.bottom - e.clientY : "auto",
          },
        });
      },
      [containerRef, conversation, setClickedConversation],
    );

    if (hiddenConversations.includes(conversation_id)) {
      return null;
    }

    const containerClassName = `cursor-pointer overflow-hidden rounded-xl ${
      isCurrentConversation
        ? "bg-primary-blue-light text-primary-blue"
        : "hover:bg-gray-50"
    } ${
      fadedOutConversations.includes(conversation_id) ? "animate-fade-out" : ""
    } pr-3 pl-2 py-2.5`;

    return (
      <div className="relative select-none">
        <div
          onContextMenu={onRightClick}
          onClick={selectConversation}
          onAnimationStart={hideAndSelectNext}
          className={containerClassName}
        >
          <div className="flex space-x-2.5 overflow-hidden">
            <ProfilePicture
              src={patient.profile_picture}
              firstName={patient.preferred_name || patient.first_name}
              lastName={patient.last_name}
              flag={conversation.flag}
            />

            <div className="w-full">
              <div className="w-full items-center justify-between grid grid-cols-conv-layout gap-1">
                <p className="font-semibold text-sm truncate w-full">
                  {`${patient.preferred_name || patient.first_name} ${patient.last_name}`}
                </p>
                <p
                  className={`text-xs whitespace-nowrap ml-auto ${
                    isCurrentConversation
                      ? "text-primary-blue"
                      : "text-tertiary"
                  }`}
                >
                  {getFormattedTimeOrDate(lastMessageDate)}
                </p>
              </div>

              <div className="flex mt-2 text-sm text-tertiary h-10 w-full space-x-2 justify-between">
                <MessageContent message={lastMessage.message} />

                {conversation.unread > 0 && (
                  <div className="h-5 min-w-5 bg-blue-1000 rounded-full flex-none text-white px-1.5 flex items-center justify-center">
                    {conversation.unread}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {contextMenu.isOpen &&
          clickedConversation.conversation_id === conversation_id && (
            <ConversationContextMenu
              onClose={() =>
                setContextMenu((prev) => ({ ...prev, isOpen: false }))
              }
              contextMenuPosition={contextMenu.position}
              conversation={conversation}
            />
          )}
      </div>
    );
  },
);

ConversationPreview.displayName = "ConversationPreview";

export default ConversationPreview;
